import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const PostOrderProduct = createAsyncThunk(
  "order/OrderProduct",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post("order/c/", props.data);
      if (props.image && data.id) {
        dispatch(SendOrderBuy({ order_id: data.id, image: props.image }));
      }
      localStorage.setItem(
        "order_success",
        JSON.stringify({
          id: data?.id,
          date: data?.created_at,
          currency: data?.currency,
        })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const HistoryOrderProduct = createAsyncThunk(
  "order/HistoryOrderProduct",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const SendOrderBuy = createAsyncThunk(
  "order/SendOrderBuy",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("order_id", props?.order_id);
      formdata.append("image", props?.image);
      const { data } = await ImageUploadingFetch.post("order/image/", formdata);
      localStorage.setItem("main_manager", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const OrderProduct = createSlice({
  name: "order",
  initialState: {
    status: "",
    data: "",
    box: JSON.parse(localStorage.getItem("Product_Add_To_Box")) || [],
    history: null,
    history_status: "",
    buy_product: null,
    buy_product_status: "",
    error_text: null,
    info_manager: JSON.parse(localStorage.getItem("main_manager")) || {},
  },
  reducers: {
    AddCardToBox: (state, actions) => {
      const boxindex = actions?.payload?.map((i, idx) => {
        return { ...i, index: idx };
      });
      state.box = boxindex;

      localStorage.setItem("Product_Add_To_Box", JSON.stringify(boxindex));
    },
    ClearBox: (state) => {
      state.box = [];
      localStorage.removeItem("Product_Add_To_Box");
    },
    ChangeStatus: (state) => {
      state.status = "";
      state.buy_product_status = "";
    },
    CLearManager: (state) => {
      localStorage.removeItem("main_manager");
      state.info_manager = {};
    },
  },
  extraReducers: {
    [PostOrderProduct.pending]: (state) => {
      state.status = "pending";
    },
    [PostOrderProduct.fulfilled]: (state, actions) => {
      state.status = "success";
      state.data = actions.payload;
    },
    [PostOrderProduct.rejected]: (state, actions) => {
      state.status = "rejected";
      state.error_text = actions.payload?.error;
    },
    [HistoryOrderProduct.pending]: (state) => {
      state.history_status = "pending";
    },
    [HistoryOrderProduct.fulfilled]: (state, actions) => {
      state.history_status = "success";
      state.history = actions.payload;
    },
    [HistoryOrderProduct.rejected]: (state) => {
      state.history_status = "rejected";
    },
    [SendOrderBuy.pending]: (state) => {
      state.buy_product_status = "pending";
    },
    [SendOrderBuy.fulfilled]: (state, actions) => {
      state.buy_product_status = "success";
      state.buy_product = actions.payload;
      state.info_manager = actions.payload;
    },
    [SendOrderBuy.rejected]: (state, actions) => {
      state.buy_product_status = "rejected";
      state.buy_product = actions.payload;
    },
  },
});

export const OrderProductActions = OrderProduct.actions;
export default OrderProduct;
