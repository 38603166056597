import styled from "@emotion/styled";
import React from "react";
import { Bars } from "react-loader-spinner";
 
function Spinner() {
  return (
    <DivSlider>
      <Bars
        height="60"
        width="55"
        color="#303030"
        ariaLabel="bas"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <span>ASIA BRAND</span>
    </DivSlider>
  );
}

export default Spinner;

const DivSlider = styled("div")`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  min-height: 100vh;
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 29px;
    text-align: center;

    color: #303030;
  }
`;

// const Spinners = styled("div")`
//   position: relative;
//   width: 200px;
//   height: 200px;
//   /* border-radius: 50%; */
//   animation: ring 2s linear infinite;

//   @keyframes ring {
//     0% {
//       transform: rotate(0deg);
//       box-shadow: 1px 5px 2px #e65c00;
//     }
//     50% {
//       transform: rotate(180deg);
//       box-shadow: 1px 5px 2px #18b201;
//     }
//     100% {
//       transform: rotate(360deg);
//       box-shadow: 1px 5px 2px #0456c8;
//     }
//   }
//   ::before {
//     position: absolute;
//     content: "";
//     left: 0;
//     top: 0;
//     height: 100%;
//     width: 100%;
//     /* border-radius: 50%; */
//     box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
//   }
//   span {
//     color: #737373;
//     font-size: 20px;
//     letter-spacing: 1px;
//     /* line-height: 200px; */
//     animation: text 3s ease-in-out infinite;
//   }
//   @keyframes text {
//     50% {
//       color: black;
//     }
//   }
// `;
