import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export const LightBoxOneImage = ({ image, openmodal }) => {
  return <Lightbox image={image} onClose={openmodal} />;
};

const LightBoxImage = ({ images, openmodal }) => {
  return <Lightbox images={images} onClose={openmodal} />;
};
export default LightBoxImage;
