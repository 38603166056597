import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { getOrdersAccountantCount } from "../managerStore/orders";

export const getAllProductsRop = createAsyncThunk(
  "productrop/getAllProductsRop",
  async (prop, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/product/list/search/${prop}`);

      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const SearchProductRop = createAsyncThunk(
  "productrop/SearchProductRop",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/product/crud/${props}`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getProductsRopById = createAsyncThunk(
  "productrop/getProductsRopByID",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/product/list/${props}/`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const addCostPrice = createAsyncThunk(
  "productrop/addCostPrice",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/product/cost-price/`,
        props.data
      );
      dispatch(getProductsRopById(props.id));
      dispatch(getOrdersAccountantCount());
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const isActiveProduct = createAsyncThunk(
  "productrop/isActiveProduct",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/product/is-active/`,
        props
      );
      dispatch(getProductsRopById(props.product_id));
      // dispatch(getOrdersAccountantCount());
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

const productropSlice = createSlice({
  name: "productrop",
  initialState: {
    all_productrop: [],
    all_productrop_status: "",
    productrop_byid: {},
    productrop_byid_status: "",
    is_active_product: "",
  },
  reducers: {
    clearStatuses: (state) => {
      state.is_active_product = "";
    },
  },
  extraReducers: {
    [isActiveProduct.fulfilled]: (state) => {
      state.is_active_product = "success";
    },
    // -------------------------------------------------------
    [getAllProductsRop.pending]: (state) => {
      state.all_productrop_status = "pending";
    },
    [getAllProductsRop.fulfilled]: (state, { payload }) => {
      state.all_productrop = payload;
      state.all_productrop_status = "success";
    },
    [getAllProductsRop.rejected]: (state, { payload }) => {
      state.all_productrop = payload;
    },
    [SearchProductRop.pending]: (state) => {
      state.all_productrop_status = "pending";
    },
    [SearchProductRop.fulfilled]: (state, { payload }) => {
      state.all_productrop = payload;
      state.all_productrop_status = "success";
    },
    [SearchProductRop.rejected]: (state, { payload }) => {
      state.all_productrop = payload;
    },
    //------------------------------------------------------
    [getProductsRopById.pending]: (state) => {
      state.productrop_byid_status = "pending";
    },
    [getProductsRopById.fulfilled]: (state, { payload }) => {
      state.productrop_byid = payload;
      state.productrop_byid_status = "success";
    },
    [getProductsRopById.rejected]: (state, { payload }) => {
      state.productrop_byid = payload;
    },

    ////---------------------------------------------------------------
    [addCostPrice.fulfilled]: (state, { payload }) => {
      state.cost_price_st = payload;
    },
    //---------------------------------------------------
  },
});

export const productropActions = productropSlice.actions;
export default productropSlice;
