import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import BasicModal from "../ui/BasicModal";
import { formatNum } from "../../utils/helpers";
import { GetCurrency } from "../../hooks/GetCurrency";
import Button from "../ui/Button";
import Input from "../ui/Input";
import { ReactComponent as ClearProduct } from "../../assets/icons/close_modal.svg";
import { ReactComponent as PlusCart } from "../../assets/icons/pluscart.svg";
import { ReactComponent as MinusCart } from "../../assets/icons/minuscart.svg";
import { useDispatch } from "react-redux";
import { OrderReturn } from "../../store/adminStore/OrderModeration";

const RenderOrder = ({ data, filter = () => { }, change = () => { } }) => {
  const [count, setcount] = useState(0);
  const getvalue = (e) => {
    if (+data?.count >= +e?.target.value) {
      setcount(+e?.target?.value);
      change({
        ...data,
        change_count: +e.target.value,
      });
    }
    if (e?.target.value <= -1) {
      filter(data);
    }
  };
  const plusValue = () => {
    if (+data?.count >= +count + 1) {
      setcount((p) => p + 1);
      change({
        ...data,
        change_count: +data?.count + 1,
      });
    }
  };
  const minusValue = () => {
    if (+data?.count >= +count - 1) {
      setcount((p) => p - 1);
      // console.log(+data.price / +data?.count);
      // const fixedprice = data.price - (data.price / data.count)
      // console.log(fixedprice);
      change({
        ...data,
        price: data?.price / +data?.count,
        count: +data?.count - 1,
        change_count: +data?.count - 1,
      });
    }
    if (count - 1 <= 0) {
      filter(data);
    }
  };
  useEffect(() => {
    setcount(data?.count || 1);
  }, [data?.count, data?.title]);
  // console.log(+data.price / +data?.count);

  // console.log(data);
  return (
    <BlockPage>
      <WrapperClose onClick={() => filter(data)}>
        <ClearProduct />
      </WrapperClose>
      <div className="div">
        <img src={data?.image} alt={data?.title} />
        <div>
          <p>Названия: {data?.title}</p>
          {/* <p>
            Цена: {formatNum(data?.price / data?.count)}{" "}
            {GetCurrency(data?.currency)}
          </p> */}
        </div>
      </div>
      <span>
        {/* <span className="p">
          <span className="label">Сумма: </span>
          {formatNum(data?.price)} {GetCurrency(data?.currency)}
        </span> */}
        <div>
          <WrapperBtnAdd>
            <Button onClick={plusValue}>
              <PlusCart />
            </Button>
            <Input value={count} constant={true} onChange={getvalue} />
            <Button onClick={minusValue}>
              <MinusCart />
            </Button>
          </WrapperBtnAdd>
        </div>
      </span>
    </BlockPage>
  );
};
const WrapperBtnAdd = styled("span")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > div {
    margin: 0px;
  }
  input {
    width: 50px;
    font-size: 20px;
    text-align: center;
  }
  & > button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > button:hover {
    path,
    rect {
      fill: white;
    }
  }
`;
const WrapperClose = styled(Button)`
  position: absolute;
  top: 2px;
  right: 7px;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: none;
  &:hover {
    & > svg {
      path {
        fill: white;
      }
    }
  }
`;
const ReturnOrder = ({ open, data, close }) => {
  const [product, setproduct] = useState([]);
  const dispatch = useDispatch();
  const filterdata = (e) => {
    const mutagen = product?.filter((i) => i?.product_id !== e?.product_id);
    setproduct(mutagen);
  };
  const changeCount = (e) => {
    const mutagen = product?.map((i) => {
      if (+i?.id === +e?.id) {
        return e;
      }
      return i;
    });
    setproduct(mutagen);
  };
  useEffect(() => {
    if (open) {
      setproduct(data?.order_products);
    }
  }, [open]);
  const orderReturnFunc = () => {
    const mutagen = product?.map((i) => {
      return { product: +i?.product_id, count: i?.change_count || i?.count };
    });
    if (mutagen?.length > 0) {
      dispatch(
        OrderReturn({
          order: data?.id,
          return_products: mutagen,
          exchange_products: [],
        })
      );
    }
    close();
  };
  return (
    <BasicModal open={open} onClose={close}>
      <WrapperModal>
        <h3>Возврат товара</h3>
        <p>
          Укажите только те товары и<br /> колличество, которые
          <br />
          необходимо сделать возврат.
        </p>
        <div>
          {product?.length > 0 ? (
            product?.map((i, idx) => (
              <RenderOrder
                change={changeCount}
                filter={filterdata}
                key={idx + "zakaz"}
                data={i}
              />
            ))
          ) : (
            <h4>Пусто</h4>
          )}
        </div>
        <Button onClick={orderReturnFunc}>Возврат</Button>
      </WrapperModal>
    </BasicModal>
  );
};
export default ReturnOrder;
const WrapperModal = styled("div")`
  min-height: 20vh;
  max-height: 80vh;
  overflow: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  @media screen and (max-width: 700px) {
    max-height: 80vh;
    overflow: auto;
    width: 300px;
    padding: 10px;
  }
`;
const BlockPage = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
  & .label {
    color: #242424;
  }
  img {
    width: 105px;
    height: 105px;
    object-fit: cover;
  }
  .p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #606060;
    width: 67%;
  }
  .div {
    display: flex;
  }
  .div > img {
    margin-right: 25px;
  }
  p {
    margin-top: 10px;
  }
  .kol {
    margin-left: 40px;
    margin-top: 30px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: rgb(69, 69, 78);
    }
  }
  & > span {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 15px;
    & > div {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .div > img {
      margin: 0px;
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 5px;
    }
    & > span {
      display: flex;
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
`;
