import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "@mui/material/Select";
import styled from "@emotion/styled";
import { ThemeProvider, createTheme } from "@mui/material";
import { ReactComponent as Traingle } from "../../assets/icons/traingleIcon.svg";
import { ReactComponent as AddCategories } from "../../assets/icons/addCategories.svg";

export default function Select({
  options,
  onChange,
  label,
  variant,
  getvalue,
  add,
  addOnClick,
  defValue,
  constant,
  error,
}) {
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setValue(defValue);
  }, [defValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    getvalue(event.target.value);
  };
  const renderValue = (selectedValue) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    return selectedOption ? selectedOption.value : "";
  };
  const CustomIcon = () => {
    return <Traingle style={{ margin: "0 10px 0 0" }} />;
  };
  return (
    <div>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: { main: "#5D728599" },
            action: {
              hover: "#DDE0E6",
            },
          },
          components: {
            MuiMenuItem: {
              styleOverrides: {
                root: {
                  "&.Mui-selected": {
                    backgroundColor: "#5D728599",
                  },
                },
              },
            },
          },
        })}
      >
        <FormControl
          variant="standard"
          sx={{ width: "100%", margin: "15px 0 0 0" }}
          error={error}
        >
          <InputLabel errorLabel={error}>
            {label} {!constant && <span>*</span>}{" "}
          </InputLabel>
          <MuiSelect
            IconComponent={CustomIcon}
            sx={{
              margin: "10px 0 0 0",
              "&.MuiInputBase-root": { margin: 0 },
            }}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={handleChange}
            renderValue={renderValue}
            value={value}
          >
            {options?.map((e, idx) => (
              <StyledMenuItem
                onClick={() => onChange(e)}
                key={idx}
                value={e.value}
              >
                {variant === "img" && (
                  <img loading="lazy" className="img" src={e.img} alt="" />
                )}
                {variant === "color" && (
                  <img loading="lazy" className="color" src={e.img} alt="" />
                )}
                {e.title}
              </StyledMenuItem>
            ))}
            {add && (
              <StyledMenuItem onClick={addOnClick}>
                <AddCategories style={{ marginRight: "20px" }} />
                <Add>{add}</Add>
              </StyledMenuItem>
            )}
          </MuiSelect>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

const Add = styled("p")`
  color: #09234f;
  font-size: 16px;
`;

const InputLabel = styled("label")`
  color: ${(props) => (props.errorLabel ? "red" : "#5d7285")};
  font-size: 14px;
  font-weight: 400;
  display: flex;
  gap: 8px;
  & > span {
    font-size: 18px;
    color: red;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  & > .img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 4px;
  }
  & > .color {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50px;
  }
`;
