import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getDealerStatus = createAsyncThunk(
    "dealerSt/getDealerStatus",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("crm/dealer-status/crud/");
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const addDealerStatus = createAsyncThunk(
    "dealerSt/addDealerStatus",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post("crm/dealer-status/crud/",
                { title: props.main_title, main_title: props.main_title, discount: props.discount });
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const editDealerStatus = createAsyncThunk(
    "dealerSt/editDealerStatus",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.put("crm/dealer-status/crud/" + props.title + "/",
                { title: props.title, main_title: props.main_title, discount: props.discount });
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const deleteCatUSer = createAsyncThunk(
    "dealerSt/deleteCatUSer",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`crm/dealer-status/crud/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
const dealerStatusSlice = createSlice({
    name: "dealerSt",
    initialState: {
        add_status: "",
        dealer_status: "",
        delete_status: "",
        edit_status: "",
        dealerList: []

    },
    reducers: {
        ChangeStatus: (state) => {
            state.add_status = "";
            state.delete_status = "";
            state.edit_status = "";

        },
    },
    extraReducers: {
        [addDealerStatus.fulfilled]: (state) => {
            state.add_status = "success";
        },
        [getDealerStatus.fulfilled]: (state, { payload }) => {
            state.dealer_status = "success";
            state.dealerList = payload
        },
        [editDealerStatus.fulfilled]: (state) => {
            state.edit_status = "success";
        },
        [deleteCatUSer.fulfilled]: (state) => {
            state.delete_status = "success";
        },

    },
});

export const dealerStatusActions = dealerStatusSlice.actions;
export default dealerStatusSlice;
