import { Box, InputLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategoryPay,
  deleteCategoryPay,
  editCategoryPay,
  getRequisites,
  requisiteActions,
} from "../../store/accountant/requisite";
import Button from "../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/deleteIcon.svg";
import BasicModal from "../../components/ui/BasicModal";
import Input from "../../components/ui/Input";
import ImagePicker from "../../components/ui/ImagePicker";
import { NotificationManager } from "react-notifications";

function RequisitePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    requisites,
    addCategoryPay_status,
    editCategoryPay_status,
    deleteCategoryPay_status,
  } = useSelector((s) => s.requisite);

  const [openModal, setOpenModal] = useState({
    modalEdit: false,
    modalDelete: false,
    action: "",
    // data: {},
  });

  const [dataReq, setDataReq] = useState({
    title: "",
    logo: [],
  });

  const getValue = (e) => {
    const value = e.target.value;
    setDataReq((prev) => ({ ...prev, title: value }));
  };
  const getPhoto = (e) => {
    setDataReq((prev) => ({ ...prev, logo: e }));
  };

  useEffect(() => {
    dispatch(getRequisites());
  }, []);

  useEffect(() => {
    if (addCategoryPay_status === "success") {
      NotificationManager.success("Успешно добавлено!");
      dispatch(requisiteActions.clearStatus());
      setOpenModal({});
      dispatch(getRequisites());
    }
    if (deleteCategoryPay_status === "success") {
      NotificationManager.success("Успешно удален!");
      dispatch(requisiteActions.clearStatus());
      setOpenModal({});
      dispatch(getRequisites());
    }
    if (editCategoryPay_status === "success") {
      NotificationManager.success("Успешно редактирован!");
      dispatch(getRequisites());
      dispatch(requisiteActions.clearStatus());
      setOpenModal({});
      setDataReq({});
    }
  }, [addCategoryPay_status, deleteCategoryPay_status, editCategoryPay_status]);

  const openEditModalHandle = ({ event, i, type }) => {
    event?.stopPropagation();
    if (type === "edit") {
      setOpenModal((prev) => ({
        ...prev,
        modalEdit: !prev.modalEdit,
        action: "edit",
      }));
      setDataReq((prev) => ({
        ...prev,
        title: i?.title,
        logo: [{ data_url: i.logo }],
        id: i.id,
      }));
    } else {
      setOpenModal((prev) => ({
        ...prev,
        action: "",
        modalEdit: !prev.modalEdit,
      }));
    }
    if (openModal.modalEdit) {
      setDataReq({});
    }
  };

  const openDeleteModalHandle = (event, id) => {
    event.stopPropagation();
    setOpenModal((prev) => ({
      ...prev,
      modalDelete: !prev.modalDelete,
      deleteId: id,
    }));
  };

  const deleteRequisiteHandle = () => {
    dispatch(deleteCategoryPay(openModal.deleteId));
  };

  const addRequisiteHandle = () => {
    if (dataReq?.logo[0]?.file && dataReq?.title) {
      dispatch(
        addCategoryPay({ title: dataReq?.title, logo: dataReq?.logo[0]?.file })
      );
    }
  };
  const editRequisiteHandle = () => {
    const image = dataReq.logo[0]?.file
      ? dataReq.logo[0]?.file
      : dataReq.logo[0]?.data_url;
    if (image && dataReq.title) {
      dispatch(
        editCategoryPay({ title: dataReq.title, logo: image, id: dataReq.id })
      );
    }
  };

  return (
    <Box>
      {/*  */}
      <BasicModal open={openModal.modalDelete} onClose={openDeleteModalHandle}>
        <Box sx={{ minWidth: "350px" }}>
          <Typography variant="h6" marginBottom="15px">
            Вы хотите удалить?{" "}
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button onClick={openDeleteModalHandle} variant="outlined">
              Нет
            </Button>
            <Button variant="danger" onClick={deleteRequisiteHandle}>
              Да
            </Button>
          </Box>
        </Box>
      </BasicModal>

      <BasicModal open={openModal.modalEdit} onClose={openEditModalHandle}>
        <Typography variant="h6">
          {openModal.action === "edit" ? "Редактировать" : "Добавить"}
        </Typography>
        <Box sx={{ minWidth: "400px" }}>
          <Box sx={{ width: "100px", height: "100px" }}>
            <ImagePicker
              allPhotos={dataReq.logo}
              maxphotos={1}
              getPhoto={getPhoto}
            />
          </Box>
          <Box sx={{ margin: "20px 0" }}>
            <Input
              label="Категория"
              onChange={getValue}
              value={dataReq.title}
            />
          </Box>

          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button variant="outlined" onClick={openEditModalHandle}>
              Отмена
            </Button>
            {openModal.action === "edit" ? (
              <Button onClick={editRequisiteHandle}>Сохранить</Button>
            ) : (
              <Button onClick={addRequisiteHandle}>Добавить</Button>
            )}
          </Box>
        </Box>
      </BasicModal>
      {/*  */}
      <Typography variant="h6">Реквизиты</Typography>
      <Box sx={{ maxWidth: "400px", gap: "20px" }}>
        {requisites?.map((i) => (
          <Box
            key={i.id}
            onClick={() => navigate(`${i.id}`)}
            sx={{
              border: "1px solid #727272",
              padding: "15px",
              margin: "0 0 15px 0",
              borderRadius: "4px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              "& img": {
                width: "30px",
                borderRadius: "50px",
              },
              ":active": {
                border: "1px solid #09234f",
              },
            }}
            variant={"outlined"}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img src={i.logo} alt="logo" />
              <span>{i.title}</span>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Edit
                onClick={(event) =>
                  openEditModalHandle({ event, i, type: "edit" })
                }
              />
              <Delete onClick={(event) => openDeleteModalHandle(event, i.id)} />
            </Box>
          </Box>
        ))}
        <Button
          onClick={(e) => openEditModalHandle(e, "add")}
          style={{ maxWidth: "300px" }}
        >
          Добавить
        </Button>
      </Box>
    </Box>
  );
}

export default RequisitePage;

export function ImageWithFallback({ primarySrc, fallbackSrc, alt }) {
  const [imageSrc, setImageSrc] = useState(primarySrc);

  const handleImageError = () => {
    setImageSrc(fallbackSrc);
  };

  return <img src={imageSrc} alt={alt} onError={handleImageError} />;
}

// #09234f
