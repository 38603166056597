import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
// import { GetProducts } from "../../store/moderatorStore/ProductSlice";
import CreateOrderProductCard from "./CreateOrderProductCard";
import ModeratorNavBar from "../../components/moderatorComponents/ModeratorNavBar";
import {
  CreateOrderActions,
  GetStockList,
  PostOrderProductBuyManager,
} from "../../store/managerStore/CreateOrder";
import Pogination from "../../components/ui/Pogination";
import { formatNum, getcurrency_KZ } from "../../utils/helpers";
import { NotificationManager } from "react-notifications";
import OrderCreateManager from "../../components/managerComponents/OrderCreateManager";
import BasicModal from "../../components/ui/BasicModal";
import ImagePicker from "../../components/ui/ImagePicker";
import Button from "../../components/ui/Button";
import card from "../../assets/icons/card-icon.png";
import cash from "../../assets/icons/cash-icon.png";
import ab_icon from "../../assets/icons/ab_icon.svg";
import kaspiicon from "../../assets/icons/kaspi.png";
import star from "../../assets/images/starincard.svg";
import DefImage from "../../assets/images/notimage.jpg";
import { ReactComponent as MinusCart } from "../../assets/icons/minuscart.svg";
import { ReactComponent as PlusCart } from "../../assets/icons/pluscart.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/close_modal.svg";
import { Skeleton } from "@mui/material";

const Renderproduct = ({ data }) => {
  const { stock_status } = useSelector((s) => s.createOrder);
  const { product_status } = useSelector((s) => s.product);

  return (
    <Cards>
      {stock_status === "pending" || product_status === "pending"
        ? [...Array(9)].map((sk, ind) => (
            <Skeleton
              width={220}
              variant="rectangular"
              height={308}
              sx={{ margin: "5px", borderRadius: "6px" }}
              key={ind + "sk"}
            />
          ))
        : data?.map((i, ind) => (
            <CreateOrderProductCard key={ind + "card"} data={i} />
          ))}
      {data?.length < 1 && "Пусто "}
    </Cards>
  );
};

const Card = ({ data, id }) => {
  const dispatch = useDispatch();
  const { add_product } = useSelector((i) => i?.createOrder);
  const [priceProduct, setPriceProduct] = useState(data);
  const [haveimage, sethaveimage] = useState(true);
  const onError = () => sethaveimage(false);
  const onLoad = () => sethaveimage(true);
  const { minus, plus, clearProduct } = OrderCreateManager();
  const plusValue = () => plus(data, 1);
  const minusValue = () => minus(data, 1);
  const clear = () => clearProduct(data);

  const getPriceProduct = (e) => {
    const val = e.target.value;
    if (id) {
      setPriceProduct((prev) => ({
        ...prev,
        needprice: { ...prev.needprice, price: val },
      }));
    }
  };

  useEffect(() => {
    setPriceProduct(data);
  }, [add_product, data]);

  const changePrice = () => {
    const mutagen = add_product.map((i) => {
      if (i.id === priceProduct.id && id) {
        return { ...i, needprice: priceProduct.needprice };
      }
      return i;
    });
    // console.log(add_product);
    dispatch(CreateOrderActions.AddToAddBuy(mutagen));
  };

  return (
    <WrapCard key={id}>
      <WrapperBtnClear onClick={clear}>
        <ClearIcon />
      </WrapperBtnClear>
      <div>
        <div className="render">
          {haveimage && data?.images[0]?.image ? (
            <img
              onLoad={onLoad}
              onError={onError}
              src={data?.images[0]?.image}
              alt="first_img_product"
            />
          ) : (
            <img src={DefImage} alt="not_image" />
          )}
        </div>
        {+data?.needprice?.discount ? (
          <span>
            {formatNum(data?.needprice?.discount || 0)}{" "}
            {data?.needprice?.discount_status
              ? "%"
              : getcurrency_KZ(data?.needprice?.currency || "")}
          </span>
        ) : (
          <WrapperSpan />
        )}
        <div className="rating">
          <span>{data?.reviews_amount}</span>
          <img loading="lazy" src={star} alt="star" />
        </div>
      </div>
      <div>
        <span>
          <WrapperH5>
            {+data?.needprice?.old_price > 0 ? (
              <WrapperOldprice>
                {formatNum(data?.needprice?.old_price || 0)}{" "}
                {getcurrency_KZ(data?.needprice?.currency || "")}
              </WrapperOldprice>
            ) : (
              ""
            )}
            <Input
              onBlur={changePrice}
              value={priceProduct.needprice?.price}
              onChange={getPriceProduct}
            />
            {/* {formatNum(data?.needprice?.price || 0)}{" "} */}
            {getcurrency_KZ(data?.needprice?.currency || "")}
          </WrapperH5>
          <div>
            <WrapperBtnAdd>
              <Button onClick={plusValue}>
                <PlusCart />
              </Button>
              <p>{data?.count} шт.</p>
              <Button onClick={minusValue}>
                <MinusCart />
              </Button>
            </WrapperBtnAdd>
          </div>
          <p className="title">{data?.title}</p>
        </span>
      </div>
    </WrapCard>
  );
};

const Input = styled("input")`
  padding: 5px 8px;
  border-radius: 8px;
  border: 1px solid gray;
  width: 110px;
  margin: 0 10px 8px 0;
`;
const WrapperBtnClear = styled(Button)`
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  background: #81808088;
  & > svg {
    width: 30px;
    height: 30px;
  }
  &:hover {
    & > svg {
      path {
        fill: white;
      }
    }
  }
`;
const WrapperBtnAdd = styled("span")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  & > p {
    white-space: nowrap;
  }
  & > button {
    cursor: pointer;
    width: 35px;
    height: 35px;
    max-height: 35px;
    min-width: 65px;
    border-radius: 10px;
    background: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > button:hover {
    path,
    rect {
      fill: white;
    }
  }
`;
const WrapCard = styled("div")`
  width: 220px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  .render {
    width: 180px;
    height: 200px;
    & > img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      object-fit: cover;
      overflow: hidden;
    }
    @media screen and (max-width: 700px) {
      height: 180px;
      width: 202px;
      & > img {
        height: 180px;
      }
    }
  }
  & > div:nth-of-type(1) {
    position: relative;
    width: 100%;
  }
  & > div:nth-of-type(1) > span {
    position: absolute;
    bottom: 4px;
    left: 0;
    /* width: 100px; */
    padding: 5px;
    height: 30px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 10px;
    background: #f84135;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
  }
  .rating {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    background: #60606094;
    border-radius: 5px;
  }
  .title {
    color: #606060;
    font-size: 16px;
    margin-top: 8px;
    cursor: pointer;
  }
  & > div:nth-of-type(2) > span > h5 {
    font-size: 14px;
    margin-top: 6px;
    cursor: pointer;
    font-weight: 700;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2) {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2) .cart {
    fill: none;
    stroke: #b4b4b4;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2):hover {
    & .cart {
      fill: none;
      stroke: #303030;
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
  }

  @media screen and (max-width: 700px) {
    padding: 8px;
    margin: 5px;
    width: 220px;
    & > div > img {
      height: 100%;
      background-size: cover;
    }
    .title {
      font-size: 13px;
    }
    & > div:nth-of-type(2) > span > h5 {
      font-size: 13px;
    }
    & > div:nth-of-type(1) > div {
      bottom: 10px;
      right: 10px;
    }
    & > div:nth-of-type(2) {
      width: 100%;
    }
    & > div:nth-of-type(1) > span {
      width: 35px;
      height: 22px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 7px;
      font-size: 12px;
    }
    & > div:nth-of-type(2) > span:nth-of-type(1) {
      width: 75%;
    }
    & > div:nth-of-type(2) > span:nth-of-type(2) {
      width: 20%;
      height: 30px;
    }
  }
`;
const WrapperOldprice = styled("span")`
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #8e8e92;
`;
const WrapperH5 = styled("h5")`
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const WrapperSpan = styled("span")`
  background: none !important;
  border: none !important;
`;

const ModalOrder = ({ send, close }) => {
  const [photo, setphoto] = useState([]);
  const create = () => {
    if (photo?.length > 0) {
      send(photo[0]?.file);
      close();
      return;
    }
    close();
  };
  return (
    <WrapperImage>
      <div>
        <ImagePicker
          type={false}
          maxphotos={1}
          allPhotos={photo}
          getPhoto={setphoto}
        />
      </div>
      <Button onClick={create}>Отрпавить</Button>
    </WrapperImage>
  );
};
const WrapperImage = styled("div")`
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > div {
    width: 250px;
    height: 260px;
  }
`;
const RenderSumm = ({ data }) => {
  const mut = data?.map((i) => {
    return { ...i, allprice: +i?.count * +i?.needprice?.price };
  });
  const summ = mut?.reduce((a, b) => (a += b?.allprice), 0);
  return (
    <WrapperSumm>
      <h4>Товары:</h4>
      <span>
        {data?.map((i, idx) => (
          <div key={idx + "idx" + idx}>
            <div>
              <p>{i?.count} x</p>
              <h4>{i?.title}</h4>
            </div>
            <div>
              <h4>{formatNum(+i?.count * +i?.needprice?.price || 0)}</h4>
              <p>{getcurrency_KZ(i?.needprice?.currency || "KZ")}</p>
            </div>
          </div>
        ))}
      </span>
      <div>
        <p>Сумма</p>
        <h4>
          {formatNum(summ || 0)} {getcurrency_KZ()}
        </h4>
      </div>
    </WrapperSumm>
  );
};
const WrapperSumm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  & > div {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
    padding-top: 10px;
  }
  & > span {
    display: flex;
    flex-direction: column;
    gap: 5px;
    & > div {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
        gap: 5px;
      }
    }
  }
`;

const RenderProOrder = ({ data }) => {
  const [method, setMethod] = useState("");
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const openModal = () => {
    setopen(!open);
  };
  const { clearProduct } = OrderCreateManager();
  const { status } = useSelector((i) => i?.createOrder);
  const [clear, setclear] = useState(false);

  function save(e) {
    if (e === "Карта" || e === "Каспи") {
      openModal();
    }
    // localStorage.setItem("method", e);
    setMethod(e);
  }

  useEffect(() => {
    if (!open) {
      setMethod("");
    }
  }, [open]);

  useEffect(() => {
    const get = localStorage.getItem("method");
    if (get) {
      setMethod(get);
    }
  }, []);

  const createOrderToBuy = () => {
    if (!method) return;
    if (method !== "Карта" && method !== "Каспи") {
      const userinfo = JSON.parse(localStorage.getItem("createorder")) || {};
      const mutagen = data?.data?.map((i) => {
        return {
          count: i?.count,
          product_id: i?.id,
          price: +i.needprice.price,
        };
      });

      const total_price = data?.data?.map((i) => +i.needprice.price * i.count);
      const sum_totalprice = +total_price?.reduce((a, b) => a + b, 0);
      
      dispatch(
        PostOrderProductBuyManager({
          data: {
            price: sum_totalprice,
            address: data?.city?.title,
            city: data?.city?.slug,
            currency: "KZ",
            gmail: userinfo?.email,
            name: userinfo?.name,
            phone: userinfo?.phone,
            products: mutagen || [],
            type_status: method,
            username: userinfo?.username,
          },
        })
      );
      setclear(true);
    } else {
      openModal();
    }
  };

  const createOrderWithPhoto = (e) => {
    const userinfo = JSON.parse(localStorage.getItem("createorder")) || {};
    const mutagen = data?.data?.map((i) => {
      return { count: i?.count, product_id: i?.id, price: +i.needprice.price };
    });

    const total_price = data?.data?.map((i) => +i.needprice.price);
    const sum_totalprice = +total_price?.reduce((a, b) => a + b, 0);
    dispatch(
      PostOrderProductBuyManager({
        data: {
          price: sum_totalprice,
          address: data?.city?.title,
          city: data?.city?.slug,
          currency: "KZ",
          gmail: userinfo?.email,
          name: userinfo?.name,
          phone: userinfo?.phone,
          products: mutagen || [],
          type_status: method,
          username: userinfo?.username,
        },
        image: e,
      })
    );
    setclear(true);
  };
  useEffect(() => {
    if (clear) {
      for (let i = 0; i < data?.data?.length; i++) {
        const element = data?.data[i];
        clearProduct(element);
      }
    }
  }, [clear, data?.data?.length, status]);
  return (
    <>
      {open && (
        <BasicModal open={open} onClose={openModal}>
          <ModalOrder close={openModal} send={createOrderWithPhoto} />
        </BasicModal>
      )}
      <WrapperBoxCard>
        <WrapperProd>
          <div>
            {data?.data?.map((i, idx) => (
              <Card data={i} id={i.id} key={idx + "idx_prod"} />
            ))}
          </div>
        </WrapperProd>
        <WwrapperMethod>
          <h4>Заказ из города: {data?.city?.title}</h4>
          <RenderSumm data={data?.data} />
          <h4>Выберите способ оплаты</h4>
          <div>
            <div
              className={`box ${method === "Карта" ? "activemethod" : ""}`}
              onClick={() => save("Карта")}
            >
              <img src={card} alt="" />
              <span>Расчетный счет (visa, Master Card)</span>
            </div>

            <div
              className={`box ${method === "Каспи" ? "activemethod" : ""}`}
              onClick={() => save("Каспи")}
            >
              <img src={kaspiicon} alt="" />
              <span>Kaspi</span>
            </div>

            <div
              className={`box ${method === "Баллы" ? "activemethod" : ""}`}
              onClick={() => save("Баллы")}
            >
              <img src={ab_icon} alt="" />
              <span>Оплата через кошелек</span>
            </div>

            <div
              className={`box ${method === "Наличка" ? "activemethod" : ""}`}
              onClick={() => save("Наличка")}
            >
              <img src={cash} alt="" />
              <span>Наличные</span>
            </div>
          </div>
        </WwrapperMethod>
        <div className="btn_order">
          <Button onClick={createOrderToBuy}>Оформить заказ</Button>
        </div>
      </WrapperBoxCard>
    </>
  );
};
const RenderBuyBox = ({ method, save }) => {
  const { add_product } = useSelector((i) => i?.createOrder);
  const mutagenarr = [];
  const mutagen = add_product?.map((i) => {
    const index = mutagenarr?.findIndex(
      (e) => e?.city?.slug === i?.addcity?.slug
    );
    if (index === -1) {
      const prod = add_product?.find(
        (e) => e?.addcity?.slug === i?.addcity?.slug
      );
      const prodarr = add_product?.filter(
        (e) => e?.addcity?.slug === prod?.addcity?.slug
      );
      if (prod?.addcity?.slug === i?.addcity?.slug) {
        mutagenarr?.push({ data: prodarr, city: prod?.addcity });
        return {
          data: prodarr,
          city: prod?.addcity,
        };
      }
    }
    const arrdata = [];
    const arr = mutagenarr?.map((e) => {
      arrdata?.push(...e?.data);
      if (e?.city?.slug === i?.addcity?.slug) {
        arrdata?.push(i);
        return { ...e, data: arrdata };
      }
      return i;
    });
    return { data: arr, city: i?.addcity };
  });
  return (
    <WrapperRenderProd>
      {add_product?.length > 0
        ? mutagenarr?.map((e, idx) => (
            <RenderProOrder
              method={method}
              save={save}
              key={e?.city?.title + idx + "box"}
              data={e}
            />
          ))
        : ""}
    </WrapperRenderProd>
  );
};
const WrapperRenderProd = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const WrapperBoxCard = styled("div")`
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px;
  .btn_order {
    width: 200px;
    margin: 0 auto;
    padding-top: 10px;
  }
`;
const WrapperProd = styled("div")`
  max-width: 500px;
  overflow: auto;
  padding: 2px 2px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

function CreatOrder() {
  const data = JSON.parse(localStorage.getItem("createorder"));
  const dispatch = useDispatch();
  const { product } = useSelector((i) => i.product);
  const { status } = useSelector((i) => i?.createOrder);

  useEffect(() => {
    dispatch(GetStockList());
  }, []);

  // const [method, setMethod] = useState("");
  // function save(e) {
  //   localStorage.setItem("method", e);
  //   setMethod(e);
  // }

  // useEffect(() => {
  //   const get = localStorage.getItem("method");
  //   if (get) {
  //     setMethod(get);
  //   }
  // }, []);

  useEffect(() => {
    if (status === "fulfilled") {
      NotificationManager.success("Заказ успешно оформлен");
      dispatch(CreateOrderActions.DefStatus());
    }
    if (status === "rejected") {
      NotificationManager.error("Произошла ошибка, повторите позже");
      dispatch(CreateOrderActions.DefStatus());
    }
  }, [status]);
  const [mobile, setmobile] = useState(false);
  const openclosemob = () => setmobile(!mobile);
  return (
    <Wrapper>
      <Products>
        <Header>
          <ModeratorNavBar btn={true} />
          <Button onClick={openclosemob} className="btn_mobile">
            Корзина
          </Button>
        </Header>
        <Renderproduct data={product?.results || []} />
        {product?.total_pages > 1 && (
          <Pogination count={product?.total_pages} />
        )}
      </Products>
      <MobileWrapper open={mobile}>
        <Button className="mobile_btn_close" onClick={openclosemob}>
          <ClearIcon />
        </Button>
        <div className="userinfo">
          <h3>Корзина</h3>
          <div>
            <p>Имя:</p>
            <h4>{data?.name}</h4>
          </div>
          <div>
            <p>Логин:</p>
            <h4>{data?.username}</h4>
          </div>
          <div>
            <p>Ф.И.О:</p>
            <h4>{data?.name}</h4>
          </div>
          <div>
            <p>Магазин:</p>
            <h4>{data?.magazine}</h4>
          </div>
        </div>
        <RenderBuyBox />
      </MobileWrapper>
      <Data>
        <div className="userinfo">
          <h3>Корзина</h3>
          <div>
            <p>Имя:</p>
            <h4>{data?.name}</h4>
          </div>
          <div>
            <p>Логин:</p>
            <h4>{data?.username}</h4>
          </div>
          <div>
            <p>Ф.И.О:</p>
            <h4>{data?.name}</h4>
          </div>
          <div>
            <p>Магазин:</p>
            <h4>{data?.magazine}</h4>
          </div>
        </div>
        <RenderBuyBox />
      </Data>
    </Wrapper>
  );
}

export default CreatOrder;
const WwrapperMethod = styled("div")`
  max-width: 500px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 10px;
  }
  & .box {
    cursor: pointer;
    margin-top: 20px;
    max-width: 450px;
    height: 75px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 20px;
    & > img {
      margin-right: 10px;
      width: 35px;
      height: 35px;
    }
  }
  & .activemethod {
    border: 1px solid #09234f;
  }
  @media screen and (max-width: 700px) {
    & > div {
      overflow: auto;
    }
  }
`;
const MobileWrapper = styled("div")`
  display: none;
  @media screen and (max-width: 700px) {
    .mobile_btn_close {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      border-radius: 50%;
      background: #b9b8b898;
      padding: 10px;
      & > svg {
        width: 35px;
        height: 35px;
      }
    }
    .userinfo {
      padding: 10px;
      width: 100%;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-top: 1px solid grey;
      border-left: 1px solid grey;
      border-right: 1px solid grey;
      & > div {
        display: flex;
        gap: 20px;
        & > h4 {
          color: gray;
        }
      }
    }
    display: block;
    position: fixed;
    z-index: 5550;
    right: 0px;
    top: 0px;
    overflow: auto;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    transition-duration: 1s;
    padding: ${(props) => (props?.open ? "10px" : "0px")};
    width: ${(props) => (props?.open ? "95%" : "95%")};
    transform: ${(props) =>
      props?.open ? "translateX(0px)" : "translateX(130%)"};
  }
`;
const Cards = styled("div")`
  display: grid;
  grid-template-columns: auto auto auto;
  @media screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }
`;
const Header = styled("div")`
  position: relative;
  .btn_mobile {
    display: none;
  }
  @media screen and (max-width: 700px) {
    .btn_mobile {
      display: block;
      position: absolute;
      top: 75px;
      width: 120px;
      height: 40px;
      padding: 0px;
      right: 0px;
    }
  }
`;
const Wrapper = styled("div")`
  display: grid;
  grid-template-columns: 60% 40%;
  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;
  }
`;
const Products = styled("div")``;
const Data = styled("div")`
  & .userinfo {
    padding: 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    & > div {
      display: flex;
      gap: 20px;
      & > h4 {
        color: gray;
      }
    }
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
`;
