import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactComponent as HomeIcon } from "../../assets/icons/Vector (4).svg";
import { ReactComponent as UserKatalog } from "../../assets/icons/usercatalogs.svg";
import { ReactComponent as Usercart } from "../../assets/icons/usercart.svg";
import { ReactComponent as UserProfile } from "../../assets/icons/avatar.svg";
import Button from "../ui/Button";
import PopUpCategory from "./PopUpCategory";
import PopUpUserProfile from "./userprofile/PopUpUserProfile";

const MobileFooter = () => {
  const { box } = useSelector((store) => store?.order);
  const [popupCategory, setpopupCategory] = useState();
  const [popupProfile, setpopupProfile] = useState();
  const [anchorElCategory, setanchorElCategory] = useState();
  const [anchorElCategory2, setanchorElCategory2] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const path = pathname?.split("/")[2];

  const navigateTo = (e) => navigate(e);

  const openProfilePopUp = (e) => {
    setanchorElCategory2(e.currentTarget);
    setpopupProfile(!popupProfile);
  };

  const openCatalogPopUp = (e) => {
    setanchorElCategory(e.currentTarget);
    setpopupCategory(!popupCategory);
  };

  const CloseMobile = () => {};

  return (
    <>
      {popupCategory && (
        <PopUpCategory
          open={popupCategory}
          anchorEl={anchorElCategory}
          onClose={openCatalogPopUp}
          mobileClose={CloseMobile}
        />
      )}
      {popupProfile && (
        <PopUpUserProfile
          open={popupProfile}
          anchorEl={anchorElCategory2}
          onClose={openProfilePopUp}
          mobileClose={CloseMobile}
        />
      )}
      <WrapperBox>
        <div>
          <div className={!path ? "active" : ""}>
            <span />
            <Button onClick={() => navigateTo("/user")}>
              <HomeIcon />
            </Button>
          </div>
          <div className={path === "catalog" ? "active" : ""}>
            <span />
            <Button onClick={openCatalogPopUp}>
              <UserKatalog />
            </Button>
          </div>
          <WrapperBtn className={path === "korzina" ? "active" : ""}>
            <span />
            <Button onClick={() => navigateTo("/user/korzina")}>
              {box?.length > 0 ? (
                <span className="box">{box?.length}</span>
              ) : (
                ""
              )}
              <Usercart />
            </Button>
          </WrapperBtn>
          <WrapperSvg
            active={
              path === "profile"
                ? "active"
                : path === "historysale"
                ? "active"
                : path === "stock"
                ? "active"
                : ""
            }
            className={
              path === "profile"
                ? "active"
                : path === "historysale"
                ? "active"
                : path === "stock"
                ? "active"
                : ""
            }
          >
            <span />
            <Button onClick={openProfilePopUp}>
              <UserProfile />
            </Button>
          </WrapperSvg>
        </div>
      </WrapperBox>
    </>
  );
};
export default MobileFooter;
const WrapperBtn = styled("div")`
  position: relative;
  .box {
    position: absolute;
    top: 0px;
    right: 0px;
    background: orange;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  & > button {
    position: relative;
  }
`;
const WrapperBox = styled("div")`
  position: fixed;
  bottom: 0px;
  height: 55px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(138, 149, 158, 0.23);
  .active {
    & > span {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition-duration: 0.6s;
      transform: scale(1.4, 1.4);
      top: 0px;
      left: 0px;
      background: white;
      border-top: 1px solid;
      z-index: 0;
    }
    & > button {
      background: #09234f;
    }
    & > button > svg {
      path {
        fill: white;
      }
      circle {
        fill: white;
        stroke: white;
      }
    }
  }
  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    & > div {
      position: relative;
    }
    & > div > button {
      width: 40px;
      height: 40px;
      padding: 0px;
      min-width: 30px;
      border-radius: 50%;
      background: none;
    }
    & > div:hover > span {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transform: scale(1.4, 1.4);
      transition-duration: 0.6s;
      top: 0px;
      left: 0px;
      background: white;
      border-top: 1px solid;
      z-index: 0;
    }
    & > div > button > svg {
      width: 25px;
      height: 25px;
      object-fit: cover;
      z-index: 4;
      path {
        fill: grey;
      }
      circle {
        fill: white;
        stroke: grey;
      }
    }
    & > div:hover > button > svg {
      path {
        fill: white;
      }
    }
  }
`;
const WrapperSvg = styled("div")`
  position: relative;
  & > button > svg {
    path {
      fill: ${(props) =>
        props?.active === "active" ? "black !important" : ""};
    }
  }
  & > button:hover > svg {
    path {
      fill: black !important;
    }
  }
`;
