import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import BasicModal from "../ui/BasicModal";
import ImagePicker from "../ui/ImagePicker";
import Input from "../ui/Input";
import Checkbox from "../../components/ui/Checkbox";
import { GetCities, GetDealerStatus } from "../../store/loginStore/Auth";
import Button from "../ui/Button";
import { SendNotificationPost } from "../../store/moderatorStore/discount";

function SentNotification({ open, onClose }) {
  const [dataSendNotification, setDataSendNotification] = useState({
    cities: [],
  });
  const { city, dealer_st } = useSelector((s) => s?.loginAuth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCities());
    dispatch(GetDealerStatus());
  }, []);

  const getPhoto = (e) => {
    setDataSendNotification({
      ...dataSendNotification,
      image: e[0]?.file,
    });
  };

  const getData = (name, e) => {
    setDataSendNotification({ ...dataSendNotification, [name]: e });
  };
  const edit = [
    {
      all: "Все город",
      list: [],
    },
  ];
  const editedCities = edit?.map((i) => {
    return {
      all: "Все город",
      list: city?.map((i) => {
        return {
          ...i,
          id: i.slug,
        };
      }),
    };
  });
  const editedDS = edit?.map((i) => {
    return {
      all: "Все пользователи",
      list: dealer_st?.map((i) => {
        return {
          ...i,
          id: i.main_title,
        };
      }),
    };
  });

  const sendData = () => {
    const data = {
      ...dataSendNotification,
      cities: dataSendNotification?.cities?.map((i) => i.slug),
      dealer_statuses: dataSendNotification?.dealer_statuses?.map((i) => i.id),
    };
    if (data.title && data.image && data.description) {
      dispatch(SendNotificationPost(data));
      onClose();
      setDataSendNotification({});
    }
  };

  return (
    <WapAll>
      <BasicModal height={"90%"} open={open} onClose={onClose}>
        <SendNotification>
          <h3>Отправить напоминание</h3>
          <div className="wrap_imageP">
            <div className="image_picker">
              <ImagePicker maxphotos={1} getPhoto={getPhoto} />
            </div>
            <span className="input_span">
              <Input
                value={dataSendNotification?.title || ""}
                name="title"
                onChange={(e) => getData(e.target.name, e.target.value)}
                label="Названия напоминание"
              />
            </span>
          </div>
          <Input
            value={dataSendNotification?.description || ""}
            name="description"
            onChange={(e) => getData(e.target.name, e.target.value)}
            label="Описания"
          />
          {editedCities?.map((i, ind) => (
            <Checkbox
              key={ind + "city"}
              value={dataSendNotification?.cities}
              getChoseData={(e) => getData("cities", e)}
              products={i.list}
              label={i.all}
              color={"#34B53A"}
            />
          ))}
          {editedDS?.map((i, ind) => (
            <Checkbox
              key={ind + "ds"}
              getChoseData={(e) => getData("dealer_statuses", e)}
              products={i.list}
              label={i.all}
              color={"#34B53A"}
            />
          ))}
          <WrapBtn>
            <Button variant={"outlined"} onClick={onClose}>
              Отмена
            </Button>
            <Button onClick={sendData}>Отправить</Button>
          </WrapBtn>
        </SendNotification>
      </BasicModal>
    </WapAll>
  );
}

export default SentNotification;
const WrapBtn = styled("div")`
  display: flex;
  gap: 15px;
`;
const WapAll = styled("div")``;
const SendNotification = styled("div")`
  padding: 15px;
  width: 100%;
  > h3 {
    margin-bottom: 10px;
  }
  & .wrap_imageP {
    display: flex;
    gap: 35px;
    align-items: end;
  }
  .input_span {
    width: 65%;
  }
  & .image_picker {
    width: 110px;
    height: 110px;
  }
  @media screen and (max-width: 700px) {
    width: 340px;
    & > h3 {
      font-size: 18px;
    }
    & .wrap_imageP {
      display: inline;
    }
    & .image_picker {
      width: 110px;
      height: 110px;
    }
  }
`;
