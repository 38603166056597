import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const MeInfoUser = createAsyncThunk(
  "user/MeInfoUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("user/me-info/");
      localStorage.setItem("user_id", data?.id);
      if (data?.status === "dealer") {
        localStorage.setItem("user_city", data?.city);
      }
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const NotificationIsRead = createAsyncThunk(
  "user/NotificationIsRead",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post("notification/u/", {
        notification_id: props,
      });
      dispatch(GetNotificationList());
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const CategoryTitle = createAsyncThunk(
  "user/CategoryTitle",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("category/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const SearchFunc = createAsyncThunk(
  "user/SearchFunc",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("product/list/search/" + props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const FilterMaxMin = createAsyncThunk(
  "user/FilterMaxMin",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("filter/max-min/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const ProductGetById = createAsyncThunk(
  "user/ProductGetById",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("product/list/" + props + "/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const ProductListPosts = createAsyncThunk(
  "user/ProductListPosts",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("review/list/" + props?.url, {
        product_id: props?.id,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const ProductPostLike = createAsyncThunk(
  "user/ProductPostLike",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("review/like/", {
        review: props,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const ProductNewAddPost = createAsyncThunk(
  "user/ProductNewAddPost",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("review/cd/", props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const ProductDeletePost = createAsyncThunk(
  "user/ProductDeletePost",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete("review/cd/" + props + "/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const BannerList = createAsyncThunk(
  "user/BannerList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("banner/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const BannerGetById = createAsyncThunk(
  "user/BannerGetById",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("banner/detail/", {
        banner: props,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetCityListUser = createAsyncThunk(
  "user/GetCityListUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetNotificationList = createAsyncThunk(
  "user/GetNotificationList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("user/notifications/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
const initialState = {
  data: null,
  user_id: localStorage.getItem("user_id") || "",
  notification_status: "",
  notification_not_read: [],
  category: [],
  categoryStatus: "",
  status: "",
  categoryData: {},
  categoryDataStatus: "",
  productById: null,
  productByIdStatus: "",
  likestatus: "",
  like_response: {},
  addnewPostStatusToastify: "",
  delete_post_status: "",
  new_posts_response: {},
  bannerStatus: "",
  banners: null,
  banner_detail: null,
  banner_detail_status: "",
  productPosts: {},
  productPosts_status: "",
  city_list: JSON.parse(localStorage.getItem("city")) || [],
  city_list_status: "",
  notification_list: null,
  filter_range: {},
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    ChangeStatusPost: (state) => {
      state.addnewPostStatusToastify = "";
      state.delete_post_status = "";
      state.notification_status = "";
    },
    ChangeBannerStatus: (state) => {
      state.banner_detail_status = "";
    },
  },
  extraReducers: {
    [FilterMaxMin.fulfilled]: (state, actions) => {
      state.filter_range = actions?.payload;
    },
    [FilterMaxMin.rejected]: (state) => {
      state.filter_range = {};
    },
    [GetNotificationList.fulfilled]: (state, actions) => {
      state.notification_list = actions?.payload;
    },
    [GetCityListUser.pending]: (state) => {
      state.city_list_status = "pending";
    },
    [GetCityListUser.fulfilled]: (state, actions) => {
      state.city_list_status = "success";
      state.city_list = actions.payload;
    },
    [GetCityListUser.rejected]: (state) => {
      state.city_list_status = "rejected";
    },
    [MeInfoUser.pending]: (state) => {
      state.status = "pending";
    },
    [MeInfoUser.fulfilled]: (state, actions) => {
      state.data = actions.payload;
      state.status = "success";
      state.notification_not_read = actions.payload?.notifications?.filter(
        (i) => !i?.is_read
      );
    },
    [MeInfoUser.rejected]: (state, actions) => {
      state.status = "rejected";
      state.data = actions.payload;
    },
    [NotificationIsRead.pending]: (state) => {
      state.notification_status = "pending";
    },
    [NotificationIsRead.fulfilled]: (state, actions) => {
      state.notification_status = "success";
    },
    [NotificationIsRead.rejected]: (state) => {
      state.notification_status = "rejected";
    },
    [CategoryTitle.fulfilled]: (state, actions) => {
      state.category = actions.payload;
      state.categoryStatus = "success";
    },
    [CategoryTitle.rejected]: (state, actions) => {
      state.category = actions.payload;
      state.categoryStatus = "rejected";
    },
    [SearchFunc.pending]: (state) => {
      state.categoryDataStatus = "pending";
    },
    [SearchFunc.fulfilled]: (state, actions) => {
      state.categoryData = actions.payload;
      state.categoryDataStatus = "success";
    },
    [SearchFunc.rejected]: (state, actions) => {
      state.categoryData = actions.payload;
      state.categoryDataStatus = "rejected";
    },
    [ProductGetById.pending]: (state) => {
      state.productByIdStatus = "pending";
    },
    [ProductGetById.fulfilled]: (state, actions) => {
      state.productById = actions.payload;
      state.productByIdStatus = "success";
    },
    [ProductGetById.rejected]: (state, actions) => {
      state.productById = actions.payload;
      state.productByIdStatus = "rejected";
    },
    [ProductPostLike.pending]: (state) => {
      state.likestatus = "pending";
    },
    [ProductPostLike.fulfilled]: (state, actions) => {
      state.likestatus = "success";
      state.like_response = actions.payload;
    },
    [ProductPostLike.rejected]: (state) => {
      state.likestatus = "rejected";
    },
    [ProductNewAddPost.pending]: (state) => {
      state.addnewPostStatusToastify = "pending";
    },
    [ProductNewAddPost.fulfilled]: (state, actions) => {
      state.addnewPostStatusToastify = "success";
      state.new_posts_response = actions.payload;
    },
    [ProductNewAddPost.rejected]: (state) => {
      state.addnewPostStatusToastify = "rejected";
    },
    [ProductDeletePost.pending]: (state) => {
      state.delete_post_status = "pending";
    },
    [ProductDeletePost.fulfilled]: (state) => {
      state.delete_post_status = "success";
    },
    [ProductDeletePost.rejected]: (state) => {
      state.delete_post_status = "rejected";
    },
    [BannerList.pending]: (state) => {
      state.bannerStatus = "pending";
    },
    [BannerList.fulfilled]: (state, actions) => {
      state.banners = actions.payload;
      state.bannerStatus = "success";
    },
    [BannerList.rejected]: (state, actions) => {
      state.banners = actions.payload;
      state.bannerStatus = "rejected";
    },
    [ProductListPosts.pending]: (state) => {
      state.productPosts_status = "pending";
    },
    [ProductListPosts.fulfilled]: (state, actions) => {
      state.productPosts = actions.payload;
      state.productPosts_status = "success";
    },
    [ProductListPosts.rejected]: (state) => {
      state.productPosts_status = "rejected";
    },
    [BannerGetById.pending]: (state) => {
      state.banner_detail_status = "pending";
    },
    [BannerGetById.fulfilled]: (state, actions) => {
      state.banner_detail_status = "success";
      state.banner_detail = actions.payload;
    },
    [BannerGetById.rejected]: (state) => {
      state.banner_detail_status = "rejected";
    },
  },
});

export const UserAction = UserSlice.actions;
export default UserSlice;
