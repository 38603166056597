import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import styled from "@emotion/styled";
import { NavbarButton } from "./NavbarButton";
import { AvatarAndLogo } from "./AvatarAndLogo";
import BurgerMenu from "./BurgerMenu";
import MAINLOGO from "../../assets/icons/mainasiabrandlogo.png";
import {
  getOrdersAccountantCount,
  getOrdersWareHouseCount,
} from "../../store/managerStore/orders";
import { discountActions } from "../../store/moderatorStore/discount";
import static_icon from "../../assets/icons/static_icon.svg";
import personIcon from "../../assets/icons/social.svg";
import managerIcon from "../../assets/icons/massage.svg";
import productIcon from "../../assets/icons/product.svg";
import productIcon2 from "../../assets/icons/storeProduct.svg";
import bannerIcon from "../../assets/icons/banners.svg";
import chat_icon from "../../assets/icons/chat_icon.svg";
import manager_stock from "../../assets/icons/manager_stock.svg";
import icon_category from "../../assets/icons/icon_category.svg";
import userCollection from "../../assets/icons/storage.svg";
import icon_abbalance from "../../assets/icons/icon_abbalance.svg";
import visit_icon from "../../assets/icons/visit_icon.svg";
import marketer from "../../assets/icons/marketer.svg";
import icon_ROP from "../../assets/icons/icon_ROP.svg";
import accountant from "../../assets/icons/accountant.svg";
import discount_icon from "../../assets/icons/discount_icon.svg";
import order_icon from "../../assets/icons/order.svg";
import cat_usericon from "../../assets/icons/cat_user.svg";
import didntordericon from "../../assets/icons/didntordericon.svg";
import { countReturnOrder } from "../../store/adminStore/OrderModeration";

const NavbarBox = () => {
  const { role } = useSelector((i) => i?.loginAuth);
  const count = useSelector((i) => i?.orders);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { send_notif } = useSelector((s) => s?.discount);
  const navigate = useNavigate();
  const { new_chat_count } = useSelector((s) => s?.chat);
  const new_message_count = useMemo(() => {
    let a = new_chat_count?.results?.reduce(
      (a, b) => (a += b?.new_messages_count),
      0
    );
    return a;
  }, [new_chat_count?.results, pathname]);
  const active = pathname.split("/")[2];
  let bool = true;
  useEffect(() => {
    if ((role === "accountant" || role === "main_manager") && bool) {
      dispatch(getOrdersAccountantCount());
    }
    if (role === "warehouse") {
      dispatch(getOrdersWareHouseCount());
    }
    bool = false;
  }, [role, bool, pathname]);
  useEffect(() => {
    if (send_notif === "success") {
      NotificationManager.success("Успешно отправлен!");
    } else if (send_notif === "rejected") {
      NotificationManager.error("Ошибка!");
    }
    dispatch(discountActions.removeSt());
  }, [send_notif]);

  const product_page = () => navigate("/home/product_page");
  const home_page = () => navigate("/home/dash_board");
  const Moderator_product_page = () => navigate("/home/products");
  const Moderator_banner_page = () => navigate("/home/banner");
  const Moderator_category_page = () => navigate("/home/category");
  const Moderator_collection_page = () => navigate("/home/collection");
  const polzovateli = () => navigate("/home/users");
  const chat = () => navigate("/home/chat");
  const zakaz = () => navigate("/home/zakaz");
  const zakaz_manager = () => navigate("/home/zakaz_manager");
  const toDiscount = () => navigate("/home/discount");
  const tomanager_stock = () => navigate("/home/warehouse");
  const balance = () => navigate("/home/balance");
  const toVisiting = () => navigate("/home/visiting");
  const manager_page = () => navigate("/home/managers");
  const rop_page = () => navigate("/home/rop");
  const product_rop = () => navigate("/home/product_rop");
  const accountant_page = () => navigate("/home/accountant");
  const tomarketer = () => navigate("/home/marketer");
  const returnOrder = () => navigate("/home/order_mod");
  const categoryUser = () => navigate("/home/cat_user");
  const reportWarehous = () => navigate("/home/report");
  const toRequisite = () => navigate("/home/requisite");
  const to_not_ordering = () => navigate("/home/not_ordering");

  const textRole = { role: "" };
  if (role === "director") {
    textRole.role = "Директор";
  } else if (role === "manager") {
    textRole.role = "Менеджер";
  } else if (role === "main_manager") {
    textRole.role = " РОП";
  } else if (role === "marketer") {
    textRole.role = "Маркетолог";
  } else if (role === "warehouse") {
    textRole.role = "Зав. склад";
  } else if (role === "accountant") {
    textRole.role = "Бухгалтер";
  }
  let count_warehouse =
    +(count?.warehouse_count?.card_point_count || 0) +
    +(count?.warehouse_count?.cash_count || 0);
  let count_accountant =
    +(count?.count?.card_count || 0) +
    +(count?.count?.cash_count || 0) +
    +(count?.count?.point_count || 0);
  let count_accountant_balance = count?.count?.balance_count;
  let count_cost_price = +(count?.count?.cost_count || 0);

  // console.log(count);
  return (
    <>
      <BurgerWrap>
        <Wrapeprtitle>
          <h3>ASIA BRAND</h3>
          <p>Мебельная фабрика</p>
        </Wrapeprtitle>
        <span>
          <AvatarAndLogo />
          <BurgerMenu
            role={role}
            ordersCount={count?.all_orders_count || 0}
            balanceCount={count_accountant_balance || 0}
            status={textRole.role}
          />
        </span>
      </BurgerWrap>
      <WrapperBox>
        <div>
          <div>
            <img width="170" src={MAINLOGO} alt="main logo" />
          </div>
          <h4>{textRole?.role}</h4>
          <WrapperButtons>
            <div>
              {role !== "accountant" && role !== "warehouse" && (
                <NavbarButton
                  onClick={home_page}
                  active={active === "dash_board" ? "active" : ""}
                  text="Статистика"
                  img={static_icon}
                />
              )}

              {role === "marketer" && (
                <>
                  <NavbarButton
                    onClick={Moderator_product_page}
                    active={active === "products" ? "active" : ""}
                    text="Продукты"
                    img={productIcon2}
                  />
                  <NavbarButton
                    onClick={Moderator_banner_page}
                    active={active === "banner" ? "active" : ""}
                    text="Баннер"
                    img={bannerIcon}
                  />
                  <NavbarButton
                    onClick={toDiscount}
                    active={active === "discount" ? "active" : ""}
                    text="Акция"
                    img={discount_icon}
                  />

                  <NavbarButton
                    onClick={Moderator_category_page}
                    text="Категории"
                    img={icon_category}
                    active={active === "category" ? "active" : ""}
                  />
                  <NavbarButton
                    onClick={Moderator_collection_page}
                    active={active === "collection" ? "active" : ""}
                    text="Коллекции"
                    img={userCollection}
                  />
                </>
              )}

              {role === "main_manager" && (
                <NavbarButton
                  onClick={manager_page}
                  active={active === "managers" ? "active" : ""}
                  text="Менеджеры"
                  img={managerIcon}
                />
              )}

              {role === "director" && (
                <>
                  <NavbarButton
                    onClick={tomarketer}
                    active={active === "marketer" ? "active" : ""}
                    text="Маркетолог"
                    img={marketer}
                  />
                  <NavbarButton
                    onClick={rop_page}
                    active={active === "rop" ? "active" : ""}
                    text="РОП"
                    img={icon_ROP}
                  />
                  <NavbarButton
                    onClick={accountant_page}
                    active={active === "accountant" ? "active" : ""}
                    text="Бухгалтер"
                    img={accountant}
                  />
                </>
              )}
              {(role === "manager" || role === "main_manager") && (
                <NavbarButton
                  onClick={polzovateli}
                  active={active === "users" ? "active" : ""}
                  text="Пользователи"
                  img={personIcon}
                />
              )}
              {role === "manager" && (
                <NavbarButton
                  onClick={tomanager_stock}
                  active={active === "warehouse" ? "active" : ""}
                  text="Зав. склад"
                  img={manager_stock}
                />
              )}
              {role === "main_manager" && (
                <NavbarButton
                  onClick={product_rop}
                  active={active === "product_rop" ? "active" : ""}
                  text="Товары"
                  img={productIcon}
                  count={count_cost_price || 0}
                />
              )}
              {role === "manager" && (
                <NavbarButton
                  onClick={chat}
                  active={active === "chat" ? "active" : ""}
                  text="Сообщения"
                  img={chat_icon}
                  count={new_message_count || 0}
                />
              )}
              {(role === "accountant" || role === "warehouse") && (
                <NavbarButton
                  onClick={zakaz}
                  active={active === "zakaz" ? "active" : ""}
                  text="Заказы"
                  img={order_icon}
                  count={
                    (role === "accountant"
                      ? count_accountant
                      : count_warehouse) || 0
                  }
                />
              )}
              {(role === "manager") && (
                <NavbarButton
                  onClick={zakaz_manager}
                  active={active === "zakaz_manager" ? "active" : ""}
                  text="Заказы"
                  img={order_icon}
                  count={0}
                />
              )}
              {role !== "marketer" && role !== "main_manager" && (
                <NavbarButton
                  onClick={product_page}
                  active={active === "product_page" ? "active" : ""}
                  text="Товары"
                  img={productIcon}
                />
              )}
              {role !== "manager" &&
                role !== "accountant" &&
                role !== "warehouse" && (
                  <NavbarButton
                    active={active === "visiting" ? "active" : ""}
                    text="Посещаемость"
                    onClick={toVisiting}
                    img={visit_icon}
                  />
                )}

              {role !== "warehouse" && (
                <NavbarButton
                  active={active === "balance" ? "active" : ""}
                  text="Баланс"
                  img={icon_abbalance}
                  onClick={balance}
                  count={
                    (role === "accountant" && count_accountant_balance) || 0
                  }
                />
              )}
              {(role === "accountant" ||
                role === "manager" ||
                role === "warehouse") && (
                  <NavbarButton
                    onClick={returnOrder}
                    active={active === "order_mod" ? "active" : ""}
                    text="Возврат"
                    img={order_icon}
                    count={+count?.count?.returns_count}
                  />
                )}
              {role === "main_manager" && (
                <NavbarButton
                  onClick={categoryUser}
                  active={active === "cat_user" ? "active" : ""}
                  text="Статус пол."
                  img={cat_usericon}
                />
              )}
              {role === "warehouse" && (
                <NavbarButton
                  onClick={reportWarehous}
                  active={active === "report" ? "active" : ""}
                  text="Отчет"
                  img={cat_usericon}
                />
              )}
              {role === "accountant" && (
                <NavbarButton
                  onClick={toRequisite}
                  active={active === "requisite" ? "active" : ""}
                  text="Реквизиты"
                  img={cat_usericon}
                />
              )}
              {(role === "manager" || role === "main_manager") && (
                <NavbarButton
                  onClick={to_not_ordering}
                  active={active === "not_ordering" ? "active" : ""}
                  text="Не заказали"
                  img={didntordericon}
                />
              )}
            </div>
          </WrapperButtons>
        </div>
      </WrapperBox>
      <WrapperStatus>
        <AvatarAndLogo />
      </WrapperStatus>
    </>
  );
};
export default NavbarBox;

const Wrapeprtitle = styled("div")`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  text-align: center;
  padding-top: 6px;
  margin-bottom: 6px;
  margin-left: 12px;
  & > h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
    letter-spacing: 0.2em;
  }
  & > p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.15em;
  }
`;
const BurgerWrap = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > span {
    display: flex;
  }
  @media screen and (min-width: 750px) {
    display: none;
  }
`;
const WrapperStatus = styled("div")`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background: #ffffffd4;
  display: flex;
  justify-content: flex-end;
  padding-right: 34px;
  z-index: 10;
  @media screen and (max-width: 750px) {
    display: none;
  }
`;
const WrapperButtons = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const WrapperBox = styled("div")`
  position: fixed;
  width: 237px;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 11;
  background: white;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  & > div > div {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    text-align: center;
    padding-top: 21px;
    margin-bottom: 47px;
    & > h3 {
      font-weight: 400;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.2em;
    }
    & > p {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.15em;
    }
  }
  & > div > h4 {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #09234f;
  }
  @media screen and (max-width: 750px) {
    display: none;
  }
`;
