import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const GetAllStockStaff = createAsyncThunk(
  "stock/GetAllStockStaff",
  async ({ city, page, page_size }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/personal/list/search/?status=warehouse&city=${city}&page=${
          page || 1
        }&page_size=${page_size || 15}`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const GetStock = createAsyncThunk(
  "stock/GetStock",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("stock/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const Stock = createSlice({
  name: "stock",
  initialState: {
    stock: null,
    status: "",
    stock_list: null,
    stock_status: ""
  },
  extraReducers: {
    [GetAllStockStaff.pending]: (state) => {
      state.status = "pending";
    },
    [GetAllStockStaff.fulfilled]: (state, actions) => {
      state.status = "fulfilled";
      state.stock = actions.payload;
    },
    [GetAllStockStaff.rejected]: (state) => {
      state.status = "rejected";
    },
    [GetStock.pending]: (state) => {
      state.stock_status = "pending";
    },
    [GetStock.fulfilled]: (state, actions) => {
      state.stock_status = "fulfilled";
      state.stock_list = actions.payload;
    },
    [GetStock.rejected]: (state) => {
      state.stock_status = "rejected";
    },
  },
});

export const StockActions = Stock.actions;
export default Stock;
