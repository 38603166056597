import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getOrdersAccountant = createAsyncThunk(
  "orders/getOrdersAccountant",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/orders/accountant/list/search/${props}&page_size=30`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getOrdersAccountantCount = createAsyncThunk(
  "orders/getOrdersAccountantCount",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/counter/`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getOrderById = createAsyncThunk(
  "orders/getOrderById",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/orders/${props.url}/list/${props.id}/`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const changeStatusAccountant = createAsyncThunk(
  "orders/changeStatusAccountant",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/accountant/order/moderation/`,
        props.data
      );
      dispatch(getOrderById({ url: props.url, id: props.data.order_id }));
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

///---------------------------------------------------------------warehouse-----
export const changeStatusWarehouse = createAsyncThunk(
  "orders/changeStatusWarehouse",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/warehouse/order/released/`,
        props.data
      );
      dispatch(getOrderById({ url: props.url, id: props.data.order_id }));
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getOrdersWareHouse = createAsyncThunk(
  "orders/getOrdersWareHouse",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/orders/warehouse/list/search/${props}&page_size=30`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getOrdersWareHouseCount = createAsyncThunk(
  "orders/getOrdersWareHouseCount",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/counter/`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchAcceptMoney = createAsyncThunk(
  "orders/fetchAcceptMoney",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/warehouse/order-paid/`, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatus = createAsyncThunk(
  "orders/changeStatus",
  async (
    { discount, dataOrder, status, valueComment },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.put(
        `crm/order/moderation/${dataOrder.id}/`,
        {
          discount: discount,
          status: status,
          address: dataOrder.address,
          currency: dataOrder.currency,
          duty_price: dataOrder.duty_price,
          gmail: dataOrder.gmail,
          name: dataOrder.name,
          phone: dataOrder.phone,
          price: dataOrder.price,
          comment: valueComment,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getTotalPriceOrders = createAsyncThunk(
  "orders/getTotalPriceOrders",
  async (props, { rejectWithValue }) => {
    try {
      if (props.city === "" || props.city === "Все") {
        delete props.city
      }
      if (props.name === "") {
        delete props.name
      }
      if (props.o_t_status === "") {
        delete props.o_t_status
      }
      if (!props.date) {
        delete props.date
      }
      if (props.status === "" || props.status === "Все") {
        delete props.status
      }
      const { data } = await axiosInstance.post(
        `crm/order/total-price/`,
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

const initialState = {
  orders: [],
  order: {},
  order_status: "",
  orders_status: "",
  order_byid: {},
  count: {},
  warehouse_count: {},
  saveorders: [],
  order_mod_status: "",
  totalAmountOfOrder: "",
  acceptMoney_status: "",
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    changeStatus: (state) => {
      state.order_status = "";
      state.order_mod_status = "";
      state.acceptMoney_status = "";
    },
    remove_status: (state) => {
      state.order = {};
    },
    saveOrders: (state, { payload }) => {
      const prod = [...state.saveorders];
      prod.push(payload);
      state.saveorders = prod;
    },
  },
  extraReducers: {
    [getTotalPriceOrders.fulfilled]: (state, { payload }) => {
      state.totalAmountOfOrder = payload;
    },
    // ---accept money---
    [fetchAcceptMoney.fulfilled]: (state, { payload }) => {
      state.acceptMoney_status = "success";
    },
    //--------------------------------------------------
    [getOrdersWareHouseCount.fulfilled]: (state, { payload }) => {
      state.warehouse_count = payload;
    },
    //--------------------------------------------------

    [getOrdersAccountantCount.fulfilled]: (state, { payload }) => {
      state.count = payload;
    },

    [getOrdersAccountant.pending]: (state, { payload }) => {
      state.orders_status = "pending";
    },
    [getOrdersAccountant.fulfilled]: (state, { payload }) => {
      state.orders = payload;
      state.orders_status = "success";
    },
    [getOrdersAccountant.rejected]: (state, { payload }) => {
      state.orders_status = "rejected";
    },
    //----------------------------------------------------
    // [changeStatus.pending]: (state) => {
    //   state.order_status = "pending";
    // },
    // [changeStatus.fulfilled]: (state, { payload }) => {
    //   state.order = payload;
    //   state.order_status = payload.status;
    // },
    // [changeStatus.rejected]: (state) => {
    //   state.order_status = "rejected";
    // },

    ////---------------------------------------------------------------
    [getOrderById.pending]: (state) => {
      state.order_byid_st = "pending";
    },
    [getOrderById.fulfilled]: (state, { payload }) => {
      state.order_byid = payload;
      state.order_byid_st = "success";
    },
    [getOrderById.rejected]: (state) => {
      state.order_byid_st = "rejected";
    },

    //-------------------------------------------------------------------
    [changeStatusAccountant.pending]: (state) => {
      state.order_mod_status = "pending";
    },
    [changeStatusAccountant.fulfilled]: (state, { payload }) => {
      state.order_status = payload.status;
      state.order_mod_status = "success";
    },
    [changeStatusAccountant.rejected]: (state) => {
      state.order_mod_status = "rejected";
    },

    //------------------------warehouse---------------------
    [getOrdersWareHouse.fulfilled]: (state, { payload }) => {
      state.orders = payload;
    },
    [changeStatusWarehouse.fulfilled]: (state, { payload }) => {
      state.order_status = payload.status;
    },
    [changeStatusWarehouse.pending]: (state) => {
      state.order_mod_status = "pending";
    },
    [changeStatusWarehouse.rejected]: (state) => {
      state.order_mod_status = "rejected";
    },
  },
});

export const ordersActions = ordersSlice.actions;
export default ordersSlice;
