import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const fetchGetManagerOrders = createAsyncThunk(
    "managerorder/fetchGetManagerOrders",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`crm/orders/manager/list/${props}`);
            return data;
        } catch (error) {
            return rejectWithValue();
        }
    }
);
export const fetchGetManagerOrdersById = createAsyncThunk(
    "managerorder/fetchGetManagerOrdersById",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`crm/orders/manager/list/${props}/`);
            return data;
        } catch (error) {
            return rejectWithValue();
        }
    }
);

export const fetchDeleteOrder = createAsyncThunk(
    "managerorder/fetchDeleteOrder",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(`crm/order-new/delete/`, props);
            return data;
        } catch (error) {
            return rejectWithValue();
        }
    }
);

const initialState = {
    order_list: {},
    order_byid: {},
    orderlist_status: "",
    orderbyid_tatus: "",
    deleteorder_tatus: "",
};

const managerOrderSlice = createSlice({
    name: "managerorder",
    initialState,
    reducers: {
        clearStatuses: (state) => {
            state.orderlist_status = ""
            state.orderbyid_tatus = ""
            state.deleteorder_tatus = ""
        },
    },
    extraReducers: {
        // -----delete order------
        [fetchDeleteOrder.fulfilled]: (state) => {
            state.deleteorder_tatus = "success";
        },
        // --------------------
        [fetchGetManagerOrdersById.pending]: (state) => {
            state.orderbyid_tatus = "pending";
        },

        [fetchGetManagerOrdersById.fulfilled]: (state, { payload }) => {
            state.order_byid = payload;
            state.orderbyid_tatus = "success";
        },
        /////-------------------------------------------
        [fetchGetManagerOrders.pending]: (state) => {
            state.orderlist_status = "pending";
        },

        [fetchGetManagerOrders.fulfilled]: (state, { payload }) => {
            state.order_list = payload;
            state.orderlist_status = "success";
        },
        /////-------------------------------------------

    },
});

export const managerOrderActions = managerOrderSlice.actions;
export default managerOrderSlice;
