import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarBox from "../components/mainComponent/NavbarBox";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../store/managerStore/profile";
import Spinner from "../components/ui/Spinner";
import { getChatListNewMessage } from "../store/managerStore/Chat";

function MainLayout() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { role } = useSelector((s) => s.loginAuth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (role === "marketer") {
      dispatch(getReviews());
    }
    if (role === "manager") {
      dispatch(getChatListNewMessage());
    }
  }, [pathname]);

  return (
    <>
      <NavbarBox />
      <WrapperContent>
        <WrapperBox />
        <WrapperRenderBox>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </WrapperRenderBox>
      </WrapperContent>
    </>
  );
}
export default MainLayout;
const WrapperRenderBox = styled("div")`
  width: 81.5%;

  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    width: 75%;
  }
  @media screen and (max-width: 950px) {
    width: 65%;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;
const WrapperContent = styled("div")`
  background: white;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  display: flex;
  margin-top: 50px;
  @media screen and (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
  }
`;
const WrapperBox = styled("div")`
  width: 250px;
  @media screen and (max-width: 750px) {
    display: none;
    width: 0px;
  }
`;
