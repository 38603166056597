import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const fetchGetDidntOrderUsers = createAsyncThunk(
    "didntorder/fetchGetDidntOrderUsers",
    async ({ body, query }, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                `crm/dont-paid/user/list/${query}`, body
            );
            return data;
        } catch (error) {
            return rejectWithValue();
        }
    }
);

export const fetchGetDidntOrderOneUser = createAsyncThunk(
    "didntorder/fetchGetDidntOrderOneUser",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                `crm/user/last-order/`, props
            );
            return data;
        } catch (error) {
            return rejectWithValue();
        }
    }
);


const initialState = {
    didntOrderUsers: [],
    last_order: {},
    didntOrderUsers_status: "",
    last_order_status: "",
};

const didntorderSlice = createSlice({
    name: "didntorder",
    initialState,
    reducers: {
        clearStatuses: (state) => {
            state.didntOrderUsers_status = "";
            state.last_order_status = "";
        },

    },
    extraReducers: {

        // get by id one user
        [fetchGetDidntOrderOneUser.pending]: (state, { payload }) => {
            state.last_order_status = "pending";
        },
        [fetchGetDidntOrderOneUser.fulfilled]: (state, { payload }) => {
            state.last_order = payload;
            state.last_order_status = "success";
        },
        [fetchGetDidntOrderOneUser.rejected]: (state, { payload }) => {
            state.last_order_status = "rejected";
        },


        [fetchGetDidntOrderUsers.pending]: (state, { payload }) => {
            state.didntOrderUsers_status = "pending";
        },
        [fetchGetDidntOrderUsers.fulfilled]: (state, { payload }) => {
            state.didntOrderUsers = payload;
            state.didntOrderUsers_status = "success";
        },
        [fetchGetDidntOrderUsers.rejected]: (state, { payload }) => {
            state.didntOrderUsers_status = "rejected";
        },

    },
});

export const didntorderActions = didntorderSlice.actions;
export default didntorderSlice;
