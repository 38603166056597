import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const PostStats = createAsyncThunk(
  "stats/PostStats",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("filter_by", props?.type || "day");
      formdata.append("start", props?.from);
      formdata.append("end", props?.to);
      if (props?.city) {
        formdata.append("city", props?.city);
      }

      const { data } = await ImageUploadingFetch.post(
        "crm/stats/order/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const PostStatsCity = createAsyncThunk(
  "stats/PostStatsCity",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("category", props?.type || "day");
      formdata.append("start_date", props?.from);
      formdata.append("end_date", props?.to);
      const { data } = await ImageUploadingFetch.post(
        "crm/stats/cities/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetCityList = createAsyncThunk(
  "stats/GetCityList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetDetailProductStats = createAsyncThunk(
  "stats/GetDetailProductStats",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "crm/stats/order/products/",
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetDetailProductStatsUser = createAsyncThunk(
  "stats/GetDetailProductStatsUser",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "crm/stats/order/clients/",
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetProductByIdStats = createAsyncThunk(
  "stats/GetProductByIdStats",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "product/list/" + props?.id + "/"
      );
      return { ...data, mutagen: props?.mutagen };
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetSlug = createAsyncThunk(
  "stats/GetSlug",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("category/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetDealerStatsInfo = createAsyncThunk(
  "stats/GetDealerStatsInfo",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/crm/stats/dealer/order-list/",
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const DashboardSlice = createSlice({
  name: "stats",
  initialState: {
    stats: [],
    stats_status: "",
    city: JSON.parse(localStorage.getItem("city")) || [],
    city_status: "",
    slug: null,
    slug_status: "",
    dateFromTo: {
      from: localStorage.getItem("dashboard_startDate") || "",
      to: localStorage.getItem("dashboard_endDate") || "",
      type: "",
    },
    stats_city: null,
    stata_city_status: "",
    product_stats: [],
    product_stats_status: "",
    dealer_detail: null,
    dealer_detail_status: "",
    stats_detail_product: null,
    stats_users_product: null,
  },
  reducers: {
    AddDateFromTo: (state, actions) => {
      state.dateFromTo.from = actions.payload.from;
      state.dateFromTo.to = actions.payload.to;
      state.dateFromTo.type = actions.payload.type;
    },
    ChangeStatus: (state) => {
      state.stats_status = "";
    },
    ChangeStatusProduct: (state) => {
      state.product_stats_status = "";
    },
    ChangeProductState: (state) => {
      state.product_stats = [];
    },
  },
  extraReducers: {
    [GetDetailProductStats.pending]: (state) => {
      state.dealer_detail_status = "pending";
    },
    [GetDetailProductStats.fulfilled]: (state, actions) => {
      state.dealer_detail_status = "success";
      state.stats_detail_product = actions?.payload;
    },
    [GetDetailProductStats.rejected]: (state) => {
      state.dealer_detail_status = "rejected";
    },
    [GetDetailProductStatsUser.fulfilled]: (state, actions) => {
      state.dealer_detail_status = "success";
      state.stats_users_product = actions?.payload;
    },

    [GetDealerStatsInfo.pending]: (state) => {
      state.dealer_detail_status = "pending";
    },
    [GetDealerStatsInfo.fulfilled]: (state, actions) => {
      state.dealer_detail_status = "success";
      state.dealer_detail = actions?.payload;
    },
    [GetDealerStatsInfo.rejected]: (state) => {
      state.dealer_detail_status = "rejected";
    },
    [GetProductByIdStats.pending]: (state) => {
      state.product_stats_status = "pending";
    },
    [GetProductByIdStats.fulfilled]: (state, actions) => {
      state.product_stats_status = "success";
      state.product_stats.push(actions.payload);
    },
    [GetProductByIdStats.rejected]: (state) => {
      state.product_stats_status = "rejected";
      state.product_stats = [];
    },
    [PostStatsCity.pending]: (state) => {
      state.stata_city_status = "pending";
    },
    [PostStatsCity.fulfilled]: (state, actions) => {
      state.stata_city_status = "success";
      const arr = [];
      for (const i in actions.payload) {
        arr.push({ value: actions.payload[i], city: i });
      }
      state.stats_city = arr;
    },
    [PostStatsCity.rejected]: (state) => {
      state.stata_city_status = "rejected";
    },
    [PostStats.pending]: (state) => {
      state.stats_status = "pending";
    },
    [PostStats.fulfilled]: (state, actions) => {
      const arr = [];
      for (const i in actions.payload) {
        arr.push(actions.payload[i]);
      }
      state.stats = [...arr].reverse();
      state.stats_status = "success";
    },
    [PostStats.rejected]: (state) => {
      state.stats = [];
      state.stats_status = "rejected";
    },
    [GetCityList.pending]: (state) => {
      state.city_status = "pending";
    },
    [GetCityList.fulfilled]: (state, actions) => {
      state.city_status = "success";
      state.city = actions.payload;
    },
    [GetCityList.rejected]: (state) => {
      state.city_status = "rejected";
    },
    [GetSlug.pending]: (state) => {
      state.slug_status = "pending";
    },
    [GetSlug.fulfilled]: (state, actions) => {
      state.slug = actions.payload;
      state.slug_status = "success";
    },
    [GetSlug.rejected]: (state) => {
      state.slug_status = "rejected";
    },
  },
});

export const DashboardActions = DashboardSlice.actions;
export default DashboardSlice;
