import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { fetchGetManagerOrders } from "../../store/managerStore/managerOrders";
import { Box } from "@mui/system";
import { Pagination, Typography } from "@mui/material";
import DatePopover from "../../components/buhgalterComponents/DatePopover";
import { ReactComponent as FilterIcon } from "../../assets/icons/calendar.svg";
import Button from "../../components/ui/Button";
import { format } from "date-fns";
import { formatDate } from "../../utils/helpers";

const get_title_city = (e, text) => e?.find((i) => i?.slug === text);

function ManagerOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { order_list } = useSelector((s) => s.managerorder);
  const { city } = useSelector((s) => s?.visit);
  const getPage = params.get("page") || 1;
  const name = params.get("name");
  const date = params.get("date");

  useEffect(() => {
    let queryList = `search/?page=1&page_size=30`;
    if (name) {
      queryList += `&name=${name}`;
    }
    if (date) {
      queryList += `&date=${date}`;
    } else {
      const start = new Date();
      const formattedStart = format(start, "dd-MM-yyyy");
      params.set("date", `${formattedStart + "$" + formattedStart}`);
      setParams(params);
      return;
    }

    dispatch(fetchGetManagerOrders(queryList));
  }, [date]);

  const [isOpenFilter, setIsOpenFilter] = useState({
    anchorEl: null,
    popopver: false,
  });
  const openFilterHandle = (e) => {
    setIsOpenFilter((prev) => ({
      ...prev,
      anchorEl: e?.currentTarget,
      popopver: !isOpenFilter?.popopver,
    }));
  };

  const applyDateHandle = (e) => {
    const start = new Date(e?.start);
    const formattedStart = format(start, "dd-MM-yyyy");
    const end = new Date(e?.end);
    const formattedEnd = format(end, "dd-MM-yyyy");

    params.set("date", `${formattedStart + "$" + formattedEnd}`);
    setParams(params);

    openFilterHandle();
  };

  const searchButtonHandler = (e) => {
    e.preventDefault();
    let queryList = `search/?page=1&page_size=30`;
    if (name) {
      queryList += `&name=${name}`;
    }
    if (date) {
      queryList += `&date=${date}`;
    }

    dispatch(fetchGetManagerOrders(queryList));
  };

  const searchInputHandle = (e) => {
    const value = e.target.value;
    params.set("name", value);
    setParams(params.toString());
  };
  const onChangePagination = (e, value) => {
    params.set("page", value);
    setParams(params);
    let queryList = `search/?page=${value}&page_size=30`;
    if (name) {
      queryList += `&name=${name}`;
    }
    if (date) {
      queryList += `&date=${date}`;
    }
    dispatch(fetchGetManagerOrders(queryList));
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  };

  const toInnerPage = ({ id }) => {
    navigate(`${id}`);
  };
  return (
    <Wrapper>
      <DatePopover
        applyHandle={applyDateHandle}
        open={isOpenFilter.popopver}
        onClose={openFilterHandle}
        anchorEl={isOpenFilter.anchorEl}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Заказы</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{whiteSpace: "nowrap", marginRight: "8px"}}>От: {date?.split('$').join(' до: ')}</Typography>
          <Button onClick={openFilterHandle} style={{ height: "35px" }}>
            Фильтр <FilterIcon style={{ marginLeft: "5px" }} />
          </Button>
          <Box
            sx={{ marginLeft: "35px" }}
            onSubmit={searchButtonHandler}
            component="form"
          >
            <div className="search_btn">
              <InputStyled
                value={params?.get("name") || ""}
                onChange={searchInputHandle}
                placeholder="Поиск..."
              />
              <Button onClick={searchButtonHandler}>Поиск</Button>
            </div>
          </Box>
        </Box>
      </Box>
      <StyledTable>
        <thead>
          <tr>
            <th>Id</th>
            <th className="th">Ф.И.О</th>
            <th className="th">Город отгрузки</th>
            <th>Сумма</th>
            <th>Оплата</th>
            <th>Создано</th>
            <th>Оплачено</th>
            <th>Отгружено</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {order_list?.results?.map((i) => (
            <tr onClick={() => toInnerPage(i)}>
              <td>{i.id} </td>
              <td>{i.name} </td>
              <td>{get_title_city(city || [], i.city_stock)?.title} </td>
              <td>{i.price} </td>
              <td>{i.type_status} </td>
              <td className="style_date">
                {formatDate(i?.created_at.slice(0, 10)) +
                  " / " +
                  i?.created_at?.split("T")[1]?.slice(0, 5)}
              </td>
              <td className="style_date">
                {formatDate(i?.created_at.slice(0, 10)) +
                  " / " +
                  i?.created_at?.split("T")[1]?.slice(0, 5)}
              </td>
              <td className="style_date">
                {formatDate(i?.released_at.slice(0, 10)) +
                  " / " +
                  i?.released_at?.split("T")[1]?.slice(0, 5)}
              </td>
              <td>{i.status}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      {order_list?.total_pages > 1 && (
        <div className="pagination_wrap">
          <div>
            {order_list.total_pages > 1 && (
              <Pagination
                count={order_list?.total_pages}
                page={+getPage}
                onChange={onChangePagination}
                size="large"
                variant="outlined"
                shape="rounded"
                siblingCount={1}
                boundaryCount={1}
              ></Pagination>
            )}
          </div>
          <span>Страниц: {order_list?.total_pages}</span>
        </div>
      )}
    </Wrapper>
  );
}

export default ManagerOrders;

const Wrapper = styled("div")`
  .style_date {
    font-size: 14px;
  }
  & .search_btn {
    display: flex;
    align-items: center;
    gap: 15px;
    & > button {
      height: 40px;
      width: 100px;
    }
  }
`;

const InputStyled = styled("input")`
  width: 170px;
  height: 40px;
  padding: 8px;
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  outline: none;
`;

const StyledTable = styled("table")`
  width: 100%;
  border-bottom: 1px solid #f1f4f9;
  border-radius: 4px;
  margin-top: 20px;
  border-collapse: collapse;
  & .th_filter {
    cursor: pointer;
  }
  & .notreaded {
    background: #dadbdb;
  }
  & .skeleton td {
    padding: 0;
  }
  & thead {
    width: 100%;
  }
  & > thead > tr > th {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #809fb8;
    text-align: left;
  }

  & > tbody > tr > td {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    padding: 8px 8px 8px 0;
    padding-right: 10px;
    white-space: nowrap;
  }

  tbody > tr {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #f1f4f9;
    :hover {
      background: #f1f4f9;
      border-radius: 4px;
    }
  }
  thead > tr {
    padding: 8px 0 8px 5px;
  }
`;
