import React from "react";
import AllRoute from "./routes/AllRoute";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

const App = () => {
  // console.log("app");
  return (
    <>
      <AllRoute />
      <NotificationContainer />
    </>
  );
};

export default App;
