import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import BellNotification from "./BellNotification";
import PopUp from "../ui/PopUp";
import Button from "../ui/Button";
import { AuthAction } from "../../store/loginStore/Auth";
import SentNotification from "./SentNotification";
import { getProfile } from "../../store/managerStore/profile";
import avatarLogo from "../../assets/icons/avatar.svg";
import { ReactComponent as ButtonLogOut } from "../../assets/icons/btn_logout.svg";
import { ReactComponent as IconSendNotification } from "../../assets/icons/icon_sendnotif.svg";
import notificationIcon from "../../assets/icons/notification.svg";

export const AvatarAndLogo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isEnchorEl, setIsEnchorEl] = useState(null);
  const { role } = useSelector((i) => i?.loginAuth);
  const [isOpenSentNotif, setIsOpenSendNotif] = useState(false);
  const { profile, reviews } = useSelector((state) => state.profile);
  let loop = true;
  useEffect(() => {
    if (loop) {
      dispatch(getProfile());
    }
    loop = false;
  }, [loop]);
  const openPupUpHandler = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenPopUp(!openPopUp);
  };

  const closePopUpHandler = () => {
    setOpenPopUp(false);
    setAnchorEl(null);
  };
  const isOpenPopUpLogOutHandler = (e) => {
    setIsEnchorEl(e.currentTarget);
    setIsOpenPopUp(!isOpenPopUp);
  };
  const logoutHandler = () => {
    dispatch(AuthAction.Logout());
  };
  const isOpenSentNotifHandler = () => {
    setIsOpenSendNotif(!isOpenSentNotif);
  };

  return (
    <WrapperBox>
      {openPopUp && (
        <BellNotification
          anchorEl={anchorEl}
          open={openPopUp}
          close={closePopUpHandler}
        />
      )}
      <abbr title="Профиль">
        <img
          className="avatar"
          loading="lazy"
          onClick={() => navigate("profile")}
          alt="logo avatar"
          src={profile.image || avatarLogo}
        />
      </abbr>
      <p>{profile?.name}</p>
      {role === "marketer" && (
        <div className="box" onClick={openPupUpHandler}>
          <img loading="lazy" alt="notification" src={notificationIcon} />
          {reviews?.length > 0 && (
            <span>
              <p>{reviews?.length}</p>
            </span>
          )}
        </div>
      )}
      {role === "marketer" && (
        <abbr title="Отправить напоминание">
          <IconSendNotification onClick={isOpenSentNotifHandler} />
        </abbr>
      )}
      <SentNotification
        onClose={isOpenSentNotifHandler}
        open={isOpenSentNotif}
      />
      {isOpenPopUp && (
        <PopUp
          anchorEl={isEnchorEl}
          open={isOpenPopUp}
          onClose={isOpenPopUpLogOutHandler}
        >
          <InnerLogoutPopUp>
            <h4>Вы хотите выйти?</h4>
            <div>
              <Button onClick={isOpenPopUpLogOutHandler} variant={"outlined"}>
                Нет
              </Button>
              <Button onClick={logoutHandler} variant={"danger"}>
                Выйти
              </Button>
            </div>
          </InnerLogoutPopUp>
        </PopUp>
      )}
      <ButtonLogOut onClick={isOpenPopUpLogOutHandler} />
    </WrapperBox>
  );
};
const InnerLogoutPopUp = styled("div")`
  padding: 10px;
  & > h4 {
    margin-bottom: 8px;
    font-size: 14px;
  }
  & > div {
    display: flex;
  }
  & > div > button {
    font-size: 12px;
    height: 30px;
    border-radius: 4;
  }
  & > div > button:first-of-type {
    margin-right: 4px;
  }
  & > div > button:last-of-type {
    margin-left: 4px;
  }
`;
const WrapperBox = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  & .avatar {
    margin-left: 15px;
  }
  & > div {
    margin-left: 22px;
    position: relative;
    cursor: pointer;
    & > span {
      position: absolute;
      top: 0px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      left: 14px;
      top: 0px;
      background: #f84135;
      display: flex;
      align-items: center;
      justify-content: center;
      & > p {
        font-size: 7px;
        color: white;
      }
    }
  }
  & .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }
  @media screen and (max-width: 700px) {
    & .avatar {
      display: none;
    }
    & > p {
      display: none;
    }

    & > div {
      margin: 0px;
    }
    & > svg {
      display: none;
    }
  }
`;
