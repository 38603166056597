import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getAccountingResults = createAsyncThunk(
  "occounting/getAccountingResults",
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/stats/manager/`, {
        start_date,
        end_date,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getAccountingInnerPage = createAsyncThunk(
  "occounting/getAccountingInnerPage",
  async ({ start_date, end_date, city }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/stats/manager/order-list/`, {
        start_date,
        end_date,
        city,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const initialState = {
  accountingResult: [],
  accounting_inner: [],
};

const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  extraReducers: {
    //----------------------------------------------------
    [getAccountingResults.fulfilled]: (state, { payload }) => {
      state.accountingResult = payload;
    },
    //----------------------------------------------------
    [getAccountingInnerPage.fulfilled]: (state, { payload }) => {
      state.accounting_inner = payload;
    },
  },
});

export const accountingActions = accountingSlice.actions;
export default accountingSlice;
