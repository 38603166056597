import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const OrderReturn = createAsyncThunk(
  "order_mod/OrderReturn",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/sync/return-order/crm-to-1c/`,
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetOrderReturnWearhouse = createAsyncThunk(
  "order_mod/GetOrderReturnWearhouse",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/sync/return-order/crm-to-1c/` + props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetOrderReturnOther = createAsyncThunk(
  "order_mod/GetOrderReturnOther",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "crm/sync/return-order-moderation/crm-to-1c/" + props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetStoksList = createAsyncThunk(
  "order_mod/GetStoksList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`stock/list/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const ProductGetDetail = createAsyncThunk(
  "order_mod/ProductGetDetail",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("product/list/" + props + "/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const ProductGetDetailExchange = createAsyncThunk(
  "order_mod/ProductGetDetailExchange",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "crm/product/crud/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const OrderModerationProduct = createAsyncThunk(
  "order_mod/OrderModerationProduct",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        "crm/sync/return-order-moderation/crm-to-1c/" + props?.id + "/",
        props?.body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);



const OrderModeration = createSlice({
  name: "order_mod",
  initialState: {
    status: "",
    return_status: "",
    data: null,
    stock: null,
    stock_status: "",
    product: [],
    product_status: "",
    exchange_product: [],
    sxchange_status: "",
    mod_status: "",
    getproduct_status: ""
  },
  reducers: {
    clearProduct: (state) => {
      state.product = [];
      state.exchange_product = [];
    },
    changeStatus: (state) => {
      state.status = "";
      state.mod_status = "";
      state.return_status = "";
    },
  },
  extraReducers: {

    // ---------------------------------------------------
    // ---------------------------------------------------
    [ProductGetDetail.pending]: (state) => {
      state.product_status = "pending";
    },
    [ProductGetDetail.fulfilled]: (state, actions) => {
      state.product_status = "fulfilled";
      state.product.push(actions.payload);
    },
    // ----------------------------------------------------------
    [ProductGetDetailExchange.pending]: (state, actions) => {
      state.getproduct_status = "pending";
    },
    [ProductGetDetailExchange.fulfilled]: (state, actions) => {
      state.getproduct_status = "success";
      state.exchange_product.push(actions.payload);
    },
    [ProductGetDetailExchange.rejected]: (state) => {
      state.getproduct_status = "reject";
    },
    // ------------------------------------------------------
    [ProductGetDetail.rejected]: (state) => {
      state.product_status = "rejected";
    },
    [OrderModerationProduct.pending]: (state) => {
      state.mod_status = "pending";
    },
    [OrderModerationProduct.fulfilled]: (state) => {
      state.mod_status = "fulfilled";
    },
    [OrderModerationProduct.rejected]: (state) => {
      state.mod_status = "rejected";
    },
    [OrderReturn.pending]: (state) => {
      state.return_status = "pending";
    },
    [OrderReturn.fulfilled]: (state) => {
      state.return_status = "fulfilled";
    },
    [OrderReturn.rejected]: (state) => {
      state.return_status = "rejected";
    },
    [GetStoksList.pending]: (state) => {
      state.stock_status = "pending";
    },
    [GetStoksList.fulfilled]: (state, actions) => {
      state.stock_status = "fulfilled";
      state.stock = actions.payload;
    },
    [GetStoksList.rejected]: (state) => {
      state.stock_status = "rejected";
    },
    [GetOrderReturnWearhouse.pending]: (state) => {
      state.status = "pending";
    },
    [GetOrderReturnWearhouse.fulfilled]: (state, actions) => {
      state.status = "fulfilled";
      state.data = actions.payload;
    },
    [GetOrderReturnWearhouse.rejected]: (state) => {
      state.status = "rejected";
    },
    [GetOrderReturnOther.pending]: (state) => {
      state.status = "pending";
    },
    [GetOrderReturnOther.fulfilled]: (state, actions) => {
      state.status = "fulfilled";
      state.data = actions.payload;
    },
    [GetOrderReturnOther.rejected]: (state) => {
      state.status = "rejected";
    },
  },
});

export const OrderModerationActions = OrderModeration.actions;
export default OrderModeration;
