import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const GetProducts = createAsyncThunk(
  "product/GetProducts",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("crm/product/crud/" + props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetProductById = createAsyncThunk(
  "product/GetProductById",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "crm/product/crud/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const DeleteProduct = createAsyncThunk(
  "product/DeleteProduct",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        "crm/product/crud/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
const SendPhoto = createAsyncThunk(
  "product/SendPhoto",
  async (props, { rejectWithValue }) => {
    try {
      if (props.images[0]?.file) {
        const formData = new FormData();
        for (let i = 0; i < props.images.length; i++) {
          formData.append("images", props.images[i]?.file);
        }
        formData.append("product", props.id);
        const { data } = await ImageUploadingFetch.post(
          "crm/product/image/create/",
          formData
        );
        return data;
      }
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const CreateNewProduct = createAsyncThunk(
  "product/CreateNewProduct",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const normalaizBody = props.data?.sizes?.map((i) => {
        return {
          width: i?.width,
          height: i?.height,
          length: i?.length,
          weight: i?.weight,
          title: i?.title,
        };
      });
      const { data } = await axiosInstance.post("crm/product/crud/", {
        ...props.data,
        sizes: normalaizBody,
      });
      if (data) {
        dispatch(SendPhoto({ images: props.images, id: data.id }));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const EditProduct = createAsyncThunk(
  "product/EditProduct",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const normalaizBody = props.data?.sizes?.map((i) => {
        return {
          width: i?.width,
          height: i?.height,
          length: i?.length,
          title: i?.title,
        };
      });
      const { data } = await axiosInstance.put(
        "crm/product/crud/" + props?.id + "/",
        {
          ...props.data,
          sizes: normalaizBody,
        }
      );
      if (data && props.images) {
        dispatch(SendPhoto({ images: props.images, id: data.id }));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const deleteImageProduct = createAsyncThunk(
  "product/deleteImageProduct",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "crm/product/image/delete/",
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const ProductSlice = createSlice({
  name: "product",
  initialState: {
    product: null,
    product_status: "",
    productdetail: null,
    product_detail_status: "",
    product_delete: "",
    product_delete_text: "",
    product_update: "",
    add_product_status: "",
    delete_status: "",
    edit_status: "",
    for_accountant_count: [],
    add_product: {
      category: "",
      category_title: "",
      collection: "",
      counts: [],
      description: "",
      guarantee: "",
      made_in: "",
      prices: [],
      title: "",
      vendor_code: "",
      sizes: [],
      link: "",
      package_count: "",
      weight: "",
    },
  },
  reducers: {
    clearData: (state) => {
      state.add_product = {}
      console.log("is work");
    },
    AddCountStock: (state, actions) => {
      state.for_accountant_count = actions.payload;
    },
    ChangeStatus: (state) => {
      state.add_product_status = "";
    },
    SetValueProductTitle: (state, actions) => {
      state.add_product.category = actions.payload.category;
      state.add_product.title = actions.payload.title;
      state.add_product.category_title = actions.payload.category_title;
      state.add_product.description = actions.payload.description;
      state.add_product.prices = actions.payload.prices;
      state.add_product.counts = actions.payload.counts;
      state.add_product.link = actions.payload?.link;
    },
    SetValueProductInfo: (state, actions) => {
      state.add_product.sizes = actions.payload;
    },
    SetValueProductHaracter: (state, actions) => {
      state.add_product.collection = actions.payload.collection;
      state.add_product.vendor_code = actions.payload.vendor_code;
      state.add_product.made_in = actions.payload.made_in;
      state.add_product.guarantee = actions.payload.guarantee;
      state.add_product.package_count = actions.payload.package_count;
      state.add_product.weight = actions.payload.weight;
    },
    ChangeDeleteStatus: (state) => {
      state.delete_status = "";
      state.add_product_status = "";
      state.edit_status = "";
    },
  },
  extraReducers: {
    [DeleteProduct.pending]: (state) => {
      state.delete_status = "pending";
    },
    [DeleteProduct.fulfilled]: (state) => {
      state.delete_status = "success";
    },
    [DeleteProduct.rejected]: (state) => {
      state.delete_status = "rejected";
    },
    [CreateNewProduct.pending]: (state) => {
      state.add_product_status = "pending";
    },
    [CreateNewProduct.fulfilled]: (state) => {
      state.add_product_status = "success";
    },
    [CreateNewProduct.rejected]: (state) => {
      state.add_product_status = "rejected";
    },
    [GetProducts.pending]: (state) => {
      state.product_status = "pending";
    },
    [GetProducts.fulfilled]: (state, actions) => {
      state.product_status = "success";
      state.product = actions.payload;
    },
    [GetProducts.rejected]: (state) => {
      state.product_status = "rejected";
    },
    [GetProductById.pending]: (state) => {
      state.product_detail_status = "pending";
    },
    [GetProductById.fulfilled]: (state, actions) => {
      state.product_detail_status = "success";
      state.productdetail = actions.payload;
    },
    [GetProductById.rejected]: (state) => {
      state.product_detail_status = "rejected";
    },
    [EditProduct.pending]: (state) => {
      state.edit_status = "pending";
    },
    [EditProduct.fulfilled]: (state) => {
      state.edit_status = "success";
    },
    [EditProduct.rejected]: (state) => {
      state.edit_status = "rejected";
    },
  },
});

export const ProductActions = ProductSlice.actions;
export default ProductSlice;
