import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const GetBannerList = createAsyncThunk(
  "banner/GetBannerList",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/banner/crud/${props}`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetBannerByIdOne = createAsyncThunk(
  "banner/GetBannerByIdOne",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        "crm/banner/crud/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const DeleteBanner = createAsyncThunk(
  "banner/DeleteBanner",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        "crm/banner/crud/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const UpdateBanner = createAsyncThunk(
  "banner/UpdateBanner",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      for (var i = 0; i < props.products.length; i++) {
        formdata.append("products", props.products[i]);
      }
      for (var i = 0; i < props.cities.length; i++) {
        formdata.append("cities", props.cities[i]);
      }
      formdata.append("title", props?.title);
      formdata.append("is_active", props?.is_active);
      formdata.append("description", props?.description);
      if (typeof props?.file !== "string") {
        formdata.append("file", props?.file);
      }
      const { data } = await ImageUploadingFetch.put(
        "crm/banner/crud/" + props.id + "/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const CreateNewBanner = createAsyncThunk(
  "banner/CreateNewBanner",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("title", props?.title);
      formdata.append("description", props?.description);
      formdata.append("file", props?.file);
      for (var i = 0; i < props.products.length; i++) {
        formdata.append("products", props.products[i]);
      }
      for (var i = 0; i < props.cities.length; i++) {
        formdata.append("cities", props.cities[i]);
      }
      formdata.append("is_active", props?.is_active);
      const { data } = await ImageUploadingFetch.post(
        "crm/banner/crud/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const BannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: [],
    banner_status: "",
    banner_detail: null,
    banner_detail_status: "",
    banner_delete: "",
    banner_delete_text: "",
    banner_update: "",
    add_banner: "",
  },
  reducers: {
    ChangeStatus: (state) => {
      state.banner_status = "";
      state.banner_delete = "";
    },
    ChangeUpdate: (state) => {
      state.banner_update = "";
      state.add_banner = "";
    },
    removeStates: (state) => {
      state.banner_detail = {};
    },
  },
  extraReducers: {
    [GetBannerList.pending]: (state) => {
      state.banner_status = "pending";
    },
    [GetBannerList.fulfilled]: (state, actions) => {
      state.banner = actions.payload;
      state.banner_status = "success";
    },
    [GetBannerList.rejected]: (state) => {
      state.banner_status = "rejected";
    },
    [GetBannerByIdOne.pending]: (state) => {
      state.banner_detail_status = "pending";
    },
    [GetBannerByIdOne.fulfilled]: (state, actions) => {
      state.banner_detail = actions.payload;
      state.banner_detail_status = "success";
    },
    [GetBannerByIdOne.rejected]: (state) => {
      state.banner_detail_status = "rejected";
    },
    [DeleteBanner.pending]: (state) => {
      state.banner_delete = "pending";
    },
    [DeleteBanner.fulfilled]: (state) => {
      state.banner_delete = "success";
    },
    [DeleteBanner.rejected]: (state, actions) => {
      state.banner_delete = "rejected";
      state.banner_delete_text = actions.payload;
    },
    [UpdateBanner.pending]: (state) => {
      state.banner_update = "pending";
    },
    [UpdateBanner.fulfilled]: (state, actions) => {
      state.banner_update = "success";
    },
    [UpdateBanner.rejected]: (state) => {
      state.banner_update = "rejected";
    },
    [CreateNewBanner.pending]: (state) => {
      state.add_banner = "pending";
    },
    [CreateNewBanner.fulfilled]: (state) => {
      state.add_banner = "success";
    },
    [CreateNewBanner.rejected]: (state) => {
      state.add_banner = "rejected";
    },
  },
});

export const BannerActions = BannerSlice.actions;
export default BannerSlice;
