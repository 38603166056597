import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const GetCatalogList = createAsyncThunk(
  "catalog/GetCatalogList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("crm/category/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const DeleteCategory = createAsyncThunk(
  "catalog/DeleteCategory",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete("crm/category/" + props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const CreateNewCategory = createAsyncThunk(
  "catalog/CreateNewCategory",
  async (props, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("image", props?.image[0]?.file);
      formData.append("title", props?.title);
      formData.append("slug", props?.slug);
      const { data } = await ImageUploadingFetch.post(
        "crm/category/",
        formData
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const UpdateCategory = createAsyncThunk(
  "catalog/UpdateCategory",
  async (props, { rejectWithValue }) => {
    try {
      if (props?.image[0]?.file) {
        const formData = new FormData();
        formData.append("image", props?.image[0]?.file);
        formData.append("title", props?.title);
        formData.append("slug", props?.slug);
        formData.append("is_active", props?.is_active);
        const { data } = await ImageUploadingFetch.put(
          "crm/category/" + props?.oldslug + "/",
          formData
        );
        return data;
      }
      const { data } = axiosInstance.put(
        "crm/category/" + props?.oldslug + "/",
        {
          slug: props?.slug,
          title: props?.title,
          is_active: props?.is_active,
        }
      ); 
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const CatalogSlice = createSlice({
  name: "catalog",
  initialState: {
    catalog: [],
    catalog_status: "",
    add_category: null,
    add_category_status: "",
    delete_status: "",
    edit_status: "",
  },
  reducers: {
    ChangeStatus: (state) => {
      state.add_category_status = "";
      state.delete_status = "";
      state.edit_status = "";
    },
  },
  extraReducers: {
    [GetCatalogList.pending]: (state) => {
      state.catalog_status = "pending";
    },
    [GetCatalogList.fulfilled]: (state, actions) => {
      state.catalog_status = "success";
      state.catalog = actions.payload;
    },
    [GetCatalogList.rejected]: (state) => {
      state.catalog_status = "rejected";
    },
    [DeleteCategory.pending]: (state) => {
      state.delete_status = "pending";
    },
    [DeleteCategory.fulfilled]: (state) => {
      state.delete_status = "success";
    },
    [DeleteCategory.rejected]: (state) => {
      state.delete_status = "rejected";
    },
    [CreateNewCategory.pending]: (state) => {
      state.add_category_status = "pending";
    },
    [CreateNewCategory.fulfilled]: (state, actions) => {
      state.add_category_status = "success";
      state.catalog = actions.payload;
    },
    [CreateNewCategory.rejected]: (state) => {
      state.add_category_status = "rejected";
    },
    [UpdateCategory.pending]: (state) => {
      state.edit_status = "pending";
    },
    [UpdateCategory.fulfilled]: (state, actions) => {
      state.edit_status = "success";
    },
    [UpdateCategory.rejected]: (state) => {
      state.edit_status = "rejected";
    },
  },
});

export const CatalogActions = CatalogSlice.actions;
export default CatalogSlice;
