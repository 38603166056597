import React from "react";
import MuiButton from "@mui/material/Button";
import styled from "@emotion/styled";
const Button = ({dis, disabled, variant, children, onClick, ...props }) => {
  const VARIANTS = {
    contained: {
      borderRadius: "4px",
      color: "#fff",
      width: "100%",
      backgroundColor: "#09234F",
      padding: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#061c43",
      },
    },
    userbtn: {
      borderRadius: "4px",
      color: "#fff",
      width: "100%",
      background: "#42AC40",
      boxShadow: "0px 10px 20px rgba(48, 48, 48, 0.25)",
      padding: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#0d6b04",
      },
    },
    userbtn_outlined: {
      borderRadius: "4px",
      color: "#42AC40",
      width: "100%",
      border: "1px solid #42AC40",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#0c5c04",
      },
    },
    outlined: {
      borderRadius: "4px",
      color: "#09234F",
      width: "100%",
      border: "1px solid #09234F",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#09234F",
      },
    },
    danger: {
      borderRadius: "4px",
      color: "#fff",
      backgroundColor: "#DD1919",
      width: "100%",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#b10d0d",
      },
    },
    dangeroutlined: {
      borderRadius: "4px",
      color: "#DD1919",
      border: "1px solid #DD1919",
      // backgroundColor: "#DD1919",
      width: "100%",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      padding: "12px",
      lineHeight: "24px",
      textTransform: "none",
      " &:hover": {
        color: "#fff",
        backgroundColor: "#DD1919",
      },
    },
  };
  return (
    <StyledButton
      disabled={disabled}
      {...props}
      styles={VARIANTS[variant]}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};
Button.defaultProps = {
  variant: "contained",
};

export default Button;
const StyledButton = styled(MuiButton)`
  ${({ styles }) => ({ ...styles })}
`;
