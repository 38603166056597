import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import PopUp from "../ui/PopUp";
import { ReactComponent as ClosePopUp } from "../../assets/icons/closepopup.svg";
import { NotificationIsRead } from "../../store/userStore/UserSlice";
import { formatDate } from "../../utils/helpers";

const PopUpNotification = ({ open, onClose, anchorEl }) => {
  const { notification_list } = useSelector((i) => i?.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateTo = (e, id, i) => {
    if (i?.status === "order") {
      let path = "";
      if (i?.order_status === "Отправлено" || i?.order_status === "Ожидание") {
        path = "Выдан";
      } else {
        path = i?.order_status;
      }
      navigate("/user/orders/" + path + "/" + i?.order_id);
      dispatch(NotificationIsRead(id));
      onClose("");
      return;
    }
    if (i?.status === "action") {
      navigate("/user/banner/" + i?.banner_id);
      dispatch(NotificationIsRead(id));
      onClose("");
      return;
    }
    navigate(e);
    dispatch(NotificationIsRead(id));
    onClose("");
  };
  let notification_sum = notification_list?.filter((i) => i?.is_read === false);
  return (
    <PopUp open={open} anchorEl={anchorEl} onClose={onClose}>
      <InnerPoUpNotification>
        <header>
          <h4>Уведомления</h4> <ClosePopUp onClick={onClose} />
        </header>
        {notification_sum?.length > 0 ? (
          notification_sum?.map((i, idx) => (
            <div
              className="body"
              key={idx + "popup"}
              onClick={() => {
                if (i?.status === "order") {
                  navigateTo("", i?.id, i);
                  return;
                }
                navigateTo("/user/profile", i?.id);
              }}
            >
              {i?.status === "order" || (
                <div>
                  <img loading="lazy" src={i?.image} alt="" />
                </div>
              )}
              <span>
                <h4>
                  {i?.status === "order" ? `Заказ №${i?.order_id}` : i?.title}
                </h4>
                <h4>
                  Дата:{" "}
                  {formatDate(i?.created_at?.slice(0, 10)) +
                    " / " +
                    i?.created_at?.split("T")[1]?.slice(0, 5)}
                </h4>
                <p>
                  {i?.status === "order"
                    ? "Статус заказа: " + i?.order_status
                    : i?.description}
                </p>
              </span>
            </div>
          ))
        ) : (
          <div className="body">
            <p>Пусто...</p>
          </div>
        )}
        <div />
      </InnerPoUpNotification>
    </PopUp>
  );
};
export default PopUpNotification;
const InnerPoUpNotification = styled("div")`
  width: 450px;
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 14px 11px 16px;
  }
  & > header > h4 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: #d9d9d9;
  }
  & .body {
    display: flex;
    gap: 10px;
    padding: 15px;
    min-width: 200px;
    cursor: pointer;
    img {
      object-fit: cover;
    }
  }
  & .body:hover {
    background: rgba(48, 48, 48, 0.1);
  }
  & .body > div:first-of-type > img {
    width: 75px;
    height: 75px;
    border-radius: 10px;
    margin-right: 10px;
  }
  & .body > span > p {
    font-size: 14px;
    font-weight: 400;
    color: #808080;
  }
  & .body > span {
    max-width: 100%;
    cursor: pointer;
  }
  & .body > span > h4 {
    font-size: 14px;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    & .body > span {
      max-width: 100%;
    }
  }
`;
