import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { fetchGetDidntOrderOneUser } from '../../store/managerStore/didntOrderUser'
import { formatDate, getcurrency_KZ } from '../../utils/helpers'


// const get_title_city = (e, text) => e?.find((i) => i?.slug === text);
function DidntOrderOneUser() {

    const dispatch = useDispatch()
    const { id } = useParams()
    const { last_order, last_order_status } = useSelector(s => s.didntorder)
    const [params, setParams] = useSearchParams()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(fetchGetDidntOrderOneUser({ user_id: id }))
    }, [])

    const info_skeleton = [
        { width: "350" },
        { width: "250" },
        { width: "280" },
        { width: "190" },
        { width: "170" },
        { width: "150" },
        { width: "250" },
        { width: "290" },
        { width: "190" },
    ]

    return (
        <div>
            {last_order_status === "pending" && <Box>
                {info_skeleton.map((i) => (
                    <Skeleton width={i.width + "px"} height={40} />
                ))
                }
            </Box>}
            {last_order_status !== "pending" && <Typography variant='h6' sx={{ display: "flex", alignItems: "center", gap: "15px", span: { color: "gray", cursor: "pointer" } }}>
                <span onClick={() => navigate(-1)}>Пользователи</span> / <p>{last_order?.name}</p> </Typography>}
            <>
                {last_order_status !== "pending" && <Box sx={{ ".wrap": { display: "flex", gap: "10px", margin: "10px 0" }, span: { color: "gray", fontSize: "18px" }, p: { fontSize: "18px" } }}>
                    <div className='wrap'>
                        <span>Имя:</span> <p>{last_order?.name} </p>
                    </div>
                    <div className='wrap'>
                        <span>Город</span> <p>{(last_order?.city_title)} </p>
                    </div>
                    <div className='wrap'>
                        <span>Склад</span> <p>{last_order?.city_stock_title} </p>
                    </div>
                    <div className='wrap'>
                        <span>Дата создание</span> <p>{formatDate(last_order?.created_at)} </p>
                    </div>
                    {last_order?.phone && <div className='wrap'>
                        <span>Тел. номер:</span> <p>{last_order?.phone} </p>
                    </div>}
                    <div className='wrap'>
                        <span>Статус:</span> <p>{last_order?.status} </p>
                    </div>
                    <div className='wrap'>
                        <span>Тип оплата:</span> <p>{last_order?.type_status} </p>
                    </div>
                    <div className='wrap'>
                        <span>Сумма:</span> <p>{last_order?.price} </p>
                    </div>
                </Box>}
            </>
            <Box marginTop='20px'>
                <h4>Продукты</h4>
                {last_order_status === "pending" && <Box>
                    {[...Array(3)].map((i) => (
                        <Skeleton variant='rectangular' width={"80%"} height={150} sx={{ margin: "15px 0" }} />
                    ))
                    }
                </Box>}
                {last_order_status !== "pending" && last_order?.order_products?.map((i) => (
                    <Box key={i.id} sx={{ boxShadow: "0 0 30px 1px #c7c7c76a", borderRadius: "10px", padding: "10px", margin: "15px 0", ".wrap": { display: "flex", gap: "10px", margin: "10px 0" }, span: { color: "gray", fontSize: "18px" }, p: { fontSize: "18px" } }}>
                        <div className='wrap'>
                            <span>Названия:</span> <p>{i.title} </p>
                        </div>
                        <div className='wrap'>
                            <span>Категория</span> <p>{i.category_title} </p>
                        </div>
                        <div className='wrap'>
                            <span>Количество:</span> <p>{i.count} шт. </p>
                        </div>
                        <div className='wrap'>
                            <span>Сумма:</span> <p>{i.price} {getcurrency_KZ(i.currency)}  </p>
                        </div>
                    </Box>
                ))}
            </Box>
        </div>
    )
}

export default DidntOrderOneUser