import { format } from "date-fns";

export const formatNum = (num) => (+num).toLocaleString();
export const formatDate = (date) => {
  let mutagenDate = "";
  if (date) {
    mutagenDate = format(new Date(date), "dd.MM.yyy");
  } else {
    mutagenDate = format(new Date(), "dd.MM.yyy");
  }
  return mutagenDate;
};
export const getcurrency_KZ = (e) => {
  let currency = "";
  if (e === "KZ") {
    currency = "₸";
  } else {
    currency = "₸";
  }
  return currency;
};
