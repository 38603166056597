import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const getDiscounts = createAsyncThunk(
  "discount/getDiscounts",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/discount/crud/${props}`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const addDiscount = createAsyncThunk(
  "discount/addDiscount",
  async (props, { rejectWithValue }) => {
    try {
      const { data_banner, data_dis } = props;
      const formdata = new FormData();
      if (data_banner.file) {
        formdata.append("file", data_banner.file);
      }
      if (data_banner.title) {
        formdata.append("title", data_banner.title);
      }
      formdata.append("description", data_banner.description);
      formdata.append("is_discount", true);
      formdata.append("is_active", false);
      for (var i = 0; i < data_dis.cities.length; i++) {
        formdata.append("cities", data_dis.cities[i]);
      }
      let banner_res;
      if (data_banner.title) {
        banner_res = await ImageUploadingFetch.post(
          `crm/banner/crud/`,
          formdata
        );
      }

      data_dis.banner = banner_res ? banner_res.data.id : data_dis.banner;

      const { data } = await axiosInstance.post(`crm/discount/crud/`, data_dis);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "discount/updateDiscount",
  async (props, { rejectWithValue }) => {
    const { data_dis, data_banner } = props;

    try {
      const formdata = new FormData();
      if (typeof data_banner.file !== "string") {
        formdata.append("file", data_banner.file);
      }

      formdata.append("title", data_banner.title);
      formdata.append("description", data_banner.description);
      formdata.append("is_discount", true);
      formdata.append("is_active", false);
      let banner_res;
      let dis_banner;

      if (data_dis.banner) {
        banner_res = await ImageUploadingFetch.put(
          `crm/banner/crud/${data_dis.banner}/`,
          formdata
        );
      } else if (data_dis.banner === null && data_banner.title) {
        dis_banner = await ImageUploadingFetch.post(
          `crm/banner/crud/`,
          formdata
        );
      }

      if (dis_banner && dis_banner.data && dis_banner.data.id) {
        data_dis.banner = dis_banner.data.id;
      }

      const { data } = await axiosInstance.put(
        `crm/discount/crud/${data_dis.id || data_dis.banner}/`,
        data_dis
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const deleteDiscount = createAsyncThunk(
  "discount/deleteDiscount",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `crm/discount/crud/${props}/`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getDiscountById = createAsyncThunk(
  "discount/getDiscountById",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/discount/crud/${props}/`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getProductsList = createAsyncThunk(
  "discount/getProductsList",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/discount/product/list/${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getProductsBannerList = createAsyncThunk(
  "discount/getProductsBannerList",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/banner/product/list/${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const SendNotificationPost = createAsyncThunk(
  "discount/SendNotificationPost",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("title", props.title);
      formdata.append("description", props.description);
      formdata.append("image", props.image);

      for (let i = 0; i < props.cities.length; i++) {
        formdata.append("cities", props?.cities[i]);
      }
      for (let i = 0; i < props.dealer_statuses.length; i++) {
        formdata.append("statuses", props.dealer_statuses[i]);
      }
      const { data } = await ImageUploadingFetch.post(
        `crm/notification/create/`,
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getStaticDiscount = createAsyncThunk(
  "discount/getStaticDiscount",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/discount/stats/`, {
        discount_id: props,
      });
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
const discountSlice = createSlice({
  name: "discount",
  initialState: {
    discounts: [],
    st_discounts: "",
    products_list: [],
    discount_byid: {},
    delete_discount: "",
    add_discountst: "",
    update_discountst: "",
    send_notif: "",
    static_discount: [],
  },
  reducers: {
    removeEditById: (state) => {
      state.discount_byid = {};
      state.products_list = [];
    },
    removeDiscount: (state) => {
      state.add_discountst = "";
      state.delete_discount = "";
      state.update_discountst = "";
    },
    removeSt: (state) => {
      state.send_notif = "";
    },
    removeDataStatic: (state) => {
      state.static_discount = [];
    },
  },
  extraReducers: {
    [getStaticDiscount.fulfilled]: (state, { payload }) => {
      state.static_discount = payload;
    },
    [SendNotificationPost.pending]: (state) => {
      state.send_notif = "pending";
    },
    [SendNotificationPost.fulfilled]: (state) => {
      state.send_notif = "success";
    },
    [SendNotificationPost.rejected]: (state) => {
      state.send_notif = "rejected";
    },
    [getDiscounts.pending]: (state) => {
      state.st_discounts = "pending";
    },
    [getDiscounts.fulfilled]: (state, { payload }) => {
      state.discounts = payload;
      state.st_discounts = "success";
    },
    [getDiscounts.rejected]: (state, { payload }) => {
      state.discounts = payload;
    },
    [getDiscountById.fulfilled]: (state, { payload }) => {
      state.discount_byid = payload;
    },
    [deleteDiscount.fulfilled]: (state, { payload }) => {
      state.delete_discount = "success";
    },
    [addDiscount.fulfilled]: (state) => {
      state.add_discountst = "success";
    },
    [updateDiscount.fulfilled]: (state) => {
      state.update_discountst = "success";
    },
    //-----------getProductsList--------------
    [getProductsList.fulfilled]: (state, { payload }) => {
      state.products_list = payload;
    },
    [getProductsBannerList.fulfilled]: (state, { payload }) => {
      state.products_list = payload;
    },
  },
});

export const discountActions = discountSlice.actions;
export default discountSlice;
