import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { ReactComponent as Bell } from "../../assets/icons/userNotification.svg";
import { ReactComponent as UserProfileIcon } from "../../assets/icons/userProfileIcon.svg";
import { ReactComponent as Usercart } from "../../assets/icons/usercart.svg";
import { ReactComponent as UserKatalog } from "../../assets/icons/usercatalogs.svg";
import { ReactComponent as UserChat } from "../../assets/icons/messages.svg";
import { ReactComponent as Usermain } from "../../assets/icons/usermain.svg";
import { ReactComponent as BurgerMenuIcon } from "../../assets/icons/menu.svg";
import PopUpCategory from "./PopUpCategory";
import PopUpUserProfile from "./userprofile/PopUpUserProfile";
import PopUpNotification from "./PopUpNotification";
import {
  CategoryTitle,
  GetNotificationList,
  MeInfoUser,
} from "../../store/userStore/UserSlice";
import mainLogo from "../../assets/icons/mainasiabrandlogo.png";

const UserHeader = () => {
  const { box } = useSelector((store) => store?.order);
  const { notification_status, notification_list } = useSelector(
    (i) => i?.userInfo
  );
  let notification_sum = notification_list?.filter((i) => !i?.is_read);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [popupCategory, setPopupCategory] = useState(false);
  const [popupProfile, setPopupProfile] = useState(false);
  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [anchorElCategory2, setAnchorElCategory2] = useState(null);
  const [isOpenPopUpNotification, setIsOpenPoUpNotification] = useState(false);
  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [openmobile, setopenmobile] = useState(false);

  const openOrCloseMobile = () => setopenmobile(!openmobile);
  const CloseMobile = () => setopenmobile(false);

  const navigateKorzina = () => {
    navigate("korzina");
    CloseMobile();
  };
  const openCatalogPopUp = (e) => {
    setAnchorElCategory(e.currentTarget);
    setPopupCategory(!popupCategory);
  };

  let loop = true;
  useEffect(() => {
    if (loop) {
      dispatch(CategoryTitle());
      dispatch(MeInfoUser());
    }
    loop = false;
  }, [loop]);

  useEffect(() => {
    dispatch(GetNotificationList());
  }, [pathname, notification_status]);

  const openProfilePopUp = (e) => {
    setAnchorElCategory2(e.currentTarget);
    setPopupProfile(!popupProfile);
  };

  const active = pathname.split("/")[2];
  const links = [
    {
      to: "/user",
      text: (
        <span className={active === undefined ? "active" : "passive"}>
          Главная
        </span>
      ),
      id: "1",
      iconL: (
        <Usermain className={active === undefined ? "active" : "passive"} />
      ),
      onClick: CloseMobile,
    },
    {
      to: `${pathname}${search}`,
      text: (
        <span className={active === "catalog" ? "active" : "passive"}>
          Каталог
        </span>
      ),
      id: "2",
      iconL: (
        <UserKatalog className={active === "catalog" ? "active" : "passive"} />
      ),
      onClick: openCatalogPopUp,
    },
    {
      to: "korzina",
      text: (
        <span className={active === "korzina" ? "active" : "passive"}>
          Корзина
          {box?.length !== 0 && (
            <NotificationBox>
              <span>
                <p>{box?.length}</p>
              </span>
            </NotificationBox>
          )}
        </span>
      ),
      id: "3",
      iconL: (
        <Usercart className={active === "korzina" ? "active" : "passive"} />
      ),
      onClick: navigateKorzina,
    },
    {
      to: null,
      text: (
        <span className={active === "profile" ? "active" : "passive"}>
          Профиль
        </span>
      ),
      id: "5",
      iconL: (
        <UserProfileIcon
          className={active === "profile" ? "active" : "passive"}
        />
      ),
      onClick: openProfilePopUp,
    },
  ];

  const openPopUpNotificationHandler = (e) => {
    setIsOpenPoUpNotification(!isOpenPopUpNotification);
    setAnchorElNotification(e?.currentTarget);
  };
  const { new_messages } = useSelector((i) => i.user_chat);
  const Navigations = () => (
    <WrapperNavigations>
      {links?.map((i, idx) => (
        <NavLink onClick={i?.onClick} to={i?.to} key={idx}>
          {i?.iconL}
          {i?.text}
        </NavLink>
      ))}

      {isOpenPopUpNotification && (
        <PopUpNotification
          open={isOpenPopUpNotification}
          onClose={openPopUpNotificationHandler}
          anchorEl={anchorElNotification}
        />
      )}
      <WrapperMessage>
        <Notification onClick={() => navigate("/user/message")}>
          <UserChat />
          {new_messages?.new_messages_count ? (
            <span>
              <p>{new_messages?.new_messages_count}</p>
            </span>
          ) : (
            ""
          )}
        </Notification>
        <Notification
          onClick={openPopUpNotificationHandler}
          className="onlydesktop"
        >
          <Bell />
          {notification_sum?.length > 0 ? (
            <span>
              <p>{notification_sum?.length}</p>
            </span>
          ) : (
            ""
          )}
        </Notification>
      </WrapperMessage>
    </WrapperNavigations>
  );

  return (
    <WrapHeader>
      <div>
        <Header>
          <WrapperLogoHome onClick={() => navigate("/user")}>
            <img src={mainLogo} alt="" />
          </WrapperLogoHome>
          {Navigations()}
          {popupCategory && (
            <PopUpCategory
              open={popupCategory}
              anchorEl={anchorElCategory}
              onClose={openCatalogPopUp}
            />
          )}
          {popupProfile && (
            <PopUpUserProfile
              open={popupProfile}
              anchorEl={anchorElCategory2}
              onClose={openProfilePopUp}
            />
          )}
        </Header>
        {/* --------------------------------------------------- */}

        <WrapBurgerMenu>
          <WrapperLogoHome onClick={() => navigate("/user")}>
            <img src={mainLogo} alt="" />
          </WrapperLogoHome>
          <div>
            <Notification onClick={() => navigate("/user/message")}>
              <UserChat />
              {new_messages?.new_messages_count ? (
                <span>
                  <p>{new_messages?.new_messages_count}</p>
                </span>
              ) : (
                ""
              )}
            </Notification>
            <Notification onClick={openPopUpNotificationHandler}>
              <Bell />
              {notification_sum?.length > 0 ? (
                <span>
                  <p>{notification_sum?.length}</p>
                </span>
              ) : (
                ""
              )}
            </Notification>
            <Button onClick={openOrCloseMobile}>
              <BurgerMenuIcon />
            </Button>
            <WrapperMobile open={openmobile}>
              {openmobile && (
                <div className="back" onClick={openOrCloseMobile} />
              )}
              <div className="mobile">
                {Navigations()}
                {popupCategory && (
                  <PopUpCategory
                    open={popupCategory}
                    anchorEl={anchorElCategory}
                    onClose={openCatalogPopUp}
                    mobileClose={CloseMobile}
                  />
                )}
                {popupProfile && (
                  <PopUpUserProfile
                    open={popupProfile}
                    anchorEl={anchorElCategory2}
                    onClose={openProfilePopUp}
                    mobileClose={CloseMobile}
                  />
                )}
              </div>
            </WrapperMobile>
          </div>
        </WrapBurgerMenu>
      </div>
    </WrapHeader>
  );
};

export default UserHeader;
const WrapperLogoHome = styled("div")`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  margin: 5px;
  > img {
    width: 150px;
  }
  @media screen and (max-width: 700px) {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    margin: 5px;
    > img {
      width: 105px;
    }
  }
`;
const WrapperMessage = styled("div")`
  display: flex;
  align-items: center;
  gap: 15px;
`;
const WrapperMobile = styled("div")`
  position: fixed;
  right: 0px;
  top: 0px;
  width: ${(props) => (props?.open ? "100%" : "0px")};
  height: ${(props) => (props?.open ? "100%" : "0px")};
  z-index: 5000;
  .back {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #807f7f69;
    z-index: 5500;
  }
  .mobile {
    position: fixed;
    z-index: 5550;
    right: 0px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    transition-duration: 1s;
    padding: ${(props) => (props?.open ? "10px" : "0px")};
    width: ${(props) => (props?.open ? "30%" : "30%")};
    transform: ${(props) =>
      props?.open ? "translateX(0px)" : "translateX(130%)"};
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
const WrapBurgerMenu = styled("div")`
  display: none;
  @media screen and (max-width: 950px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    & > svg {
      width: 150px;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  & > div > button {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
`;

const WrapHeader = styled("div")`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 15;
  margin: 0 auto;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  & > div {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
  }
`;

const WrapperNavigations = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  .active {
    color: #42ac40;
    path {
      fill: #42ac40;
    }
  }
  .passive {
    color: #303030;
    path {
      fill: #30303099;
    }
  }
  & > a {
    color: #30303099;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  & a > svg {
    margin: 2px 7px 0 0;
  }
  & a > svg > path {
    fill: #30303099;
  }
  & > a:hover {
    & > span {
      color: #42ac40;
    }
    & > svg > path {
      fill: #42ac40;
    }
  }

  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    align-items: flex-start;
    & .onlydesktop {
      display: none;
    }
  }
`;
const NotificationBox = styled("div")`
  position: relative;
  cursor: pointer;
  & > span {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: 80px;
    top: -25px;
    background: #f84135;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 10px;
      color: white;
    }
  }
`;
const Notification = styled("div")`
  margin-left: 0px;
  position: relative;
  cursor: pointer;
  & > span {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 14px;
    top: -2px;
    background: #f84135;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-size: 7px;
      color: white;
    }
  }
  & > svg {
    path {
      fill: #30303099;
    }
  }
  & > svg:hover {
    path {
      fill: #42ac40;
    }
  }

  @media screen and (max-width: 500px) {
    margin-right: 25px;
  }
`;
const Header = styled("div")`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    margin-bottom: 6px;
    margin-top: 2px;
  }
  @media screen and (max-width: 950px) {
    display: none;
  }
`;
