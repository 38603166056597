import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import styled from "@emotion/styled";
import Button from "../../components/ui/Button";
import { formatDate, formatNum, getcurrency_KZ } from "../../utils/helpers";
import { GetCurrency } from "../../hooks/GetCurrency";
import { LightBoxOneImage } from "../../components/ui/LightBoxImage";
import defImage from "../../assets/images/notimage.jpg";
import { OrderModerationActions } from "../../store/adminStore/OrderModeration";
import PopUp from "../../components/ui/PopUp";
import Spinner from "../../components/ui/Spinner";
import ReturnOrder from "../../components/managerComponents/ReturnOrder";
import { fetchDeleteOrder, fetchGetManagerOrdersById } from "../../store/managerStore/managerOrders";
import { Box, Typography } from "@mui/material";
import BasicModal from "../../components/ui/BasicModal";

function InnerPageManagerOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  //   const { order_status, order, order_byid, order_mod_status } = useSelector(
  //     (state) => state.orders
  //   );
  const { order_byid, orderbyid_tatus, deleteorder_tatus } = useSelector(
    (state) => state.managerorder
  );

  const { status, return_status } = useSelector((e) => e?.order_mod);
  const { role } = useSelector((s) => s.loginAuth);
  const [isAnchorEl, setIsAnchorEl] = useState(null);
  const [isOpenPoUp, setOpenPopUp] = useState(false);
  const [discount, setDiscount] = useState("");
  const [valueComment, setValueComment] = useState("");
  const [deleteOrder, setDeleteOrder] = useState(false)

  useEffect(() => {
    dispatch(fetchGetManagerOrdersById(id));
  }, []);

  useEffect(() => {
    if (return_status === "fulfilled") {
      NotificationManager.success("Возврат успешно сделан!");
      dispatch(OrderModerationActions.changeStatus());
      // dispatch(OrderModerationActions.changeStatus());
      navigate(-1);
    }
    if (return_status === "rejected") {
      NotificationManager.error("Произошла ошибка, повтрите снова!");
      dispatch(OrderModerationActions.changeStatus());
    }
    if (deleteorder_tatus === "success") {
      NotificationManager.success("Заказ успешно удален!");
      navigate(-1);
      dispatch(OrderModerationActions.changeStatus());
    }
  }, [return_status, deleteorder_tatus]);

  const status_order = order_byid?.status ? order_byid.status : "";
  const classNameStatus =
    status_order === "Успешно"
      ? "#06960d"
      : status_order === "Ожидание"
        ? "#f2994a"
        : status_order === "Отказано"
          ? "#f24a4a"
          : status_order === "Новый"
            ? "blue"
            : "";

  const openPopUp = (e) => {
    setIsAnchorEl(e.currentTarget);
    setOpenPopUp(!isOpenPoUp);
  };

  const getValueComment = (e) => {
    setValueComment(e.target.value);
  };

  const discountHandler = (e) => {
    setDiscount(e.target.value);
  };
  const isDsabledBtn = () => {
    if (role === "accountant" && order_byid.type_status === "Наличка") {
      return true;
    } else {
      return false;
    }
  };
  const [image, setimage] = useState("");
  const openBig = (e) => setimage(e);
  const closeBig = () => setimage("");
  const [imageHave, setimageHave] = useState(true);
  const onLoad = () => setimageHave(true);
  const notLoaded = () => setimageHave(false);

  // return order
  const [openreturn, setopenreturn] = useState(false);
  const opencloseReturnOrder = () => setopenreturn(!openreturn);

  //   const isLoading = order_mod_status === "pending" ? true : false;
  function deleteOrderisOpenHandler() {
    setDeleteOrder(!deleteOrder)
  }
  function deleteOrderHandle() {
    dispatch(fetchDeleteOrder({ order_id: order_byid.id }))
  }

  return (
    <>
      <BasicModal open={deleteOrder} onClose={deleteOrderisOpenHandler}>
        <Box sx={{ padding: '20px' }}>
          <Typography marginBottom="20px">Вы хотите удалить заказа?</Typography>
          <Box sx={{ display: 'flex', gap: "15px" }}>
            <Button>Нет</Button>
            <Button onClick={deleteOrderHandle} variant="danger">Да</Button>
          </Box>
        </Box>
      </BasicModal>
      {orderbyid_tatus === "pending" ? (
        <WrapperLoading>
          <Spinner />
        </WrapperLoading>
      ) : (
        ""
      )}
      {openreturn && (
        <ReturnOrder
          close={opencloseReturnOrder}
          open={openreturn}
          data={order_byid}
        />
      )}
      {image && <LightBoxOneImage image={image} openmodal={closeBig} />}
      <Container>
        <BlockDiv>
          <Div>
            <ContainerBlock>
              {imageHave && order_byid?.author_image ? (
                <img
                  src={
                    order_byid?.author_image
                      ? order_byid?.author_image
                      : defImage
                  }
                  alt="secret foto"
                  onClick={() =>
                    order_byid?.author_image
                      ? openBig(order_byid?.author_image)
                      : ""
                  }
                  onLoad={onLoad}
                  onError={notLoaded}
                />
              ) : (
                <img src={defImage} alt="secret foto" />
              )}
              <p className="fio">{order_byid?.name}</p>
              <div className="userinfo">
                <a href={"mailto:" + order_byid?.gmail} className="gmail">
                  {order_byid?.gmail}
                </a>
                <a href={"tel:" + order_byid?.phone} className="gmail">
                  {order_byid?.phone}
                </a>
              </div>
            </ContainerBlock>
            <PaymentMethod>
              Оплата:{" "}
              {order_byid?.type_status === "Карта"
                ? "через картой"
                : order_byid?.type_status === "Баллы"
                  ? "через Баллы"
                  : order_byid?.type_status === "Наличка"
                    ? "Наличный"
                    : order_byid?.type_status === "Каспи"
                      ? "Каспи"
                      : ""}
            </PaymentMethod>
            <BlockImg>
              {(order_byid?.type_status === "Карта" ||
                order_byid?.type_status === "Каспи") && (
                  <img
                    loading="lazy"
                    onClick={() =>
                      order_byid?.image ? openBig(order_byid?.image) : ""
                    }
                    src={order_byid?.image || defImage}
                    alt="чек"
                  />
                )}
            </BlockImg>
            {isOpenPoUp && (
              <PopUp
                anchorEl={isAnchorEl}
                open={isOpenPoUp}
                onClose={openPopUp}
              >
                <InnerPopUp>
                  <h4>Обарщение к клиентам</h4>
                  <div className="styled_textarea">
                    <textarea value={discount} onChange={discountHandler} />
                  </div>
                  <div className="wrap_buttons">
                    <Button onClick={openPopUp} variant={"outlined"}>
                      Отмена
                    </Button>
                    <Button onClick={openPopUp}>Отправить</Button>
                  </div>
                </InnerPopUp>
              </PopUp>
            )}
          </Div>
          <BlockCont>
            <div className="all_order">
              {(role === "accountant" || role === "warehouse") &&
                order_byid?.type_status === "Наличка" ? (
                <div className="sum_order">
                  <span className="sum">Итого к оплате:</span>
                  {formatNum(order_byid?.price)}{" "}
                  {getcurrency_KZ(order_byid?.currency)}
                </div>
              ) : (
                ""
              )}
              <div>
                <span>id заказ:</span>
                {order_byid?.id}
              </div>
              <div>
                <span>Дата:</span>
                {formatDate(order_byid?.created_at?.slice(0, 10)) +
                  " / " +
                  order_byid?.created_at?.split("T")[1]?.slice(0, 5)}
              </div>
              <div style={{ color: classNameStatus }}>
                <span>Статус:</span>
                {order_byid?.status}
              </div>
            </div>
            {order_byid?.order_products?.map((i, idx) => (
              <BlockPage key={idx + "zakaz"}>
                <div className="div">
                  <img
                    src={i?.image}
                    onClick={() => openBig(i?.image)}
                    alt={i?.title}
                  />
                  <div>
                    <p>Названия: {i?.title}</p>
                    <p>
                      Цена: {formatNum(i?.price / i?.count)}{" "}
                      {GetCurrency(i?.currency)}
                    </p>
                  </div>
                </div>
                <span>
                  <span className="p">
                    <span className="label">Сумма: </span>
                    {formatNum(i?.price)} {GetCurrency(i?.currency)}
                  </span>
                  <span className="p">
                    <span className="label">Штук: </span>
                    {i?.count} шт.
                  </span>
                </span>
              </BlockPage>
            ))}
            <Box sx={{ button: { height: "40px" } }}>
              {((order_byid.type_status === "Баллы" && order_byid.status === "Оплачено") || order_byid.status === "Новый") && <Button onClick={deleteOrderisOpenHandler} variant={"danger"}>Удалить</Button>}
            </Box>
            {/* <p>{order_byid?.comment}</p> */}
            {/* <CommentBlock>
              {role === "warehouse" ? (
                order_byid?.status === "Отложенный" ||
                order_byid?.status === "Новый" ||
                (order_byid?.status === "Оплачено" &&
                  (order_byid?.type_status === "Карта" ||
                    order_byid?.type_status === "Баллы")) ? (
                  <textarea
                    onChange={getValueComment}
                    value={valueComment}
                    placeholder="Коментарий..."
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {role === "accountant" && order_byid?.status === "Новый" ? (
                <textarea
                  onChange={getValueComment}
                  value={valueComment}
                  placeholder="Коментарий..."
                />
              ) : (
                ""
              )}
            </CommentBlock> */}
          </BlockCont>
        </BlockDiv>
      </Container>
    </>
  );
}
export default InnerPageManagerOrder;
const WrapperLoading = styled("div")`
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background: #00000026;
  top: 0px;
  left: 0px;
`;
const ButtonReturn = styled(Button)`
  background: #920b0b97;
  box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
  border-radius: 8px;
  width: 213px;
  height: 40px;
  margin-left: 30px;
  color: #0f0e11;
  :hover {
    background: #8000ff33;
    color: #8000ff;
  }
  @media screen and (max-width: 800px) {
    margin: 20px 0 0 0px;
  }
`;
const PaymentMethod = styled("div")`
  font-size: 16px;
  color: #09234f;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 22px 22px 22px;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 20px;
  @media screen and (max-width: 700px) {
    margin-bottom: 20px;
  }
`;
const InnerPopUp = styled("div")`
  width: 400px;
  padding: 20px 30px 20px 30px;
  & > h4 {
    color: #09234f;
    font-weight: 600;
    font-size: 16px;
  }
  & .styled_textarea {
    width: 100%;
    margin: 25px 0;

    & > textarea {
      width: 100%;
      height: 70px;
    }
  }
  & .wrap_buttons {
    display: flex;
    height: 36px;
    gap: 15px;
  }
`;

const CommentBlock = styled("div")`
  width: 100%;
  display: flex;
  & > textarea {
    width: 100%;
    height: 120px;
    padding: 7px;
    border-radius: 8px;
  }
  & > button {
    width: 30%;
    height: 45px;
    margin-left: 30px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 95%;
  margin-top: 10px;
  padding-bottom: 100px;
  position: relative;
`;

const ContainerBlock = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 300px;
  text-align: center;
  padding: 20px 40px 40px 40px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 20px;
    cursor: pointer;
  }

  .fio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
    color: #303030;
  }
  .gmail {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    text-align: center;
    text-decoration: none;
    letter-spacing: 1px;
    color: rgba(29, 29, 29, 0.8);
  }
  .userinfo {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 750px) {
    max-width: 500px;
  }
`;

const BlockImg = styled.div`
  margin-top: 30px;
  width: 170px;
  height: 170px;
  & > img {
    cursor: pointer;
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
    & > img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
`;

const BlockPage = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
  & .label {
    color: #242424;
  }
  img {
    width: 105px;
    height: 105px;
    object-fit: cover;
    cursor: pointer;
  }
  .p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #606060;
    width: 67%;
  }
  .div {
    display: flex;
  }
  .div > img {
    margin-right: 25px;
  }
  p {
    margin-top: 10px;
  }
  .kol {
    margin-left: 40px;
    margin-top: 30px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: rgb(69, 69, 78);
    }
  }
  & > span {
    display: flex;
    margin-top: 10px;
    gap: 15px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .div > img {
      margin: 0px;
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 5px;
    }
    & > span {
      display: flex;
    }
  }
`;

const BlockDiv = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  gap: 5%;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const BlockCont = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  width: 76%;
  & .all_order {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 6px;
  }
  & .all_order > div > span {
    font-size: 18px;
    color: gray;
    font-weight: 500;
    margin-right: 10px;
  }
  & .sum_order {
    font-size: 24px;
    padding: 8px 12px;
    box-shadow: 0 0 12px #4f4f4f51;
    .sum {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    .all_order {
      flex-direction: column;
      align-items: flex-start;
    }
    .sum_order {
      width: 100%;
      padding: 10px;
      font-size: 18px;
      margin-bottom: 20px;
      .sum {
        font-size: 18px;
      }
    }
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  & .contacting {
    padding: 5px;
  }
  @media screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const BlockButton = styled.div`
  display: flex;
  justify-content: space-around;
  /* width: 95%; */
  margin: 0 auto;
  margin-top: 40px;
  @media screen and (max-width: 800px) {
    display: block;
  }
  @media screen and (max-width: 510px) {
    /* margin-left: 100px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > button {
      width: 48%;
      margin: 0px;
    }
  }
`;

const ButtonStyled = styled(Button)`
  box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
  border-radius: 8px;
  color: #f24a4a;
  width: 213px;
  height: 40px;
  background: #ffc3c3;
  :hover {
    background: #ffc3c3;
    color: #f24a4a;
  }
  @media screen and (max-width: 510px) {
    /* margin-left: 100px; */
  }
`;

const ButtonWaiting = styled(Button)`
  background: #fff3db;
  box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
  color: #f2994a;
  border-radius: 8px;
  width: 213px;
  height: 40px;
  margin-left: 30px;
  :hover {
    background: #fff3db;
    color: #f2994a;
  }
  @media screen and (max-width: 510px) {
    margin-right: 30px;
    margin-top: 20px;
  }
`;

const ButtonPrin = styled(Button)`
  background: #98ffa9;
  box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
  border-radius: 8px;
  width: 213px;
  height: 40px;
  margin-left: 30px;
  color: #06960d;
  :hover {
    background: #98ffa9;
    color: #06960d;
  }
  @media screen and (max-width: 800px) {
    margin: 20px 0 0 0px;
  }
`;

const ButtonGone = styled(Button)`
  background: #8000ff33;
  box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
  border-radius: 8px;
  width: 213px;
  height: 40px;
  margin-left: 30px;
  color: #8000ff;
  :hover {
    background: #8000ff33;
    color: #8000ff;
  }
  @media screen and (max-width: 800px) {
    margin: 20px 0 0 0px;
  }
`;
