import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

const initialState = {
  createmanager_status: "",
  editmanager_status: "",
  deletemanager_status: "",
  citiesList: [],
  managersList: [],
  managers_status: "",
  manager: {},
};

export const getAllManagers = createAsyncThunk(
  "managers/getAllManagers",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/manager/?${props}`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const getOneManager = createAsyncThunk(
  "manager/getOneManager",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/manager/${props.id}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const createUser = createAsyncThunk(
  "crmuser/createUser",
  async ({ dataUser, dataCity, avatar }, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("name", dataUser.name);
      formdata.append("username", dataUser.login);
      formdata.append("email", dataUser.email);
      if (avatar.file) {
        formdata.append("image", avatar.file);
      }
      formdata.append("phone", dataUser.phone);
      formdata.append("password", dataUser.password);
      formdata.append("city", dataCity);
      formdata.append("status", "manager");
      const { data } = await ImageUploadingFetch.post(
        "crm/user/cu/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const editManager = createAsyncThunk(
  "crmuser/updateUser",
  async ({ id, dataUser, profileImage, dataCity }, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("email", dataUser.email);
      formdata.append("password", dataUser.password);
      formdata.append("username", dataUser.login);
      formdata.append("name", dataUser.name);
      formdata.append("pwd", dataUser.pwd);
      if (profileImage[0].file) {
        formdata.append("image", profileImage[0].file);
      }
      formdata.append("phone", dataUser.phone);
      formdata.append("city", dataCity);
      formdata.append("status", "dealer");
      const { data } = await ImageUploadingFetch.put(
        `crm/manager/${id}/`,
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getCitiesList = createAsyncThunk(
  "crmuser/getCitiesList",
  async () => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      return data;
    } catch (error) {
      return [];
    }
  }
);

const createManagerSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    ChangeStatus: (state) => {
      state.createuser_status = "";
      state.edituser_status = "";
      state.deleteuser_status = "";
    },
  },
  extraReducers: {
    ///--------------------------------------------
    // [editManager.pending]: (state) => {
    //   state.edituser_status = "pending";
    // },
    // [editManager.fulfilled]: (state) => {
    //   state.edituser_status = "success";
    // },
    // [editManager.rejected]: (state) => {
    //   state.edituser_status = "rejected";
    // },
    //-----------------------------------------------
    [getOneManager.fulfilled]: (state, { payload }) => {
      state.manager = payload;
    },
    //-----------------------------------------------
    // [getAllManagers.pending]: (state) => {
    //   state.getusers_status = "pending";
    // },
    [getAllManagers.pending]: (state) => {
      state.managers_status = "pending";
    },
    [getAllManagers.fulfilled]: (state, { payload }) => {
      state.managersList = payload;
      state.managers_status = "success";
    },
    [getAllManagers.rejected]: (state) => {
      state.managers_status = "rejected";
    },

    [createUser.pending]: (state) => {
      state.createuser_status = "pending";
    },
    [createUser.fulfilled]: (state) => {
      state.createuser_status = "success";
    },
    // [createManager.rejected]: (state) => {
    //   state.createuser_status = "rejected";
    // },
    //-=-=-=-------------------------------------------------
    [getCitiesList.fulfilled]: (state, { payload }) => {
      state.citiesList = payload;
    },
  },
});

export const createManagerActions = createManagerSlice.actions;
export default createManagerSlice;
