import axiosInstance from "./axios";

const verifytoken = async () => {
  try {
    const { data } = await axiosInstance.post("user/token/verify/", {
      token: localStorage.getItem("accessToken"),
    });
    return data;
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.setItem("token_not_valid", 1);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    window.location.reload();
  }
};
const changetoken = async () => {
  try {
    let num = 1;
    const { data } = await axiosInstance.post("user/token/refresh/", {
      refresh: localStorage.getItem("refreshToken"),
    });
    localStorage.setItem("accessToken", data?.access);
    localStorage.setItem("token", num + 1);

    if (num === 1) {
      num = num + 1;
      window.location.reload();
    }
    return data;
  } catch (error) {
    verifytoken();
  }
};
export { changetoken };
