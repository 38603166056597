import { useState } from "react";
import styled from "@emotion/styled";

const DescriptionNotification = ({ description }) => {
  const [isMore, setIsMore] = useState(false);
  return (
    <Description>
      {!isMore && (
        <>
          {description.length > 160 ? description.slice(0, 160) : description}
        </>
      )}
      {isMore && description}
      {description.length > 160 && (
        <>
          {!isMore ? (
            <span className="moreLess" onClick={() => setIsMore(!isMore)}>
              {"..."}еще
            </span>
          ) : (
            <span className="moreLess" onClick={() => setIsMore(!isMore)}>
               {"< "} скрыть
            </span>
          )}
        </>
      )}
    </Description>
  );
};
export default DescriptionNotification;

const Description = styled("div")`
  max-width: 370px;
  font-size: 12px;
  color: #8e8e92;
  margin-top: 6px;
  & .moreLess {
    color: #414142;
    cursor: pointer;
  }
`;
