import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";
import { getOrdersAccountantCount } from "../managerStore/orders";


export const getRequisitesAll = createAsyncThunk(
    "requisite/getRequisitesAll",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("crm/requisite/crud/");
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const getRequisites = createAsyncThunk(
    "requisite/getRequisites",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get("crm/requisite/category/crud/");
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const getRequisiteType = createAsyncThunk(
    "requisite/getRequisiteType",
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`crm/requisite/category/crud/${id}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);
export const deleteRequisite = createAsyncThunk(
    "requisite/deleteRequisite",
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`crm/requisite/crud/${id}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);

export const addRequisite = createAsyncThunk(
    "requisite/addRequisite",
    async (props, { rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append("category", props.category)
            formData.append("text", props.text)
            for (let i = 0; i < props.cities.length; i++) {
                formData.append("cities", props.cities[i].slug || props.cities[i].city)
            }
            const { data } = await ImageUploadingFetch.post(`crm/requisite/crud/`, formData);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);

export const editRequisite = createAsyncThunk(
    "requisite/editRequisite",
    async (props, { rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append("category", props.category)
            formData.append("text", props.text)
            for (let i = 0; i < props.cities.length; i++) {
                formData.append("cities", props.cities[i].slug || props.cities[i].city)
            }
            const { data } = await ImageUploadingFetch.put(`crm/requisite/crud/${props.id}/`, formData);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);


// --------------=-----------categoryPay------------------=------------------------
export const addCategoryPay = createAsyncThunk(
    "requisite/addCategoryPay",
    async (props, { rejectWithValue }) => {

        try {
            const formData = new FormData()
            formData.append("logo", props.logo)
            formData.append("title", props.title)

            const { data } = await ImageUploadingFetch.post(`crm/requisite/category/crud/`, formData);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);

export const deleteCategoryPay = createAsyncThunk(
    "requisite/deleteCategoryPay",
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.delete(`crm/requisite/category/crud/${id}/`);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);


export const editCategoryPay = createAsyncThunk(
    "requisite/editCategoryPay",
    async (props, { rejectWithValue }) => {
        try {
            const formData = new FormData()
            formData.append("title", props.title)
            if (typeof props.logo !== "string") {
                formData.append("logo", props.logo)
            }
            const { data } = await ImageUploadingFetch.put(`crm/requisite/category/crud/${props.id}/`, formData);
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);




const requisiteSlice = createSlice({
    name: "requisite",
    initialState: {
        addCategoryPay_status: "",
        editCategoryPay_status: "",
        deleteCategoryPay_status: "",


        // -------------------------
        requisites: [],
        requisitesAll: [],
        requisitetype: {},
        requisitesall_status: "",
        requisites_status: "",
        requisitetype_status: "",
        addRequisite_status: "",
        editRequisite_status: "",
        deleterequisites_status: ""

    },
    reducers: {
        clearStatus: (state) => {
            state.addCategoryPay_status = '';
            state.deleteCategoryPay_status = '';
            state.editCategoryPay_status = '';

            // --------requisites---------------------
            state.requisites_status = "";
            state.requisitetype_status = "";
            state.addRequisite_status = "";
            state.requisitesall_status = "";
            state.deleterequisites_status = ""
            state.editRequisite_status = ""
        },
    },
    extraReducers: {
        [editCategoryPay.fulfilled]: (state) => {
            state.editCategoryPay_status = "success";
        },
        [deleteCategoryPay.fulfilled]: (state) => {
            state.deleteCategoryPay_status = "success";
        },
        [addCategoryPay.fulfilled]: (state) => {
            state.addCategoryPay_status = "success";
        },


        // =======================requisites================================
        [editRequisite.fulfilled]: (state) => {
            state.editRequisite_status = "success";
        },
        // ----------------------------------------------------
        [deleteRequisite.fulfilled]: (state) => {
            state.deleterequisites_status = "success";
        },
        // ----------------------------------------------------
        [getRequisitesAll.pending]: (state) => {
            state.requisitesall_status = "pending";
        },
        [getRequisitesAll.fulfilled]: (state, { payload }) => {
            state.requisitesAll = payload;
            state.requisitesall_status = "success";
        },
        [getRequisitesAll.rejected]: (state) => {
            state.requisitesall_status = "reject";
        },
        // ------------------------------------------------------
        [addRequisite.pending]: (state) => {
            state.addRequisite_status = "pending";
        },
        [addRequisite.fulfilled]: (state, { payload }) => {
            state.addRequisite_status = "success";
        },
        [addRequisite.rejected]: (state) => {
            state.addRequisite_status = "reject";
        },
        // ------------------------------------------------------
        [getRequisiteType.pending]: (state) => {
            state.requisitetype_status = "pending";
        },
        [getRequisiteType.fulfilled]: (state, { payload }) => {
            state.requisitetype = payload
            state.requisitetype_status = "success";
        },
        [getRequisiteType.rejected]: (state) => {
            state.requisitetype_status = "reject";
        },
        // ------------------------------------------------------
        [getRequisites.pending]: (state) => {
            state.requisites_status = "pending";
        },
        [getRequisites.fulfilled]: (state, { payload }) => {
            state.requisites = payload
            state.requisites_status = "success";
        },
        [getRequisites.rejected]: (state) => {
            state.requisites_status = "reject";
        },
        // ------------------------------------------------------

    },
});

export const requisiteActions = requisiteSlice.actions;
export default requisiteSlice;
