import { useDispatch, useSelector } from "react-redux";
import { CreateOrderActions } from "../../store/managerStore/CreateOrder";

const OrderCreateManager = () => {
  const dispatch = useDispatch();
  const { add_product } = useSelector((i) => i?.createOrder);
  // add to buy
  // console.log(add_product, "log");
  const addProduct = (e) => {
    const haveproduct = add_product?.find(
      (i) => +i?.id === +e?.id && i?.addcity?.slug === e?.addcity?.slug
    );
    if (haveproduct?.id) {
      const mutagen = add_product?.map((i) => {
        if (+i?.id === +e?.id && i?.addcity?.slug === e?.addcity?.slug) {
          let count;
          if (+i?.maxcount >= +i?.count + e?.count) {
            count = +i?.count + e?.count;
          } else {
            count = e?.count;
          }
          return { ...i, count: count };
        }
        return i;
      });
      dispatch(CreateOrderActions.AddToAddBuy(mutagen));
    } else {
      dispatch(CreateOrderActions.AddToBuy([...add_product, e]));
    }
  };
  // clear from buy
  const clearProduct = (e) => {
    const clear = add_product
      ?.map((i) => {
        if (+i?.id === +e?.id && i?.addcity?.slug === e?.addcity?.slug) {
          return null;
        }
        return i;
      })
      ?.filter((i) => i !== null);
    dispatch(CreateOrderActions.AddToAddBuy(clear));
  };
  // minus buy box
  const minus = (e, c) => {
    const mutagen = add_product?.map((i) => {
      if (+i?.id === +e?.id && i?.addcity?.slug === e?.addcity?.slug) {
        let count;
        if (+i?.maxcount >= +i?.count - +c && +i?.count - +c > 0) {
          count = +i?.count - +c;
        } else {
          count = i?.count;
        }
        return { ...i, count: count };
      }
      return i;
    });
    dispatch(CreateOrderActions.AddToAddBuy(mutagen));
  };
  // plus buy box
  const plus = (e, c) => {
    const mutagen = add_product?.map((i) => {
      if (+i?.id === +e?.id && i?.addcity?.slug === e?.addcity?.slug) {
        let count;
        if (+i?.maxcount >= +i?.count + +c && +i?.count + +c > 0) {
          count = +i?.count + +c;
        } else {
          count = i?.count;
        }
        return { ...i, count: count };
      }
      return i;
    });
    dispatch(CreateOrderActions.AddToAddBuy(mutagen));
  };
 
  return { addProduct, clearProduct, minus, plus };
};
export default OrderCreateManager;
