import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import PopUp from "../ui/PopUp";
import Button from "../ui/Button";
import DescriptionNotification from "./DescriptionNotification";
import { deleteReview, readedReview } from "../../store/managerStore/profile";
import { ReactComponent as ClosePopUp } from "../../assets/icons/closepopup.svg";
import star from "../../assets/icons/starInPopUp.svg";

const BellNotification = ({ anchorEl, open, close }) => {
  const dispatch = useDispatch();
  const { reviews } = useSelector((state) => state.profile);

  const readedReviewHandler = (data) => {
    dispatch(readedReview(data));
  };
  const deleteReviewHandler = (data) => {
    dispatch(deleteReview(data));
  };
  return (
    <PopUp open={open} anchorEl={anchorEl} onClose={close}>
      <WrapPopUp>
        <Header>
          <h2>Уведомление</h2>
          <ClosePopUp onClick={close} />
        </Header>
        {reviews.length ? (
          reviews?.map((i) => (
            <Body key={i.id}>
              <TopWrap>
                <div className="author_avatar">
                  {i.author.image ? (
                    <img loading="lazy" src={i.author.image} alt="avatar" />
                  ) : (
                    <span>{i.author.name.slice(0, 1)}</span>
                  )}
                </div>
                <div>
                  <div>
                    <span className="fullname">{i.author.name}</span>
                    <span className="feadback">оставил отзыв на</span>
                  </div>
                  <div>
                    <span className="feadback">товар</span>
                    <span className="nameProduct"> "{i.product_title}"</span>
                  </div>
                </div>
              </TopWrap>
              <div className="actions">
                <span className="date">{i.created_at.slice(0, 10)}</span>
                <span className="raiting">
                  <img loading="lazy" src={star} alt="star" />
                  {i.rating}
                </span>
                <Button
                  onClick={() => readedReviewHandler(i)}
                  variant="outlined"
                >
                  Прочитано
                </Button>
                <Button
                  onClick={() => deleteReviewHandler(i)}
                  variant="dangeroutlined"
                >
                  Удалить
                </Button>
              </div>
              <DescriptionNotification description={i.text} />
            </Body>
          ))
        ) : (
          <Body>
            <h4 style={{ padding: "10px 25px 10px 25px" }}>Нет уведомление!</h4>
          </Body>
        )}
      </WrapPopUp>
    </PopUp>
  );
};
export default BellNotification;

const TopWrap = styled("div")`
  display: flex;
  & .author_avatar {
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    > img {
      width: 100%;
      border-radius: 50%;
    }
  }
  & > div > div:nth-of-type(1) {
    margin-bottom: 3px;
  }

  & > div:first-of-type {
    margin-right: 14px;
  }

  & .nameProduct {
    font-weight: 600;
  }
  & .fullname {
    color: #09234f;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }

  & .feadback {
    font-size: 16px;
    color: #8e8e92;
  }
`;
const Body = styled("div")`
  padding: 10px 15px 10px 15px;
  &:hover {
    background: #dde0e680;
  }
  & .actions {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    margin-top: 7px;
    button {
      width: 87px;
      height: 19px;
      font-size: 10px;
    }
  }
  & .date {
    margin-right: 10px;
    font-size: 14px;
    color: #949494;
  }
  & .raiting {
    font-weight: 500;
    & > img {
      margin-right: 10px;
    }
  }
`;
const WrapPopUp = styled("div")`
  position: relative;
`;
const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 15px 11px 15px;
  border-bottom: 1px solid #d9d9d9;
  & > h2 {
    font-size: 18px;
  }
`;
