import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { getOrdersAccountantCount } from "../managerStore/orders";

export const getNewBalances = createAsyncThunk(
  "balances/getNewBalances",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/dealer-balance/new/list/${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

// export const searchOfNewBalances = createAsyncThunk(
//   "balances/searchOfNewBalances",
//   async (props, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.get(
//         `crm/dealer-balance/new/list/search/?username=${props}`
//       );
//       return data;
//     } catch (error) {
//       return rejectWithValue(error?.detail);
//     }
//   }
// );

export const sortBalances = createAsyncThunk(
  "balances/sortBalances",
  async (props, { rejectWithValue }) => {
    if (props.type.dealer_status === "Все") {
      delete props.type.dealer_status
    }
    if (props.type.city === "Все") {
      delete props.type.city
    }
    if (!props.type.search) {
      delete props.type.search
    }

    try {
      const { data } = await axiosInstance.post(
        `crm/dealers-balance/list/?page=${props.page}&page_size=15`,
        props.type
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const NotconfirmcheckAction = createAsyncThunk(
  "balances/NotconfirmcheckAction",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      let access = null;
      if (props?.access) {
        access = {
          is_success: true,
        };
      } else {
        access = {
          is_success: false,
        };
      }
      const { data } = await axiosInstance.post(
        "crm/dealer-balance/new/update/",
        { balance_id: props.id, ...access, type_status: props.type_status }
      );
      dispatch(getNewBalances(`?page=${props.page}&page_size=15`));
      dispatch(getOrdersAccountantCount());
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const confirmcheckAction = createAsyncThunk(
  "balances/confirmcheckAction",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      let access = null;
      if (props?.access) {
        access = {
          is_success: true,
        };
      } else {
        access = {
          is_success: false,
        };
      }
      const { data } = await axiosInstance.post(
        "crm/dealer-balance/new/update/",
        { balance_id: props.id, ...access, type_status: props.type_status }
      );
      dispatch(getNewBalances(`?page=${props.page}&page_size=15`));
      dispatch(getOrdersAccountantCount());
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getHistoryBalances = createAsyncThunk(
  "balances/getHistoryBalances",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/dealer-balance/history/list/${props.url}&page_size=16`,
        { username: props.id }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getTotalBalances = createAsyncThunk(
  "balances/getTotalBalances",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/total-balance/${props}`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const BalancesSlice = createSlice({
  name: "balances",
  initialState: {
    balances: {},
    getusers_status: "",
    status_history: "",
    history_balances: [],
    total_balances: {},
    confirmCheck_status: "",
    notconfirmCheck_status: "",
  },
  reducers: {
    clearStatus: (state) => {
      state.confirmCheck_status = "";
      state.notconfirmCheck_status = "";
    },
  },
  extraReducers: {
    [NotconfirmcheckAction.fulfilled]: (state) => {
      state.notconfirmCheck_status = "success";
    },
    [confirmcheckAction.fulfilled]: (state) => {
      state.confirmCheck_status = "success";
    },
    // ------------------------------------------------------
    [getNewBalances.pending]: (state) => {
      state.getusers_status = "pending";
    },
    [getNewBalances.fulfilled]: (state, { payload }) => {
      state.balances = payload;
      state.getusers_status = "success";
    },
    [getNewBalances.rejected]: (state, { payload }) => {
      state.balances = payload;
    },
    //-----------------------------------------------------------------
    [sortBalances.pending]: (state) => {
      state.getusers_status = "pending";
    },
    [sortBalances.fulfilled]: (state, { payload }) => {
      state.balances = payload;
      state.getusers_status = "success";
    },
    [sortBalances.rejected]: (state, { payload }) => {
      state.balances = payload;
    },
    //------------------------------------------------------------------
    [getHistoryBalances.pending]: (state) => {
      state.status_history = "pending";
    },
    [getHistoryBalances.fulfilled]: (state, { payload }) => {
      state.history_balances = payload;
      state.status_history = "success";
    },
    [getHistoryBalances.rejected]: (state, { payload }) => {
      state.history_balances = payload;
    },

    [getTotalBalances.fulfilled]: (state, { payload }) => {
      state.total_balances = payload;
    },
  },
});

export const BalancesActions = BalancesSlice.actions;
export default BalancesSlice;
