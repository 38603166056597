import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import PopUp from "../ui/PopUp";

const PopUpCategory = ({ open, anchorEl, onClose, mobileClose }) => {
  const navigate = useNavigate();
  const { category } = useSelector((store) => store?.userInfo);

  return (
    <PopUp open={open} anchorEl={anchorEl} onClose={onClose}>
      <WrapPopUpCatalog>
        {category?.length > 0 &&
          [...category, { slug: "All", title: "Все" }]?.map((i, idx) => (
            <div
              key={idx}
              onClick={(e) => {
                navigate(`catalog/` + i?.slug + "?page=1");
                onClose(e);
                mobileClose();
              }}
            >
              {i?.title !== "Все" && (
                <img loading="lazy" src={i?.image} alt={i?.title} />
              )}
              <span>{i?.title}</span>
            </div>
          ))}
      </WrapPopUpCatalog>
    </PopUp>
  );
};
export default PopUpCategory;
const WrapPopUpCatalog = styled("div")`
  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 15px 5px 15px;
    max-width: 390px;
  }
  & > div:hover {
    background: rgba(66, 172, 64, 0.3);
  }
  & > div > img {
    width: 30px;
    height: 30px;
    background-size: cover;
    margin-right: 10px;
    border-radius: 5px;
  }
  @media screen and (max-width: 500px) {
    /* height: 180px; */
    /* max-height: 200px; */
  }
`;
