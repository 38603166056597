import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersByDate } from "../../store/warehouse/report";
import styled from "@emotion/styled";
import { format } from "date-fns";
import PopUp from "../../components/ui/PopUp";
import DatePickerParams from "../../components/ui/DatePickerParams";
import Button from "../../components/ui/Button";

function ReportPage() {
  const dispatch = useDispatch();
  const { orders, status_orders } = useSelector((s) => s.report);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [filteredData, setfilteredData] = useState({});

  const formatStart = format(new Date(start), "dd-MM-yyyy");
  const formatEnd = format(new Date(end), "dd-MM-yyyy");

  useEffect(() => {
    dispatch(getOrdersByDate({ start: formatStart, end: formatEnd }));
  }, []);

  const getDates = (e) => {
    setStart(e[0].startDate);
    setEnd(e[0].endDate);
  };
  useEffect(() => {
    let groupedCat = {};
    const removedEmpties = orders.filter((i) => {
      return i.sale + i.before + i.after !== 0;
    });
    const sorted = removedEmpties.sort((a, b) => b.after - a.after)
    sorted?.forEach((i) => {
      if (!groupedCat[i.category]) {
        groupedCat[i.category] = [];
      }
      groupedCat[i.category].push(i);
    });

    console.log(groupedCat);
    setfilteredData(groupedCat);
  }, [orders]);

  const [datePicher, setDatePicker] = useState({
    modal: false,
    anchorEl: null,
  });
  const openDatePicker = (e) => {
    if (datePicher.anchorEl) {
      setDatePicker((prev) => ({ ...prev, modal: false, anchorEl: null }));
    } else {
      setDatePicker((prev) => ({
        ...prev,
        modal: true,
        anchorEl: e.currentTarget,
      }));
    }
  };

  const aplayDatesHandle = (e) => {
    dispatch(getOrdersByDate({ start: formatStart, end: formatEnd }));
    openDatePicker();
  };

  const getAfterSum = Object.keys(filteredData).map((key) => filteredData[key].reduce((a, b) => a + b.after, 0)).reduce((a, b) => a + b, 0)
  const getSaleSum = Object.keys(filteredData).map((key) => filteredData[key].reduce((a, b) => a + b.sale, 0)).reduce((a, b) => a + b, 0)
  const getBeforeSum = Object.keys(filteredData).map((key) => filteredData[key].reduce((a, b) => a + b.before, 0)).reduce((a, b) => a + b, 0)
  console.log(getAfterSum);

  return (
    <Box sx={{ paddingBottom: "40px" }}>
      <Box
        sx={{
          display: "flex",
          button: { width: "120px", height: "30px", marginLeft: "100px" },
        }}
      >
        <Typography variant="h6">Отчет</Typography>
        <Button onClick={openDatePicker} variant="contained">
          Фильтр
        </Button>
        <Typography marginLeft="50px">
          От: {formatStart} До: {formatEnd}
        </Typography>
      </Box>

      <PopUp
        open={datePicher.modal}
        anchorEl={datePicher.anchorEl}
        onClose={openDatePicker}
      >
        <Box
          sx={{
            ">button": { width: "330px" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DatePickerParams start={start} end={end} onChange={getDates} />
          <Button onClick={aplayDatesHandle}>Применить</Button>
        </Box>
      </PopUp>

      <WrapTable>
        <Box
          sx={{
            display: "grid",
            padding: "6px 12px",
            gridTemplateColumns: {
              lg: "30% 20% 20% 20%",
              xs: "40% 20% 20% 20%",
            },
            color: "#809fb8",
            fontWeight: "600"
          }}
        >
          <div>Название</div>
          <div>
            <div>До</div>
            {getAfterSum}
          </div>
          <div>
            <div>Продано</div>
            {getSaleSum}
          </div>
          <div>
            <div>Остаток</div>
            {getBeforeSum}
          </div>
        </Box>
        {status_orders === "pending" ? (
          <div>
            {[...Array(15)].map((i, index) => (
              <TableSkeleton key={index} variant="rectangular" />
            ))}
          </div>
        ) : (
          Object.keys(filteredData).map((key, ind) => (
            <Box key={key} sx={{ marginTop: "15px" }}>
              <Box sx={{ marginTop: "5px", padding: "6px 8px", }}>
                <Box sx={{
                  padding: "6px 8px",
                  display: "grid",
                  background: "#b3c1cc4d",
                  borderRadius: "3px",
                  gridTemplateColumns: {
                    lg: "30% 20% 20% 20%",
                    xs: "40% 20% 20% 20%",
                  },
                  span: {
                    fontWeight: "600"
                  }
                }} >
                  <Box sx={{ borderRadius: "3px" }} component='span'>{key}</Box>
                  <span>
                    {filteredData[key].reduce((a, b) => a + b.before, 0)}
                  </span>
                  <span>
                    {filteredData[key].reduce((a, b) => a + b.sale, 0)}
                  </span>
                  <span>
                    {filteredData[key].reduce((a, b) => a + b.after, 0)}
                  </span>
                </Box>
                {filteredData[key]?.map((i) => {
                  return (
                    <Box
                      key={i.title}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          lg: "30% 20% 20% 20%",
                          xs: "40% 20% 20% 20%",
                        },

                        padding: "5px 8px",
                        "&:hover": {
                          background: "#b3c1cc4d",
                        },
                      }}
                    >
                      <div>{i.title}</div>
                      <div>{i?.before}</div>
                      <div>{i?.sale}</div>
                      <div>{i?.after}</div>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))
        )}
      </WrapTable>
    </Box>
  );
}

export default ReportPage;

const TableSkeleton = styled(Skeleton)`
  margin: 10px 0;
  padding: 20px 0;
`;

const WrapTable = styled("div")`
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
