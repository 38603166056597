import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import Button from "../../components/ui/Button";
import {
  BalancesActions,
  NotconfirmcheckAction,
  confirmcheckAction,
  getNewBalances,
  getTotalBalances,
  sortBalances,
} from "../../store/accountant/balances";
import { formatNum } from "../../utils/helpers";
import BasicModal from "../../components/ui/BasicModal";
import { LightBoxOneImage } from "../../components/ui/LightBoxImage";
import DefCheckImage from "../../assets/images/notimage.jpg";
import { getOrdersAccountantCount } from "../../store/managerStore/orders";
import { NotificationManager } from "react-notifications";
import { ReactComponent as Filter } from "../../assets/icons/filterTable.svg";
import PopUp from "../../components/ui/PopUp";
import SelectSmall from "../../components/ui/SelectSmall";

const AB_Balance = () => {
  const { role, dealer_st, city } = useSelector((i) => i?.loginAuth);
  const {
    balances,
    notconfirmCheck_status,
    confirmCheck_status,
    getusers_status,
    total_balances,
  } = useSelector((state) => state?.balances);

  const [params, setParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState({ modal: false, id: "" });
  const [selectedValue, setSelectedValueRadio] = useState("Без нал");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const urls = {
    dealer_status: params.get("dealer_status") || "Все",
    city: params.get("city") || "Все",
  };
  const nameTab = params.get("balances") || "new";

  const urlsQuery = {
    city: `${
      params.get("city") !== "Все" && params.get("city") !== null
        ? "&city=" + params.get("city")
        : ""
    }`,
    dealer_status: `${
      params.get("dealer_status") !== "Все" &&
      params.get("dealer_status") !== null
        ? "&dealer_status=" + params.get("dealer_status")
        : ""
    }`,
    is_success: `${
      params.get("is_success") !== "Все" && params.get("is_success") !== null
        ? "&is_success=" + params.get("is_success")
        : ""
    }`,
    search: searchValue ? `name=${searchValue}` : "",
    tab_status:
      nameTab === "active"
        ? `&positive=true`
        : nameTab === `not_active`
        ? `&negative=true`
        : "",
  };

  const BalanceHandler = (e) => {
    params.set("balances", e);
    setParams(params);
  };

  useEffect(() => {
    if (role !== "accountant" && role !== "manager" && nameTab === "new") {
      setParams({ balances: "active" });
    }
    if (nameTab === "not_active") {
      dispatch(
        sortBalances({
          type: {
            search: searchValue,
            negative: true,
            dealer_status: urls.dealer_status,
            city: urls.city,
          },
          page: params.get("page") || 1,
        })
      );
    }
    if (nameTab === "active") {
      dispatch(
        sortBalances({
          type: {
            search: searchValue,
            positive: true,
            dealer_status: urls.dealer_status,
            city: urls.city,
          },
          page: params.get("page") || 1,
        })
      );
    }
    if (
      (nameTab === "new" || nameTab === null) &&
      (role === "accountant" || role === "manager")
    ) {
      dispatch(
        getNewBalances(
          `search/?page=${params.get("page") || 1}${urlsQuery.city || ""}${
            urlsQuery.is_success
          }${urlsQuery.dealer_status || ""}&page_size=15`
        )
      );
      if (role === "accountant") {
        dispatch(getOrdersAccountantCount());
      }
    }

    if (nameTab === "all") {
      dispatch(
        sortBalances({
          type: {
            search: searchValue,
            dealer_status: urls.dealer_status,
            city: urls.city,
          },
          page: params.get("page") || 1,
        })
      );
    }
  }, [
    nameTab,
    params.get("page"),
    urls.dealer_status,
    urls.city,
    urlsQuery.is_success,
  ]);

  const getValueSearch = (e) => {
    setSearchValue(e.target.value);
  };

  //       enter - search
  const searchResultHandler = (e) => {
    e.preventDefault();

    dispatch(
      getTotalBalances(
        `?${urlsQuery.search}${urlsQuery.dealer_status}${urlsQuery.city}`
      )
    );

    if (
      nameTab !== "new" ||
      (role !== "accountant" && role !== "manager" && nameTab !== null)
    ) {
      dispatch(
        sortBalances({
          type: {
            search: searchValue,
            city: urls.city,
            dealer_status: urls.dealer_status || "",
          },
          page: params.get("page") || 1,
        })
      );
    } else if (nameTab === "new" || nameTab === null) {
      dispatch(
        getNewBalances(
          `search/?name=${searchValue || ""}&page=${
            params.get("page") || 1
          }&page_size=15`
        )
      );
    }
  };

  const toInnerPage = (e) => {
    if ((nameTab === "new" || nameTab === null) && role === "manager") return;
    if (
      (nameTab === "new" || nameTab === null) &&
      role === "accountant" &&
      !e?.is_moderation
    ) {
      setIsOpen({ modal: !isOpen.modal, data: e });
      dispatch(getOrdersAccountantCount());
    } else {
      if (!e?.is_moderation) {
        navigate(`${e.username}`);
      }
    }
  };
  const [page, setpage] = useState(1);

  useEffect(() => {
    if (params.get("page")) {
      setpage(+params.get("page"));
    }
  }, [params]);

  const onChangePagination = (e, value) => {
    setpage(value);
    setParams({ balances: nameTab, page: value });
  };

  useEffect(() => {
    if (confirmCheck_status === "success") {
      NotificationManager.success("Успешно пополнил!");
      dispatch(BalancesActions.clearStatus());
    }
    if (notconfirmCheck_status === "success") {
      NotificationManager.success("Успешно отказал!");
      dispatch(BalancesActions.clearStatus());
    }
  }, [confirmCheck_status, notconfirmCheck_status]);

  const confirmCheck = () => {
    dispatch(
      confirmcheckAction({
        id: isOpen.data.id,
        access: true,
        page: params.get("page") || 1,
        page_size: 15,
        type_status: selectedValue,
      })
    );
    setIsOpen(!isOpen.modal);
  };

  useEffect(() => {
    // const search = searchValue ? `name=${searchValue}` : "";
    dispatch(
      getTotalBalances(
        `?${urlsQuery.search}${urlsQuery.dealer_status}${urlsQuery.city}${urlsQuery.tab_status}`
      )
    );
  }, [urlsQuery.city, urlsQuery.dealer_status, urlsQuery.tab_status]);
  // -----------------------------------------------------------------total-balance

  const [open, setopen] = useState(false);
  const openclose = () => setopen(!open);
  const notAccessToBalanceAdd = () => {
    dispatch(
      NotconfirmcheckAction({
        id: isOpen?.data?.id,
        access: "",
        page: params.get("page") || 1,
      })
    );
    setIsOpen(!isOpen.modal);
  };

  const handleChangeRadio = (event) => {
    setSelectedValueRadio(event.target.value);
  };

  // ---------------------------------------------------------------------------

  const [anchorElfilter, setAnchorElfilter] = useState(null);
  const [openfilter, setOpenfilter] = useState(false);

  const openfilterStatus = (event) => {
    if (anchorElfilter) {
      setOpenfilter(false);
      setAnchorElfilter(null);
    } else {
      setAnchorElfilter(event.currentTarget);
      setOpenfilter(true);
    }
  };

  const getStatusHandle = (e) => {
    params.set("dealer_status", e);
    setParams(params);
    setOpenfilter(false);
  };

  const addedAll = [...dealer_st, { title: "Все" }];
  const addedAllCity = [...city, { slug: "Все", title: "Все" }];

  // ---------------------------------------------------------------------------------

  const [anchorElfilterCity, setAnchorElfilterCity] = useState(null);
  const [openfilterCity, setOpenfilterCity] = useState(false);

  const openfilterCityHandle = (e) => {
    if (anchorElfilterCity) {
      setAnchorElfilterCity(null);
      setOpenfilterCity(false);
    } else {
      setAnchorElfilterCity(e.currentTarget);
      setOpenfilterCity(true);
    }
  };

  const getCityHandle = (e) => {
    params.set("city", e);
    setParams(params);
    setOpenfilterCity(false);
  };

  const getStatusApp = (e) => {
    params.set("is_success", e);
    setParams(params);
  };

  return (
    <>
      {open && (
        <LightBoxOneImage image={isOpen?.data?.file} openmodal={openclose} />
      )}

      <WrapBalance>
        {isOpen.modal && (
          <BasicModal onClose={toInnerPage} open={isOpen.modal}>
            <Request>
              {isOpen?.data?.file ? (
                <img
                  src={isOpen?.data?.file}
                  onClick={openclose}
                  alt="check_photo"
                />
              ) : (
                <img src={DefCheckImage} alt="def_photo" />
              )}

              <div>
                <p className="name">{isOpen?.data?.user?.name}</p>
                <div className="right_content">
                  <h2>
                    <span className="name">Пополнил:</span>
                    <span className="sum">
                      {formatNum(isOpen?.data?.amount)} ₸
                    </span>
                  </h2>
                  <Box>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={selectedValue}
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="Без нал"
                        control={<Radio />}
                        label="Банк"
                      />
                      <br />
                      <FormControlLabel
                        value="Наличка"
                        control={<Radio />}
                        label="Наличка"
                      />
                      <br />
                      <FormControlLabel
                        value="Каспи"
                        control={<Radio />}
                        label="Каспи"
                      />
                    </RadioGroup>
                  </Box>
                  <div className="wrap_buttons">
                    <Button variant="danger" onClick={notAccessToBalanceAdd}>
                      Отказать
                    </Button>
                    <Button onClick={confirmCheck}>Потвердить</Button>
                  </div>
                </div>
              </div>
            </Request>
          </BasicModal>
        )}
        <Box>
          <PopUp
            open={openfilterCity}
            anchorEl={anchorElfilterCity}
            onClose={openfilterCityHandle}
          >
            {addedAllCity?.map((i) => (
              <Typography
                key={i.title}
                onClick={() => getCityHandle(i.slug)}
                sx={{
                  cursor: "pointer",
                  padding: "0 10px",
                  ":hover": { background: "#9eb7cb" },
                }}
              >
                {i?.title}
              </Typography>
            ))}
          </PopUp>
        </Box>
        <h3>Баланс</h3>
        <WrapSubHeader>
          <div className="wrap_tab">
            {(role === "accountant" || role === "manager") && (
              <span
                onClick={() => BalanceHandler("new")}
                className={`new_balance  ${
                  nameTab === "new" || nameTab === null ? "new" : ""
                }`}
              >
                Новые
              </span>
            )}
            <span
              onClick={() => BalanceHandler("active")}
              className={`active_balance  ${
                nameTab === "active" ? "active" : ""
              }`}
            >
              Активные
            </span>
            <span
              onClick={() => BalanceHandler("not_active")}
              className={`notactive_balance  ${
                nameTab === "not_active" ? "not_active" : ""
              }`}
            >
              Не активные
            </span>
            <span
              onClick={() => BalanceHandler("all")}
              className={`allbalance  ${nameTab === "all" ? "all" : ""}`}
            >
              Все
            </span>
          </div>
          <Box onSubmit={searchResultHandler} component="form">
            <div className="wrap_search">
              <input placeholder="Поиск..." onChange={getValueSearch} />
              <Button onClick={searchResultHandler}>Поиск</Button>
            </div>
          </Box>
          {nameTab === "new" || nameTab === null ? (
            <Box width="100px" marginRight="40px">
              <SelectSmall
                defvalue=""
                onChange={getStatusApp}
                style={{ minWidth: "100px" }}
                placeholder="Статус"
                options={[
                  { value: "1", title: "Успешный" },
                  { value: "0", title: "Отказ" },
                  { value: "Все", title: "Все" },
                ]}
              />
            </Box>
          ) : (
            ""
          )}
          {nameTab !== "new" && (
            <div className="all_balances">
              <div>
                <span className="text">Общий баланс:</span>
                <span className="sum">
                  {formatNum(total_balances.total_balance)} ₸
                </span>
              </div>
              {/* <div>
                <span className="text">Выбранный баланс:</span>
                <span className="sum">
                  {formatNum(total_balances.total_balance)} ₸
                </span>
              </div> */}
            </div>
          )}
        </WrapSubHeader>
        <PopUp
          open={openfilter}
          anchorEl={anchorElfilter}
          onClose={openfilterStatus}
        >
          <Box
            sx={{
              padding: "0",
              background: "secondary",
              border: "1px solid secondary",
            }}
          >
            {addedAll?.map((i) => (
              <Typography
                key={i.title}
                onClick={() => getStatusHandle(i.title)}
                sx={{
                  cursor: "pointer",
                  padding: "0 10px",
                  ":hover": { background: "#9eb7cb" },
                }}
              >
                {i?.title}
              </Typography>
            ))}
          </Box>
        </PopUp>
        <WrapTable>
          <StyledTable>
            <thead>
              <tr>
                <th>№</th>
                <th>Ф.И.О</th>
                {nameTab === "active" ||
                nameTab === "not_active" ||
                nameTab === "all" ? (
                  <>
                    <th>Баланс 1С</th>
                    <th>Баланс CRM</th>
                    <th>Оплачено</th>
                  </>
                ) : (
                  <th>Сумма</th>
                )}

                {role === "manager" ? (
                  <th>Город</th>
                ) : (
                  <th className="th_filter" onClick={openfilterCityHandle}>
                    {filterWithIcon("Город")}{" "}
                  </th>
                )}
                {nameTab !== "active" &&
                nameTab !== "not_active" &&
                nameTab !== "all" ? (
                  ""
                ) : (
                  <th className="th_filter" onClick={openfilterStatus}>
                    {filterWithIcon("Статус")}
                  </th>
                )}
                <th>Телефон</th>
                <th>E-mail</th>
                <th>Магазин</th>
              </tr>
            </thead>
            <tbody>
              {getusers_status === "pending" ? (
                <>
                  {[...Array(10)].map((i, index) => (
                    <tr key={index} className="skeleton">
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                balances?.results?.map((i) => (
                  <Box
                    sx={{
                      background:
                        i.is_moderation && !i.is_success
                          ? "#fbccc4"
                          : i.is_moderation && i.is_success
                          ? "#d0f2f8"
                          : "",
                    }}
                    component="tr"
                    key={i?.id}
                    onClick={() => toInnerPage(i)}
                  >
                    {nameTab !== "active" &&
                    nameTab !== "not_active" &&
                    nameTab !== "all" ? (
                      <>
                        <td>{i.id}</td>
                        <td>{i?.user?.name}</td>
                        <td>{formatNum(i?.amount)} ₸</td>
                        <td>{i.user?.city}</td>
                        {/* <td>{i.user?.dealer_status}</td> */}
                        <td>{i.user?.phone}</td>
                        <td>{i.user?.email}</td>
                        <td>{i.user?.magazine}</td>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                ))
              )}
              {getusers_status === "pending" ? (
                <>
                  {[...Array(10)].map((i, index) => (
                    <tr key={index} className="skeleton">
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                      <td>
                        <TableSkeleton variant="rectangular" />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                balances?.results?.map((i) => (
                  <Box
                    component="tr"
                    key={i?.id}
                    onClick={() => toInnerPage(i)}
                  >
                    {nameTab !== "active" &&
                    nameTab !== "not_active" &&
                    nameTab !== "all" ? (
                      ""
                    ) : (
                      <>
                        <td>{i.id}</td>
                        <td>{i?.name}</td>
                        <td>{formatNum(i?.balance_1c)} ₸</td>
                        <td>{formatNum(i?.balance_crm)} ₸</td>
                        <td>{formatNum(i?.balance_order)} ₸</td>
                        <td>{i?.city}</td>
                        <td>{i?.dealer_status}</td>
                        <td>{i?.phone}</td>
                        <td>{i?.email}</td>
                        <td>{i?.magazine}</td>
                      </>
                    )}
                  </Box>
                ))
              )}
            </tbody>
            {balances?.results?.length < 1 && "Пусто"}
          </StyledTable>
          {balances?.total_pages > 1 && (
            <div className="pagination_wrap">
              <div>
                {balances.total_pages > 1 && (
                  <Pagination
                    count={balances?.total_pages}
                    page={page}
                    onChange={onChangePagination}
                    size="large"
                    variant="outlined"
                    shape="rounded"
                    siblingCount={1}
                    boundaryCount={1}
                  ></Pagination>
                )}
              </div>
              <span>Страниц: {balances?.total_pages}</span>
            </div>
          )}
        </WrapTable>
      </WrapBalance>
    </>
  );
};

export default AB_Balance;

export const filterWithIcon = (e) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        svg: {
          margin: "2px 0 0 5px",
          ":hover": {
            path: { fill: "#294B66" },
          },
        },
      }}
      component="span"
    >
      {e}
      <Filter />
    </Box>
  );
};

const Request = styled("div")`
  display: flex;
  padding: 20px;
  border-radius: 8px;
  max-width: 615px;
  & .name {
    margin-bottom: 20px;
  }
  & .right_content {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* gap: 100px; */
    & > h2 {
      .name {
        color: #757575;
        margin-right: 15px;
      }
      .sum {
        color: #242424;
      }
      font-size: 30px;
    }
  }
  & img {
    width: 180px;
    height: 215px;
    margin-right: 30px;
    border-radius: 4px;
    cursor: pointer;
  }

  & .wrap_buttons {
    display: flex;

    & button {
      margin-right: 20px;
      height: 35px;
    }
  }
`;

const WrapSubHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > h3 {
    font-size: 18px;
    font-weight: 600;
    color: #09234f;
  }
  & .all_balances {
    padding: 8px 20px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    > div {
      margin: 5px 0;
      display: flex;
      align-items: center;
    }
    & .text {
      font-size: 16px;
      color: #242424;
      margin-right: 10px;
      font-weight: 500;
    }
    & .sum {
      font-size: 18px;
      color: #727272;
      font-weight: 600;
    }
  }
  & .wrap_search {
    display: flex;
    align-items: center;
    & > button {
      height: 35px;
      margin-left: 30px;
    }
    & > input {
      padding: 10px;
      height: 35px;
      border-radius: 5px;
      border: 1px solid gray;
    }
  }
  & .wrap_tab {
    display: flex;
    border-bottom: 0.5px solid gray;

    & > span {
      position: relative;
      cursor: pointer;
      padding: 15px 15px 0 15px;
      font-size: 20px;
      font-weight: 500;
    }
    & .new_balance {
      color: #4473c5;
    }
    & > .active_balance {
      color: #34b53a;
    }
    & > .notactive_balance {
      color: #ff0000;
    }
    & > .allbalance {
      color: #a507cc;
    }

    & > .new::before {
      content: "";
      width: 100%;
      border: 1px solid #4473c5;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: line 0.1s linear;
    }

    & > .active::before {
      content: "";
      width: 100%;
      border: 1px solid #34b53a;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: line 0.2s linear;
    }

    & > .not_active::before {
      content: "";
      width: 100%;
      background: #ff0000;
      border: 1px solid red;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: line 0.1s ease-in;
    }
    & > .all::before {
      content: "";
      width: 100%;
      background: #a507cc;
      border: 1px solid #a507cc;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: line 0.1s ease-in;
    }
    @keyframes line {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
    & .wrap_tab {
      overflow: auto;
      margin: 0px 0 15px 0;
      width: 100%;
      & > span {
        white-space: nowrap;
        padding-bottom: 5px;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    & .wrap_search {
      margin: 0px 0 15px 0;
      justify-content: center;
      width: 100%;
    }

    /* gap: 20px; */
  }
`;
const WrapBalance = styled("div")`
  /* padding-top: 15px; */
  & .pagination_wrap {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    .pagination_wrap {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;
const TableSkeleton = styled(Skeleton)`
  margin: 10px 0;
  padding: 20px 0;
  width: 100%;
`;

const WrapTable = styled("div")`
  width: 100%;
  overflow-x: scroll;
`;
const StyledTable = styled("table")`
  width: 100%;
  border-bottom: 1px solid #f1f4f9;
  border-radius: 4px;
  margin-top: 20px;
  border-collapse: collapse;

  & .th_filter {
    cursor: pointer;
  }
  & .skeleton td {
    padding: 0;
  }
  & thead {
    width: 100%;
  }
  & > thead > tr > th {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #809fb8;
    text-align: left;
    padding: 8px 8px 8px 0px;
    white-space: nowrap;
  }

  & > tbody > tr > td {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    padding: 8px 8px 8px 0px;
    padding-right: 40px;
    white-space: nowrap;
  }

  tbody > tr {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #f1f4f9;
    :hover {
      background: #f1f4f9;
      border-radius: 4px;
    }
  }
  thead > tr {
    padding: 8px 0 8px 5px;
  }

  @media screen and (max-width: 700px) {
    thead > tr {
      padding: 82px 0 8px 5px;
    }
  }
`;
