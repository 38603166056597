import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LightBoxImage from "../../components/ui/LightBoxImage";
import { formatNum, getcurrency_KZ } from "../../utils/helpers";
import PopUp from "../../components/ui/PopUp";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Select from "../../components/ui/Select";
import { NotificationManager } from "react-notifications";
// import { ordersActions } from "../../store/managerStore/orders";
import styled from "@emotion/styled";
import OrderCreateManager from "../../components/managerComponents/OrderCreateManager";
import star from "../../assets/images/starincard.svg";
import DefImage from "../../assets/images/notimage.jpg";
import { ReactComponent as Cart } from "../../assets/icons/cartincard.svg";
import { ReactComponent as MinusCart } from "../../assets/icons/minuscart.svg";
import { ReactComponent as PlusCart } from "../../assets/icons/pluscart.svg";

const GetCount = ({ data, changestock, stock, datauser }) => {
  const getcity =
    JSON.parse(localStorage.getItem("city"))?.map((i) => {
      return { ...i, value: i?.title };
    }) || [];
  const defcity_user = getcity?.find(
    (i) => i?.slug === (changestock || datauser?.city)
  );
  let stock_def = "";
  // if (defcity_user?.title === "Тараз") {
  //   stock_def = "Шымкент";
  // } else {
    stock_def = defcity_user?.title;
  // }
  const user_stock = stock?.find((i) => i?.city === stock_def);
  let objcity = data?.find((i) => +i?.stock === +user_stock?.id);
  return objcity;
};

const GetPrice = ({ data, city, dealer_status }) => {
  const priceobj = data?.find(
    (i) => i?.city === city && i?.dealer_status === dealer_status
  );
  return priceobj;
};

function CreateOrderProductCard({ data }) {
  const datauser = JSON.parse(localStorage.getItem("createorder"));
  const { stock } = useSelector((i) => i?.createOrder);
  const [detail, setdetail] = useState(false);
  const [modalPosition, setmodalPosition] = useState(null);
  const [count, setcount] = useState(1);
  const [haveimage, sethaveimage] = useState(true);
  const opendetail = () => setdetail(!detail);
  const [changecity, setchangecity] = useState({ slug: "" });
  const opendetailimg = (e) => {
    e.stopPropagation();
    setdetail(!detail);
  };
  const { addProduct } = OrderCreateManager();

  const getPosition = (e) => {
    e.stopPropagation();
    setmodalPosition(e?.currentTarget);
  };
  const getcity =
    JSON.parse(localStorage.getItem("city"))?.map((i) => {
      return { ...i, value: i?.title };
    }) || [];
  const defcity_user = getcity?.find((i) => i?.slug === datauser?.price_city);
  useEffect(() => {
    setchangecity(defcity_user || {});
  }, [datauser?.city]);
  const count_all = useMemo(() => {
    let userobj = GetCount({
      data: data.counts,
      changestock: changecity?.slug,
      stock,
      datauser,
    });
    return userobj;
  }, [changecity?.slug, data.counts, datauser, stock]);
  const price = useMemo(() => {
    const priceneed = GetPrice({
      data: data?.prices,
      city:  datauser?.price_city,
      dealer_status: datauser?.dealer_status,
    });
    return priceneed;
  }, [changecity?.slug, data?.prices, datauser?.city, datauser?.dealer_status]);

  const onError = () => sethaveimage(false);
  const onLoad = () => sethaveimage(true);
  const closePosition = () => {
    setmodalPosition(null);
    setchangecity(defcity_user);
  };
  const plusValue = () => {
    if (+count_all?.count >= count + 1) {
      setcount(+count + 1);
    } else {
      NotificationManager.info("Это максимальное колличество товара в наличии");
    }
  };
  const minusValue = () => {
    if (+count_all?.count >= +count) {
      if (+count === 1 || +count - 1 <= 0) return setcount(1);
      setcount(+count - 1);
    }
  };
  const getvalue = (e) => {
    if (+count_all?.count >= +e?.target?.value && +e.target.value > 0) {
      setcount(e.target.value);
    }
  };

  const addToBox = () => {
    if (+count_all?.count > 0 && +count_all?.count >= +count) {
      addProduct({
        ...data,
        count: count,
        addcity: changecity,
        maxcount: +count_all?.count,
        needprice: price,
      });
      NotificationManager.success("Успешно добавлено в корзину");
      closePosition();
    }
    closePosition();
  };
  const getvalue_select2 = (e) => {
    setchangecity(e);
  };
  

  return (
    <WrapAllCard>
      {detail && (
        <LightBoxImage
          images={data?.images?.map((i) => {
            return { ...i, url: i?.image };
          })}
          openmodal={opendetail}
        />
      )}
      {modalPosition && (
        <PopUp
          anchorEl={modalPosition}
          open={!!modalPosition}
          onClose={closePosition}
          data={data}
        >
          <InnerPopUp>
            <Select
              getvalue={() => {}}
              options={getcity || []}
              onChange={getvalue_select2}
              constant={true}
              defValue={changecity?.title || ""}
            />
            {+count_all?.count > 0 ? (
              <WrapperBtnAdd>
                <Button onClick={plusValue}>
                  <PlusCart />
                </Button>
                <Input value={count} constant={true} onChange={getvalue} />
                <Button onClick={minusValue}>
                  <MinusCart />
                </Button>
              </WrapperBtnAdd>
            ) : (
              ""
            )}

            {+count_all?.count > 0 ? (
              <p>
                {formatNum(price?.price * count || 0)} {getcurrency_KZ("KZ")}
              </p>
            ) : (
              <p>
                По городу - {changecity?.title},<br /> нету в наличии
              </p>
            )}
            {+count_all?.count > 0 ? (
              <WrapperBtn onClick={addToBox}>Добавить</WrapperBtn>
            ) : (
              ""
            )}
          </InnerPopUp>
        </PopUp>
      )}
      <WrapCard>
        <div>
          <div className="render">
            {haveimage && data?.images[0]?.image ? (
              <img
                onClick={opendetailimg}
                onLoad={onLoad}
                onError={onError}
                src={data?.images[0]?.image}
                alt="first_img_product"
              />
            ) : (
              <img src={DefImage} alt="not_image" />
            )}
          </div>
          {+price?.discount ? (
            <span>
              {formatNum(price?.discount || 0)}{" "}
              {price?.discount_status
                ? "%"
                : getcurrency_KZ(price?.currency || "")}
            </span>
          ) : (
            <WrapperSpan />
          )}
          <div className="rating">
            <span>{data?.reviews_amount}</span>
            <img loading="lazy" src={star} alt="star" />
          </div>
        </div>
        <div>
          <span>
            <WrapperH5>
              {+price?.old_price > 0 ? (
                <WrapperOldprice>
                  {formatNum(price?.old_price || 0)}{" "}
                  {getcurrency_KZ(price?.currency || "")}
                </WrapperOldprice>
              ) : (
                ""
              )}
              {formatNum(price?.price || 0)}{" "}
              {getcurrency_KZ(price?.currency || "")}
            </WrapperH5>
            <p className="title">{data?.title}</p>
            {count_all?.count > 0 && data?.is_active ? (
              <WrapperHave>В наличии</WrapperHave>
            ) : (
              <WrapperNotHave>
                {data?.is_active ? "Нет в наличии" : "Этот продукт не активен"}
              </WrapperNotHave>
            )}
          </span>
          {data?.is_active && (
            <span onClick={getPosition}>
              <Cart />
            </span>
          )}
        </div>
      </WrapCard>
    </WrapAllCard>
  );
}
export default CreateOrderProductCard;
const InnerPopUp = styled("div")`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    text-align: center;
  }
`;
const WrapperBtn = styled(Button)`
  background: #42ac40 !important;
`;

const WrapperBtnAdd = styled("span")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & > div {
    margin: 0px;
  }
  input {
    width: 50px;
    font-size: 20px;
    text-align: center;
  }
  & > button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > button:hover {
    path,
    rect {
      fill: white;
    }
  }
`;
const WrapAllCard = styled("div")``;
const WrapperH5 = styled("h5")`
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const WrapperOldprice = styled("span")`
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #8e8e92;
`;
const WrapperHave = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #16a34a;
  margin-top: 8px;
`;
const WrapperNotHave = styled("p")`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  color: #ff0000;
`;
const WrapperSpan = styled("span")`
  background: none !important;
  border: none !important;
`;

const WrapCard = styled("div")`
  max-width: 220px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0 0 5% 0;
  cursor: pointer;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  .render {
    width: 100%;
    height: 200px;
    & > img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      object-fit: cover;
      overflow: hidden;
    }
    @media screen and (max-width: 700px) {
      height: 180px;
      & > img {
        height: 180px;
      }
    }
  }
  & > div:nth-of-type(1) {
    position: relative;
    width: 100%;
  }
  & > div:nth-of-type(1) > span {
    position: absolute;
    bottom: 4px;
    left: 0;
    /* width: 100px; */
    padding: 5px;
    height: 30px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 10px;
    background: #f84135;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
  }
  .rating {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    background: #60606094;
    border-radius: 5px;
  }
  .title {
    color: #606060;
    font-size: 16px;
    margin-top: 8px;
    cursor: pointer;
  }
  & > div:nth-of-type(2) > span > h5 {
    font-size: 14px;
    margin-top: 6px;
    cursor: pointer;
    font-weight: 700;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2) {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2) .cart {
    fill: none;
    stroke: #b4b4b4;
  }
  & > div:nth-of-type(2) > span:nth-of-type(2):hover {
    & .cart {
      fill: none;
      stroke: #303030;
    }
  }
  & > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
  }

  @media screen and (max-width: 700px) {
    padding: 8px;
    margin: 5px;
    & > div > img {
      height: 100%;
      background-size: cover;
    }
    .title {
      font-size: 13px;
    }
    & > div:nth-of-type(2) > span > h5 {
      font-size: 13px;
    }
    & > div:nth-of-type(1) > div {
      bottom: 10px;
      right: 10px;
    }
    & > div:nth-of-type(2) {
      width: 100%;
    }
    & > div:nth-of-type(1) > span {
      width: 35px;
      height: 22px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 7px;
      font-size: 12px;
    }
    & > div:nth-of-type(2) > span:nth-of-type(1) {
      width: 75%;
    }
    & > div:nth-of-type(2) > span:nth-of-type(2) {
      width: 20%;
      height: 30px;
    }
  }
`;
