import styled from '@emotion/styled';
import { Box, Pagination, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchGetDidntOrderUsers } from '../../store/managerStore/didntOrderUser';
import Button from '../../components/ui/Button';
import { ReactComponent as FilterIcon } from "../../assets/icons/calendar.svg";
import DatePopover from '../../components/buhgalterComponents/DatePopover';
import { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { filterWithIcon } from '../buhgalter/AB_Balance';
import PopUp from '../../components/ui/PopUp';


function DidntOrderUsers() {
    const dispatch = useDispatch()
    const { didntOrderUsers, didntOrderUsers_status } = useSelector(s => s.didntorder)
    const { role } = useSelector((s) => s?.loginAuth);
    const { city } = useSelector((s) => s?.visit);

    const [params, setParams] = useSearchParams()
    const navigate = useNavigate()
    const name = params.get("name");
    const startDate = params.get("startD");
    const endDate = params.get("endD");
    const cityParam = params.get("city");
    const getPage = params.get("page") || 1;

    const [isOpenFilter, setIsOpenFilter] = useState({
        anchorEl: null,
        popopver: false,
    });

    const [modal, setModal] = useState({ popoverCity: false, anchorElCity: null })

    useEffect(() => {
        let query = `?page=1&page_size=20`;
        const body = {}
        if (cityParam) {
            body.city = cityParam
        }
        if (name) {
            body.name = name
        }
        if (startDate && endDate) {
            body.start = startDate
            body.end = endDate
        } else {
            const start = new Date();
            const formattedStart = format(start, "dd-MM-yyyy");
            params.set("startD", formattedStart);
            params.set("endD", formattedStart);
            setParams(params);
            return;
        }

        dispatch(fetchGetDidntOrderUsers({ body, query }))
    }, [startDate, endDate, cityParam])

    const searchButtonHandler = (e) => {
        e.preventDefault();
        let query = `?page=1&page_size=20`;
        const body = {}
        if (cityParam) {
            body.city = cityParam
        }
        if (name) {
            body.name = name
        }
        if (startDate) {
            body.start = startDate
        }
        if (endDate) {
            body.end = endDate
        }

        dispatch(fetchGetDidntOrderUsers({ body, query }));
    };


    function toInnerPage(i) {
        navigate(`${i.id}`)
    }

    const applyDateHandle = (e) => {
        const start = new Date(e?.start);
        const formattedStart = format(start, "dd-MM-yyyy");
        const end = new Date(e?.end);
        const formattedEnd = format(end, "dd-MM-yyyy");

        params.set("startD", formattedStart);
        params.set("endD", formattedEnd);
        setParams(params);

        openFilterHandle();
    };
    const openFilterHandle = (e) => {
        // console.log(e);
        setIsOpenFilter((prev) => ({
            ...prev,
            anchorEl: e?.currentTarget,
            popopver: !prev?.popopver,
        }));
    };

    const searchInputHandle = (e) => {
        const value = e.target.value;
        params.set("name", value);
        setParams(params.toString());
    };

    const onChangePagination = (e, value) => {
        params.set("page", value);
        setParams(params);

        let query = `?page=${value}&page_size=20`;
        const body = {}
        if (cityParam) {
            body.city = cityParam
        }
        if (name) {
            body.name = name
        }
        if (startDate) {
            body.start = startDate
        }
        if (endDate) {
            body.end = endDate
        }
        dispatch(fetchGetDidntOrderUsers({ body, query }));
        window.scrollTo({
            top: "0",
            behavior: "smooth",
        });
    };
    // console.log(didntOrderUsers);

    function openfilterByCity({ currentTarget }) {
        setModal(prev => ({ ...prev, popoverCity: !prev.popoverCity, anchorElCity: currentTarget }))
    }
    const addedAllCity = [...city, { slug: "", title: "Все" }];

    const getCityHandle = (e) => {
        params.set("city", e);
        setParams(params);
        setModal(prev => ({ ...prev, popoverCity: false, anchorElCity: null }));
    };


    return (
        <div>
            <Box>
                <PopUp
                    open={modal.popoverCity}
                    anchorEl={modal.anchorElCity}
                    onClose={openfilterByCity}
                >
                    {addedAllCity?.map((i) => (
                        <Typography
                            key={i.title}
                            onClick={() => getCityHandle(i.slug)}
                            sx={{
                                cursor: "pointer",
                                padding: "0 10px",
                                ":hover": { background: "#9eb7cb" },
                            }}
                        >
                            {i.title}
                        </Typography>
                    ))}
                </PopUp>
            </Box>
            <DatePopover
                applyHandle={applyDateHandle}
                open={isOpenFilter.popopver}
                onClose={openFilterHandle}
                anchorEl={isOpenFilter.anchorEl}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <Typography variant='h6'>Пользователи</Typography>
                <Box sx={{ width: "200px" }}>

                    <Button onClick={openFilterHandle} style={{ height: "35px" }}>
                        Фильтр <FilterIcon style={{ marginLeft: "5px" }} />
                    </Button>
                </Box>

                <Box
                    sx={{
                        marginLeft: "35px", ".search_btn": {
                            display: "flex"
                        }, button: {
                            height: "40px",
                            marginLeft: "30px",
                        }
                    }}
                    onSubmit={searchButtonHandler}
                    component="form"
                >
                    <div className="search_btn">
                        <InputStyled
                            value={params?.get("name") || ""}
                            onChange={searchInputHandle}
                            placeholder="Поиск..."
                        />
                        <Button onClick={searchButtonHandler}>Поиск</Button>
                    </div>
                </Box>
            </Box>
            {didntOrderUsers_status === "pending" && <Box>
                {[...Array(20)].map((el) => (
                    <Skeleton variant="rectangular" width={"100%"} height={48} sx={{ borderRadius: "4px", margin: '10px 0' }} />

                ))}
            </Box>
            }
            <StyledTable>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th className="th">Ф.И.О</th>

                        {role === "manager" ? <th className="th">Город</th> : <th className="th" onClick={openfilterByCity}>{filterWithIcon("Город")}</th>}
                    </tr>
                </thead>
                <tbody>
                    {didntOrderUsers?.results?.map((i) => (
                        <tr key={i.id} onClick={() => toInnerPage(i)}>
                            <td>{i.id} </td>
                            <td>{i.name} </td>
                            <td>{i.city_title} </td>

                        </tr>
                    ))}
                    {didntOrderUsers?.results?.length < 1 && "Пусто"}
                </tbody>
            </StyledTable>
            {didntOrderUsers?.total_pages > 1 && (
                <div className="pagination_wrap">
                    <div>
                        {didntOrderUsers?.total_pages > 1 && (
                            <Pagination
                                count={didntOrderUsers?.total_pages}
                                page={+getPage}
                                onChange={onChangePagination}
                                size="large"
                                variant="outlined"
                                shape="rounded"
                                siblingCount={1}
                                boundaryCount={1}
                            ></Pagination>
                        )}
                    </div>
                    <span>Страниц: {didntOrderUsers?.total_pages}</span>
                </div>
            )}
        </div >
    )
}

export default DidntOrderUsers

const InputStyled = styled("input")`
  width: 170px;
  height: 40px;
  padding: 8px;
  border: 1px solid #8d8d8d;
  border-radius: 8px;
  outline: none;
`;


const StyledTable = styled("table")`
  width: 100%;
  border-bottom: 1px solid #f1f4f9;
  border-radius: 4px;
  margin-top: 20px;
  border-collapse: collapse;
  & .th_filter {
    cursor: pointer;
  }
  & .notreaded {
    background: #dadbdb;
  }
  & .skeleton td {
    padding: 0;
  }
  & thead {
    width: 100%;
  }
  & > thead > tr > th {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #809fb8;
    text-align: left;
  }

  & > tbody > tr > td {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    cursor: pointer;
    padding: 8px 8px 8px 0;
    padding-right: 10px;
    white-space: nowrap;
  }

  tbody > tr {
    padding: 8px 0 8px 5px;
    border-bottom: 1px solid #f1f4f9;
    :hover {
      background: #f1f4f9;
      border-radius: 4px;
    }
  }
  thead > tr {
    padding: 8px 0 8px 5px;
  }
`;
