import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const LoginAuth = createAsyncThunk(
  "auth/login",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("user/login/", props.data);
      localStorage.setItem("accessToken", data?.access);
      localStorage.setItem("refreshToken", data?.refresh);
      localStorage.setItem("role", data?.status);
      localStorage.setItem("token", 1);
      localStorage.removeItem("token_not_valid");

      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const GetCities = createAsyncThunk(
  "auth/GetCities",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetDealerStatus = createAsyncThunk(
  "auth/GetDealerStatus",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("dealer/status/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetMainManagerInfo = createAsyncThunk(
  "auth/GetMainManagerInfo",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("forgot-pwd/manager/list/", {
        city: props,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  status: "",
  role: localStorage.getItem("role") || "",
  data: null,
  city: JSON.parse(localStorage.getItem("city")) || [],
  city_status: "",
  main_manager: [],
  main_manager_status: "",
  dealer_st: [],
};

const Auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    Logout: (state) => {
      state.data = null;
      state.role = "";
      state.status = "";
      localStorage.clear();
    },
    clearStatusesLoginauth: (state) => {
      state.status = "";
    },
  },
  extraReducers: {
    [LoginAuth.pending]: (state) => {
      state.status = "pending";
    },
    [LoginAuth.fulfilled]: (state, actions) => {
      state.data = actions.payload;
      state.role = actions.payload?.status;
      state.status = "success";
    },
    [LoginAuth.rejected]: (state, actions) => {
      state.status = "rejected";
      state.data = actions.payload;
    },
    [GetCities.pending]: (state) => {
      state.city_status = "pending";
    },
    [GetCities.fulfilled]: (state, actions) => {
      state.city_status = "success";
      state.city = actions.payload;
    },
    [GetCities.rejected]: (state, actions) => {
      state.city_status = "rejected";
      state.city = actions.payload;
    },
    ///----------------GetDealerStatus--------------
    [GetDealerStatus.fulfilled]: (state, { payload }) => {
      state.dealer_st = payload;
    },
    //---------------------------------------------
    [GetMainManagerInfo.pending]: (state) => {
      state.main_manager_status = "pending";
    },
    [GetMainManagerInfo.fulfilled]: (state, actions) => {
      state.main_manager_status = "success";
      state.main_manager = actions.payload;
    },
    [GetMainManagerInfo.rejected]: (state, actions) => {
      state.main_manager_status = "rejected";
    },
  },
});

export const AuthAction = Auth.actions;
export default Auth;
