import styled from "@emotion/styled";
import PopUp from "../../../components/ui/PopUp";
import Accordions from "../../ui/Acordion";
import Insta from "../../../assets/images/insta.svg";
import Youtube from "../../../assets/images/youtube.svg";
import Tiktok from "../../../assets/images/tiktok.svg";
import Whats from "../../../assets/images/whats.svg";
import Email from "../../../assets/images/email.svg";
import Telefon from "../../../assets/images/telefon.svg";
import Back from "../../../assets/images/back.svg";
import Sclad from "../../../assets/images/shop.svg";
import Message from "../../../assets/images/messages.svg";
import Seti from "../../../assets/images/Seti.svg";
import Ls from "../../../assets/images/Ls.svg";
import Shop from "../../../assets/images/info.svg";
import ABbalanse from "../../../assets/icons/ABbalanse.svg";
import { useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction, GetMainManagerInfo } from "../../../store/loginStore/Auth";
import { OrderProductActions } from "../../../store/userStore/OrderProduct";
import { useEffect } from "react";

const PopUpUserProfile = ({ open, anchorEl, onClose, mobileClose }) => {
  const { data } = useSelector((i) => i?.userInfo);
  const { main_manager } = useSelector((i) => i?.loginAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateTo = (e, i) => {
    navigate(i);
    onClose(e);
    mobileClose();
  };

  useEffect(() => {
    dispatch(GetMainManagerInfo());
  }, []);

  const logout = (e) => {
    dispatch(AuthAction.Logout());
    dispatch(OrderProductActions.ClearBox());
    navigateTo(e, "/");
    mobileClose();
  };

  const getPhoneNumber = main_manager?.find(
    (i) => i.city === data.address
  )?.phone;

  return (
    <PopUp open={open} anchorEl={anchorEl} onClose={onClose}>
      <WrapPopUpCatalog>
        <div>
          <div onClick={(e) => navigateTo(e, "/user/stock")}>
            <Accordions img={Sclad} title="Cклады" />
          </div>

          <Accordions
            img={Message}
            title="Связаться с нами"
            content={
              <BlockDiv1>
                <div>
                  <a href={"tel:" + getPhoneNumber}>{getPhoneNumber}</a>
                </div>
                <div>
                  <img src={Telefon} alt="ww" />
                </div>
              </BlockDiv1>
            }
          />
          <Accordions
            img={Seti}
            title="Социальные сети"
            // content={data1?.map((i, idx) => (
            //   <BlockDiv key={idx + "popup_profile"}>
            //     {/* <div>
            //       <div></div>
            //     </div> */}
            //   </BlockDiv>
            // ))}
          />
          <div onClick={(e) => navigateTo(e, "/user/profile")}>
            <Accordions img={Ls} title="Личный кабинет" />
          </div>
          <div onClick={(e) => navigateTo(e, "/user/balance")}>
            <Accordions img={ABbalanse} title="Кошелек" />
          </div>
          <div onClick={(e) => navigateTo(e, "/user/historysale")}>
            <Accordions img={Shop} title="История покупок" />
          </div>

          <AccordionS>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ButtonS>
                <img loading="lazy" src={Back} alt="Back" /> Выйти
              </ButtonS>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="span">
                <Div>
                  <p
                    className="net"
                    onClick={(e) => {
                      onClose(e);
                      mobileClose();
                    }}
                  >
                    Нет
                  </p>
                  <p className="da" onClick={logout}>
                    Да
                  </p>
                </Div>
              </Typography>
            </AccordionDetails>
          </AccordionS>
        </div>
      </WrapPopUpCatalog>
    </PopUp>
  );
};
export default PopUpUserProfile;
const WrapPopUpCatalog = styled("div")`
  & > div {
    display: block;
    align-items: flex-start;
    cursor: pointer;
    padding: 8px 15px 8px 15px;
    max-width: 400px;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      text-align: center;
      letter-spacing: 1px;

      color: #010101;
    }
  }
  @media screen and (max-width: 500px) {
    max-height: 350px;
  }
`;

const BlockDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  padding-top: 10px;
  padding-left: 20px;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    left: 30px;
  }
  a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: #010101;
    text-decoration: none;
  }
`;

const BlockDiv1 = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  img {
    margin-left: 20px;
    margin-right: 10px;
  }
  p,
  a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    color: #010101;
    text-decoration: none;
  }
`;

const ButtonS = styled(Button)`
  background-color: transparent;
  color: red;
  box-shadow: none;
  text-align: start;
  margin-left: -7px;

  :hover {
    background: transparent;
    color: red;
  }
`;

const AccordionS = styled(Accordion)`
  box-shadow: none;
  position: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Div = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 50px;
  margin-top: -20px;
  .net {
    border: 1px solid #303030;
    filter: drop-shadow(0px 10px 20px rgba(48, 48, 48, 0.25));
    border-radius: 6px;
    color: #303030;
    width: 60px;
    height: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
  }
  .da {
    border: 1px solid #dd1919;
    filter: drop-shadow(0px 10px 20px rgba(48, 48, 48, 0.25));
    border-radius: 6px;
    color: #dd1919;
    margin-left: 10px;
    width: 60px;
    height: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
