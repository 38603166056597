import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getAllProducts = createAsyncThunk(
  "products/getAllProducts",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("crm/product/count-category/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const getProductSlug = createAsyncThunk(
  "products/getProductSlug",
  async (slug, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/product/all-category/`, {
        category_slug: slug,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getCities = createAsyncThunk(
  "products/getCities",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/product/count-city/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetAllStockCount = createAsyncThunk(
  "products/GetAllStockCount",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/product/count-stock/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetStoks = createAsyncThunk(
  "products/GetStoks",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`stock/list/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getProductsByCity = createAsyncThunk(
  "products/getProductsByCity",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/stock/product/count-category/`,
        { stock_id: id }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetCountCityProduct = createAsyncThunk(
  "products/GetCountCityProduct",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/city/product/count-category/`,
        {
          stock_id: id,
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const getProductCategoryByCity = createAsyncThunk(
  "products/getProductCategoryByCity",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/stock/product/all-category/`,
        { stock_id: props?.id, category_slug: props.category_slug }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const initialState = {
  all_products: [],
  cities: [],
  product: [],
  products_bycity: [],
  productsbycategory_bycity: [],
  allproducts_status: "",
  product_status: "",
  stock: [],
  status: "",
  all_stock: [],
  all_stock_sattus: "",
  productByCity_status: "",
};

const productSlice = createSlice({
  name: "products",
  initialState,
  extraReducers: {
    [GetAllStockCount.pending]: (state) => {
      state.all_stock_sattus = "pending";
    },
    [GetAllStockCount.fulfilled]: (state, { payload }) => {
      state.all_stock = payload;
      state.all_stock_sattus = "success";
    },
    [GetAllStockCount.rejected]: (state) => {
      state.all_stock_sattus = "rejected";
    },
    [GetStoks.pending]: (state) => {
      state.status = "pending";
    },
    [GetStoks.fulfilled]: (state, { payload }) => {
      state.stock = payload;
      state.status = "success";
    },
    [GetStoks.rejected]: (state) => {
      state.status = "rejected";
    },
    [getAllProducts.pending]: (state) => {
      state.allproducts_status = "pending";
    },
    [getAllProducts.fulfilled]: (state, { payload }) => {
      state.all_products = payload;
      state.allproducts_status = "success";
    },
    [getAllProducts.rejected]: (state, { payload }) => {
      state.all_products = payload;
      state.allproducts_status = "reject";
    },
    //----------------------------------------------------
    [getProductSlug.pending]: (state) => {
      state.product_status = "pending";
    },
    [getProductSlug.fulfilled]: (state, { payload }) => {
      state.product = payload;
      state.product_status = "success";
    },
    [getProductSlug.rejected]: (state, { payload }) => {
      state.product = payload;
    },
    //----------------------------------------------------
    [getCities.fulfilled]: (state, { payload }) => {
      state.cities = payload;
    },
    //----------------------------------------------------
    [getProductsByCity.pending]: (state, { payload }) => {
      state.productByCity_status = "pending"
    },
    [getProductsByCity.fulfilled]: (state, { payload }) => {
      state.productByCity_status = "success"
      state.products_bycity = payload;
    },
    [getProductsByCity.rejected]: (state, { payload }) => {
      state.productByCity_status = "reject"
    },
    //----------------------------------------------------
    [getProductCategoryByCity.pending]: (state) => {
      state.product_status = "pending";
    },
    [getProductCategoryByCity.fulfilled]: (state, { payload }) => {
      state.product = payload;
      state.product_status = "success";
    },
    [getProductCategoryByCity.rejected]: (state, { payload }) => {
      state.product = payload;
    },
    //----------------------------------------------------
  },
});

export const productActions = productSlice.actions;
export default productSlice;
