import { TextField, ThemeProvider, createTheme } from "@mui/material";
import styled from "@emotion/styled";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#5D7285",
    },
  },
});

const Input = ({
  value,
  onChange,
  label,
  variant,
  border,
  width,
  type,
  name,
  multiline,
  constant,
  placeholder,
  error,
  ...props
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Div errorlabel={error}>
        <label className="error">
          {label} {!constant && <span>*</span>}
        </label>
        <InputWrapper
          error={error}
          multiline={multiline}
          value={value}
          onChange={onChange}
          variant={variant || "standard"}
          color={border || "secondary"}
          width={width}
          type={type || "text"}
          name={name}
          placeholder={placeholder}
          {...props}
        />
      </Div>
    </ThemeProvider>
  );
};
export default Input;

const Div = styled("div")`
  margin-top: 15px;
  & > label {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => (props.errorlabel ? "red" : "#5d7285")};
    display: flex;
    gap: 8px;
    & > span {
      font-size: 18px;
      color: red;
    }
  }
`;

const InputWrapper = styled(TextField)`
  width: ${(props) => (props.width ? `${props.width}%` : "100%")};
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
