import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectSmall({
  placeholder,
  options,
  onChange,
  defvalue,
}) {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
      <InputLabel id="demo-select-small">
        {placeholder || "Категории"}
      </InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={defvalue || value}
        label={placeholder || "Категории"}
        onChange={handleChange}
        defaultValue=""
      >
        {options?.map((i, idx) => (
          <MenuItem value={i?.value || i?.slug} key={idx}>
            {i?.name || i?.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
