import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getHistorySale = createAsyncThunk(
  "historysale/getHistorySale",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `user/order/list/search/?${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getHistorySaleDetail = createAsyncThunk(
  "historysale/getHistorySaleDetail",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`user/order/list/${id}/`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getMyOrder = createAsyncThunk(
  "historysale/getMyOrder",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `crm/user/order/result/`,
        props
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

const historysaleSlice = createSlice({
  name: "historysale",
  initialState: {
    his_sale: {},
    gone_order: "",
    detail_order: {},
  },
  reducers: {
    remove_stgoneorder: (state) => {
      state.gone_order = "";
    },
  },
  extraReducers: {
    [getHistorySaleDetail.fulfilled]: (state, { payload }) => {
      state.detail_order = payload;
    },
    [getHistorySale.fulfilled]: (state, { payload }) => {
      state.his_sale = payload;
    },
    [getMyOrder.fulfilled]: (state, { payload }) => {
      state.gone_order = payload;
    },
  },
});

export const historysaleActions = historysaleSlice.actions;
export default historysaleSlice;
