import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { ru } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSearchParams } from "react-router-dom";

const DatePickerParams = ({ onChange, start, end }) => {
  const [state, setState] = useState([
    {
      startDate: start || new Date(),
      endDate: end || new Date(),
      key: "selection",
    },
  ]);
  const [params] = useSearchParams();
  useEffect(() => {
    if (params?.get("start") && params?.get("end")) {
      setState([
        {
          startDate: new Date(params?.get("start")),
          endDate: new Date(params.get("end")),
          key: "selection",
        },
      ]);
    }
  }, [params?.toString()]);
  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <DateRange
      editableDateInputs={true}
      onChange={(item) => setState([item.selection])}
      moveRangeOnFirstSelection={false}
      ranges={state}
      locale={ru}
      rangeColors={["#09234f"]}
    />
  );
};

export default DatePickerParams;
