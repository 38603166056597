import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const getChat = createAsyncThunk(
  "chat/getChat",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`chat/`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getChatList = createAsyncThunk(
  "chat/getChatList",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`chat/${props}`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getChatListNewMessage = createAsyncThunk(
  "chat/getChatListNewMessage",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`chat/?page=1&page_size=10`);
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const PostMessageWithMedia = createAsyncThunk(
  "chat/PostMessageWithMedia",
  async (props, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (props?.type === "image") {
        for (let i = 0; i < props?.media?.length; i++) {
          formData.append("upload_files", props?.media[i]?.file);
        }
      }
      if (props?.type === "audio") {
        const audioBlob = await fetch(props?.media).then((r) => r.blob());
        const audioFile = new File([audioBlob], "voice.mp3", {
          type: "audio/mp3",
        });
        formData.append("upload_files", audioFile);
      }
      if (props?.type === "video") {
        formData.append("upload_files", props?.media);
      }
      if (props?.type === "selfi") {
        formData.append("upload_files", props?.media);
      }
      formData.append("chat", props?.chat);
      const { data } = await ImageUploadingFetch.post(
        `chat/create/message/`,
        formData
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const ChatDetail = createAsyncThunk(
  "chat/ChatDetail",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`chat/messages/${props}/`);
      return { ...data, chat_id: props };
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const ChatDetailPogination = createAsyncThunk(
  "chat/ChatDetailPogination",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `chat/messages/${props.id}/?limit=20&offset=${props.offset}`
      );
      return { ...data, chat_id: props };
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const SearchByUserChat = createAsyncThunk(
  "chat/SearchByUserChat",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `chat/search-dealers/?search=${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

const Chat = createSlice({
  name: "chat",
  initialState: {
    data: null,
    detail_chat: null,
    detail_chat_status: "",
    search_chat: null,
    search_chat_status: "",
    new_message: null,
    new_message_status: false,
    chat_id: "",
    allchats: null,
    chat_list: {} || null,
    chat_status: "",
    new_chat_count: null,
  },
  reducers: {
    AddMainMessage: (state, actions) => {
      state.detail_chat = actions.payload;
    },
    GetCHatId: (state, actions) => {
      state.chat_id = actions.payload;
    },
    GetAllChats: (state, actions) => {
      state.allchats = actions.payload;
    },
    MutagenChatList: (state, actions) => {
      const mutagen = actions?.payload?.data?.map((i) => {
        if (i?.id === actions?.payload?.id) {
          return { ...i, new_messages_count: 0 };
        }
        return i;
      });
      state.chat_list.results = mutagen;
    },
  },
  extraReducers: {
    [getChatList.pending]: (state) => {
      state.chat_status = "pending";
    },
    [getChatList.fulfilled]: (state, { payload }) => {
      state.chat_list = payload;
      state.chat_status = "success";
    },
    [getChatList.rejected]: (state) => {
      state.chat_status = "rejected";
    },
    [getChat.pending]: (state) => {
      state.orders_status = "pending";
    },
    [getChat.fulfilled]: (state, { payload }) => {
      state.orders = payload;
      state.orders_status = "success";
    },
    [getChat.rejected]: (state) => {
      state.orders_status = "rejected";
    },
    [getChatListNewMessage.fulfilled]: (state, { payload }) => {
      state.new_chat_count = payload;
    },
    [ChatDetail.pending]: (state) => {
      state.detail_chat_status = "pending";
    },
    [ChatDetail.fulfilled]: (state, { payload }) => {
      state.detail_chat = payload;
      state.detail_chat_status = "success";
    },
    [ChatDetail.rejected]: (state) => {
      state.detail_chat_status = "rejected";
    },
    [ChatDetailPogination.fulfilled]: (state, { payload }) => {
      state.new_message = payload;
      state.new_message_status = !state.new_message_status;
    },
    [ChatDetailPogination.rejected]: (state) => {
      state.new_message_status = !state.new_message_status;
    },
    [SearchByUserChat.pending]: (state) => {
      state.search_chat_status = "pending";
    },
    [SearchByUserChat.fulfilled]: (state, { payload }) => {
      state.search_chat = payload;
      state.search_chat_status = "success";
    },
    [SearchByUserChat.rejected]: (state) => {
      state.search_chat_status = "rejected";
    },
  },
});

export const ChatActions = Chat.actions;
export default Chat;
