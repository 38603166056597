import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ImageUploadingFetch } from "../../api/axios";

export const ToUpWalletSlice = createAsyncThunk(
  "wallet/ToUpWallet",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("amount", props?.repliced);
      formdata.append("file", props?.check[0].file);
      if (props.user) {
        formdata.append("user", props.user)
      }
      const { data } = await ImageUploadingFetch.post(
        `user/application/c/`,
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const getRequisites = createAsyncThunk(
  "wallet/getRequisites",
  async (props, { rejectWithValue }) => {
    try {

      const { data } = await ImageUploadingFetch.post(
        `payment/requisite/`,
        { type: props }
      );
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);


const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    status_toupwallet: "",
    requisites: {}
  },
  reducers: {
    changeStatusToupWallet: (state) => {
      state.status_toupwallet = "";
    },
  },
  extraReducers: {

    [getRequisites.fulfilled]: (state, { payload }) => {
      state.requisites = payload;
    },
    // -----------------------------------------------------------
    [ToUpWalletSlice.fulfilled]: (state, { payload }) => {
      state.status_toupwallet = payload;
    },
  },
});

export const walletActions = walletSlice.actions;
export default walletSlice;
