import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const GetVisitStats = createAsyncThunk(
  "sales/GetVisitStats",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/stats/clicks/`, props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetAllCity = createAsyncThunk(
  "sales/GetAllCity",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`city/list/`);
      localStorage.setItem("city", JSON.stringify(data))
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const Visit = createSlice({
  name: "visit",
  initialState: {
    data: null,
    status: "",
    city: JSON.parse(localStorage.getItem("city")) || [],
    status_city: ""
  },
  extraReducers: {
    [GetVisitStats.pending]: (state) => {
      state.status = "pending";
    },
    [GetVisitStats.fulfilled]: (state, actions) => {
      state.status = "success";
      state.data = actions.payload;
    },
    [GetVisitStats.rejected]: (state) => {
      state.status = "rejected";
    },
    [GetAllCity.pending]: (state) => {
      state.status_city = "pending";
    },
    [GetAllCity.fulfilled]: (state, actions) => {
      state.status_city = "success";
      state.city = actions.payload;
    },
    [GetAllCity.rejected]: (state) => {
      state.status_city = "rejected";
    },
  },
});

export const VisitActions = Visit.actions;
export default Visit;
