import React from "react";
import PopUp from "../ui/PopUp";
import { Box } from "@mui/material";
import Button from "../ui/Button";
import styled from "@emotion/styled";
import { useState } from "react";
import DatePickerParams from "../ui/DatePickerParams";
import { format } from "date-fns";

function DatePopover({ anchorEl, onClose, open, applyHandle }) {
  const [dates, setDates] = useState({
    start: "",
    end: "",
  });

  const getDates = (e) => {
    // console.log(e);
    if (e?.length > 0) {
      setDates({
        start: format(e[0]?.startDate, "yyyy-MM-dd"),
        end: format(e[0]?.endDate, "yyyy-MM-dd"),
      });
      // const { name, value } = e.target;
      // setDates((prev) => ({ ...prev, [name]: value }));
    }
  };

  const getValuesHandle = () => {
    applyHandle(dates);
  };

  return (
    <Wrapper>
      <PopUp anchorEl={anchorEl} onClose={onClose} open={open || false}>
        <InnerPopover>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <DatePickerParams onChange={getDates} />
            <Button
              style={{ width: "100%" }}
              className="apply"
              onClick={getValuesHandle}
            >
              Применить
            </Button>
          </Box>
        </InnerPopover>
      </PopUp>
    </Wrapper>
  );
}

export default DatePopover;

const Wrapper = styled("div")``;
const InnerPopover = styled("div")`
  padding: 10px 15px;
  & .apply {
    width: 120px;
    height: 35px;
    margin: 0 auto;
  }
`;
