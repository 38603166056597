import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";
// import { NotificationManager } from "react-notifications";

const initialState = {
  activateuser_status: "",
  createuser_status: "",
  edituser_status: "",
  deleteuser_status: "",
  citiesList: JSON.parse(localStorage.getItem("city")) || [],
  usersList: [],
  user: {},
  getusers_status: "",
  user_categories: [],
  res_createuser: [],
};

export const getUsersList = createAsyncThunk(
  "managers/getUsersList",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/personal/list/search/?${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const getUsersListDealer = createAsyncThunk(
  "managers/getUsersListDealer",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `crm/dealer/list/search/?${props}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getOneUser = createAsyncThunk(
  "crm/getoneUser",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/personal/list/${props}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const getOneDealer = createAsyncThunk(
  "crm/getOneDealer",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/dealer/list/${props}/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const createUser = createAsyncThunk(
  "crmuser/createUser",
  async ({ dataUser, dataCity, avatar, status, dataPriceCity }, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("email", dataUser.email);
      formdata.append("password", dataUser.password);
      formdata.append("username", dataUser.login.replace(/\s/g, ""));
      formdata.append("name", dataUser.name);
      formdata.append("magazine", dataUser.magazine);
      formdata.append("phone", dataUser.phone);
      formdata.append("liability", dataUser.liability);
      formdata.append("status", status);
      if (dataPriceCity) {
        formdata.append("price_city", dataPriceCity);
      }
      if (dataUser.dealer_status) {
        formdata.append("dealer_status", dataUser.dealer_status);
      }
      if (avatar.file) {
        formdata.append("image", avatar.file);
      }
      if (dataCity) {
        formdata.append("city", dataCity);
      }
      const { data } = await ImageUploadingFetch.post(
        "crm/user/cud/",
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "crmuser/updateUser",
  async ({ dataUser, image }, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      if (dataUser.city) {
        formdata.append("city", dataUser.city);
      }
      if (dataUser.dealer_status) {
        formdata.append("dealer_status", dataUser.dealer_status);
      }
      if (dataUser.magazine) {
        formdata.append("magazine", dataUser.magazine);
      }
      formdata.append("email", dataUser.email);
      formdata.append("password", dataUser.pwd);
      formdata.append("username", dataUser.login);
      formdata.append("name", dataUser.name);
      if (dataUser.price_city) {
        formdata.append("price_city", dataUser.price_city);
      }

      if (image[0]?.file) {
        formdata.append("image", image[0]?.file);
      }
      if (image[0] === undefined) {
        formdata.append("image", "");
      }

      formdata.append("phone", dataUser.phone);

      const { data } = await ImageUploadingFetch.put(
        `crm/user/cud/${dataUser.id}/`,
        formdata
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCitiesList = createAsyncThunk(
  "crmuser/getCitiesList",
  async () => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return [];
    }
  }
);

export const deleteuser = createAsyncThunk(
  "crmuser/deleteUser",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`crm/user/cud/${id}/`);

      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const fetchActivateUser = createAsyncThunk(
  "crmuser/fetchActivateUser",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`crm/manager/user/activate/`, { user_id: id });

      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

export const getUserCategoriesList = createAsyncThunk(
  "crmuser/getUserCategoriesList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`dealer-status/list/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const createUserSlice = createSlice({
  name: "crmuser",
  initialState,
  reducers: {
    ChangeStatus: (state) => {
      state.createuser_status = "";
      state.edituser_status = "";
      state.deleteuser_status = "";
      state.activateuser_status = "";
    },
  },
  extraReducers: {
    ///--------------------------------------------
    [deleteuser.pending]: (state) => {
      state.deleteuser_status = "pending";
    },
    ///--------------------------------------------
    [fetchActivateUser.fulfilled]: (state) => {
      state.activateuser_status = "success";
    },
    [deleteuser.fulfilled]: (state) => {
      state.deleteuser_status = "success";
    },
    [deleteuser.rejected]: (state) => {
      state.deleteuser_status = "rejected";
    },

    //--------------------------------------------
    [updateUser.pending]: (state) => {
      state.edituser_status = "pending";
    },
    [updateUser.fulfilled]: (state) => {
      state.edituser_status = "success";
    },
    [updateUser.rejected]: (state, { payload }) => {
      const error = Object.values(payload).flatMap((errorMsgs) => errorMsgs);
      state.res_createuser = error;
      state.edituser_status = "rejected";
    },
    //-----------------------------------------------
    [getOneUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [getOneDealer.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    //-----------------------------------------------
    //-----------------------------------------------
    [getUserCategoriesList.fulfilled]: (state, { payload }) => {
      state.user_categories = payload;
    },
    //-----------------------------------------------

    [getUsersList.pending]: (state) => {
      state.getusers_status = "pending";
    },
    [getUsersList.fulfilled]: (state, { payload }) => {
      state.usersList = payload;
      state.getusers_status = "success";
    },
    [getUsersList.rejected]: (state) => {
      state.getusers_status = "rejected";
    },

    [getUsersListDealer.pending]: (state) => {
      state.getusers_status = "pending"
    },
    [getUsersListDealer.fulfilled]: (state, { payload }) => {
      state.usersList = payload;
      state.getusers_status = "success";
    },
    [getUsersListDealer.rejected]: (state) => {
      state.getusers_status = "reject";
    },

    [createUser.pending]: (state) => {
      state.createuser_status = "pending";
    },
    [createUser.fulfilled]: (state) => {
      state.createuser_status = "success";
    },
    [createUser.rejected]: (state, { payload }) => {
      const error = Object.values(payload).flatMap((errorMsgs) => errorMsgs);
      state.res_createuser = error;

      state.createuser_status = "rejected";
    },
    //-=-=-=-------------------------------------------------
    [getCitiesList.fulfilled]: (state, { payload }) => {
      state.citiesList = payload;
    },
  },
});

export const createUserActions = createUserSlice.actions;
export default createUserSlice;
