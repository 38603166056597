import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import Button from "../ui/Button";
import searchicon from "../../assets/icons/searchIcon.svg";
import SelectSmall from "../ui/SelectSmall";
import { GetSlug } from "../../store/adminStore/DashboardSlice";
import { GetProducts } from "../../store/moderatorStore/ProductSlice";

const ModeratorNavBar = ({ btn }) => {
  const { slug } = useSelector((i) => i?.stats);
  const [value, setvalue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let loop = true;
  useEffect(() => {
    if (loop) {
      dispatch(GetSlug());
    }
    loop = false;
  }, [loop]);

  const category = slug?.map((i) => {
    return {
      id: i?.slug,
      value: i?.slug,
      name: i?.title,
    };
  });

  if (category?.length > 0) {
    category.push({ id: 1, name: "Все", value: "All" });
  }

  const [search, setsearch] = useSearchParams();
  let loopsecond = true;
  useEffect(() => {
    if (loopsecond) {
      dispatch(
        GetProducts(
          `search/?${
            search?.toString() ? search?.toString() : "page=1"
          }&page_size=${btn ? "18" : "20"}`
        )
      );
    }
    loopsecond = false;
  }, [search]);

  const getvalueSelect = (e) => {
    if (e === "All") {
      setsearch({
        page: 1,
      });
      return;
    }
    setsearch({
      category: e,
      page: 1,
    });
  };

  const addcard = () => navigate("/home/products/add");

  const getValueInput = (e) => {
    setvalue(e.target.value);
  };

  const gosearchHandler = (e) => {
    e?.preventDefault();
    setsearch({
      title: value,
      page: 1,
    });
  };

  return (
    <WrapperHeader>
      <WrapperInput onSubmit={gosearchHandler}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <OutlinedInput
            id="outlined-adornment-amount"
            placeholder="Поиск..."
            onChange={getValueInput}
            startAdornment={
              <InputAdornment position="start">
                <img loading="lazy" alt="search_icon" src={searchicon} />
              </InputAdornment>
            }
          />
        </FormControl>
      </WrapperInput>
      <div>
        <WrapperBtnSelect>
          <SelectSmall
            onChange={getvalueSelect}
            placeholder="Категории"
            options={category}
          />
        </WrapperBtnSelect>
        {btn ? "" : <Button onClick={addcard}>+ Добавить продукты</Button>}
      </div>
    </WrapperHeader>
  );
};
export default ModeratorNavBar;

const WrapperHeader = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  & > div {
    display: flex;
    width: 70%;
    gap: 30px;
    align-items: center;
    & > button {
      width: 200px;
      height: 40px;
    }
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    & > div {
      width: 100%;
      & > button {
        font-size: 12px;
      }
    }
  }
`;
const WrapperInput = styled("form")`
  width: 35%;
  height: 40px;
  position: relative;
  & > div {
    margin: 0px;
    input {
      height: 40px;
      padding: 0px;
      padding-left: 5px;
    }
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-top: 15px;
  }
`;
const WrapperBtnSelect = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  & > div {
    width: 30%;
  }
  @media screen and (max-width: 950px) {
    width: 60%;
    & > div {
      width: 60%;
      margin: 0px;
    }
  }
`;
