import { Box, InputLabel, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addRequisite,
  deleteRequisite,
  editRequisite,
  getRequisiteType,
  getRequisitesAll,
  requisiteActions,
} from "../../store/accountant/requisite";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/deleteIcon.svg";
import BasicModal from "../../components/ui/BasicModal";
// import CheckboxLabels from "../../components/ui/Checkbox";
import Button from "../../components/ui/Button";
import { NotificationManager } from "react-notifications";

import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider, createTheme } from "@mui/material";
import styled from "@emotion/styled";

export const InnerRequisite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState({
    modalEdit: false,
    modalDelete: false,
    action: "",
    // data: {},
  });

  const [dataReq, setDataReq] = useState({
    text: "",
    cities: [],
  });

  const {
    requisitetype,
    requisitesAll,
    addRequisite_status,
    deleterequisites_status,
    editRequisite_status,
  } = useSelector((s) => s.requisite);
  const { dealer_st, city } = useSelector((s) => s.loginAuth);
  const { title, logo, text } = requisitetype;

  useEffect(() => {
    dispatch(getRequisiteType(id));
    dispatch(getRequisitesAll());
  }, []);

  useEffect(() => {
    if (addRequisite_status === "success") {
      NotificationManager.success("Успешно добавлено!");
      dispatch(requisiteActions.clearStatus());
      setOpenModal({});
      dispatch(getRequisitesAll());
    }
    if (deleterequisites_status === "success") {
      NotificationManager.success("Успешно удален!");
      dispatch(requisiteActions.clearStatus());
      setOpenModal({});
      dispatch(getRequisitesAll());
    }
    if (editRequisite_status === "success") {
      NotificationManager.success("Успешно редактирован!");
      dispatch(requisiteActions.clearStatus());
      dispatch(getRequisitesAll());
      setOpenModal({});
      setDataReq({});
    }
  }, [addRequisite_status, deleterequisites_status, editRequisite_status]);

  const openEditModalHandle = (e, name) => {
    if (name === "edit") {
      setOpenModal((prev) => ({
        ...prev,
        modalEdit: !prev.modalEdit,
        action: "edit",
      }));
      setDataReq((prev) => ({
        ...prev,
        text: e?.text,
        id: e?.id,
        cities: e.cities?.map((i) => ({ ...i, id: i.city })),
      }));
      setChecked(e.cities);
    } else {
      setOpenModal((prev) => ({
        ...prev,
        action: "",
        modalEdit: !prev.modalEdit,
      }));
    }
    if (openModal.modalEdit) {
      setDataReq({});
    }
  };
  const openDeleteModalHandle = (id) => {
    setOpenModal((prev) => ({
      ...prev,
      modalDelete: !prev.modalDelete,
      deleteId: id,
    }));
  };

  const edit = [
    {
      all: "Все город",
      list: [],
    },
  ];

  const cityList = useMemo(() => {
    return edit?.map((i) => {
      return {
        all: "Все город",
        list: city?.map((id) => {
          return { ...id, id: id?.slug };
        }),
      };
    });
  }, [city]);

  const getUserCategories = (e) => {
    setDataReq((prev) => ({ ...prev, cities: e }));
  };

  const getText = (e) => {
    const value = e.target.value;
    setDataReq((prev) => ({ ...prev, text: value }));
  };
  const [checked, setChecked] = useState([]);

  const addRequisiteHandle = () => {
    dispatch(addRequisite({ ...dataReq, category: id, cities: checked }));
  };
  const editRequisiteHandle = () => {
    dispatch(editRequisite({ ...dataReq, category: id, cities: checked }));
  };
  const deleteRequisiteHandle = () => {
    dispatch(deleteRequisite(openModal.deleteId));
  };

  const handleChangeValue = (e, c) => {
    if (e.target.checked) {
      setChecked([...checked, c]);
    } else {
      setChecked(checked.filter((item) => item?.title !== c?.title));
    }
  };
  const includ = checked?.map((i) => i?.title);
  return (
    <Box>
      <BasicModal open={openModal.modalDelete} onClose={openDeleteModalHandle}>
        <Box sx={{ minWidth: "350px" }}>
          <Typography variant="h6" marginBottom="15px">
            Вы хотите удалить?{" "}
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button onClick={openDeleteModalHandle} variant="outlined">
              Нет
            </Button>
            <Button variant="danger" onClick={deleteRequisiteHandle}>
              Да
            </Button>
          </Box>
        </Box>
      </BasicModal>
      <BasicModal open={openModal.modalEdit} onClose={openEditModalHandle}>
        <Typography variant="h6">
          {openModal.action === "edit" ? "Редактировать" : "Добавить"}
        </Typography>
        <Box>
          <InputLabel>Реквизит</InputLabel>
          <textarea
            style={{ padding: "8px", fontSize: "16px" }}
            onChange={getText}
            value={dataReq?.text}
            rows="4"
            cols="50"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {city?.map((i) => (
              <FormControlLabel
                key={i.title}
                label={i.title}
                checked={includ.includes(i.title)}
                onChange={(e) => handleChangeValue(e, i)}
                control={<Checkbox />}
              />
            ))}
          </Box>
          {/* <CheckboxLabels
            value={dataReq?.cities}
            label={cityList[0].all}
            products={cityList[0].list}
            getChoseData={getUserCategories}
          /> */}
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button variant="outlined" onClick={openEditModalHandle}>
              Отмена
            </Button>
            {openModal.action === "edit" ? (
              <Button onClick={editRequisiteHandle}>Сохранить</Button>
            ) : (
              <Button onClick={addRequisiteHandle}>Добавить</Button>
            )}
          </Box>
        </Box>
      </BasicModal>
      <Typography
        variant="h6"
        sx={{ "&>span": { color: "#575757", cursor: "pointer" } }}
      >
        <span onClick={() => navigate(-1)}>Реквизиты / </span> {title}
      </Typography>
      {requisitesAll?.map((i) =>
        +id === +i.category ? (
          <Box
            key={i.id}
            sx={{
              maxWidth: "600px",
              border: "1px solid #727272",
              padding: "15px",
              margin: "0 0 15px 0",
              borderRadius: "4px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              "& img": {
                width: "30px",
                borderRadius: "50px",
              },
              ":active": {
                border: "1px solid #09234f",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <img src={i.image} alt="" />
              <Typography sx={{ whiteSpace: "pre-wrap" }}>{i.text}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <Edit onClick={() => openEditModalHandle(i, "edit")} />
              <Delete onClick={() => openDeleteModalHandle(i.id)} />
            </Box>
          </Box>
        ) : (
          ""
        )
      )}
      <Button
        onClick={(e) => openEditModalHandle(e, "add")}
        style={{ maxWidth: "300px" }}
      >
        Добавить реквизит
      </Button>
    </Box>
  );
};

function CheckboxLabels({
  getChoseData,
  products,
  label,
  color,
  variant,
  value,
}) {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    getChoseData(checked);
  }, [checked]);

  useEffect(() => {
    setChecked((value) => value);
  }, [value]);

  const checkAll = (e) => {
    if (e.target.checked) {
      const all = products.map((c) => c);
      setChecked(all);
    } else {
      setChecked([]);
    }
  };

  const handleChangeValue = (e, c) => {
    if (e.target.checked) {
      setChecked([...checked, c]);
    } else {
      setChecked(checked.filter((item) => item?.id !== c?.id));
    }
  };
  const them = createTheme({
    palette: {
      primary: {
        main: color || "#0071E3",
      },
    },
  });
  const includ = checked?.map((i) => i?.id);
  return (
    <WrapCheckbox>
      <FormGroup>
        <ThemeProvider theme={them}>
          <FormControlLabel
            onChange={checkAll}
            control={<Checkbox />}
            label={
              <Typography
                component="span"
                style={{
                  display: "block",
                  color: variant === "product" ? "#09234F" : "#242424",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                {label}
              </Typography>
            }
            value={"value1"}
            checked={checked?.length === products?.length}
          />
          <div className="titles">
            {products?.map((i, index) => (
              <FormControlLabel
                key={index}
                label={i.title}
                checked={includ.includes(i?.id)}
                onChange={(e) => handleChangeValue(e, i)}
                control={<Checkbox />}
              />
            ))}
          </div>
        </ThemeProvider>
      </FormGroup>
    </WrapCheckbox>
  );
}
const WrapCheckbox = styled("div")`
  margin: 20px 0;
  & .titles {
    /* display: flex; */
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }
  @media screen and (max-width: 700px) {
    & .titles {
      grid-template-columns: auto;
    }
  }
`;
