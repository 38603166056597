import { Suspense, useEffect } from "react";
import { Outlet } from "react-router";
import UserHeader from "../components/userComponents/UserHeader";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import MobileFooter from "../components/userComponents/MobileFooter";
import { useDispatch } from "react-redux";
import { GetNewChatMessage } from "../store/userStore/UserChat";
import Spinner from "../components/ui/Spinner";

const UserLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(GetNewChatMessage());
  }, [location.pathname]);
  return (
    <>
      <WrapperBack />
      <UserHeader />
      <WrapperContent>
        <div>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      </WrapperContent>
      <WrapperFooterMobile>
        <MobileFooter />
      </WrapperFooterMobile>
    </>
  );
};
export default UserLayout;
const WrapperFooterMobile = styled("div")`
  display: none;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;
const WrapperBack = styled("div")`
  background-color: #f4f5f7;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`;

const WrapperContent = styled("div")`
  width: 100%;
  padding-bottom: 100px;
  & > div {
    width: 90%;
    margin: 0 auto;
    max-width: 1600px;
    padding-top: 50px;
    @media screen and (max-width: 950px) {
      width: 95%;
      margin: 0 auto;
    }
  }
`;
