import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const GetMainManager = createAsyncThunk(
  "chat_user/GetMainManager",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("forgot-pwd/manager/list/");
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const GetMainCity = createAsyncThunk(
  "chat_user/GetMainCity",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("city/list/");
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const GetNewChatMessage = createAsyncThunk(
  "chat_user/GetNewChatMessage",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("chat/");
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
const UserChat = createSlice({
  name: "chat_user",
  initialState: {
    status: "",
    manager: null,
    city: JSON.parse(localStorage.getItem("city")) || [],
    new_messages: null,
  },
  reducers: {},
  extraReducers: {
    [GetNewChatMessage.fulfilled]: (state, actions) => {
      const chat = actions?.payload
      state.new_messages = chat;
    },
    [GetMainManager.pending]: (state) => {
      state.status = "pending";
    },
    [GetMainManager.fulfilled]: (state, { payload }) => {
      state.status = "fulfilled";
      state.manager = payload;
    },
    [GetMainManager.rejected]: (state) => {
      state.status = "rejected";
    },
    [GetMainCity.pending]: (state) => {
      state.status = "pending";
    },
    [GetMainCity.fulfilled]: (state, { payload }) => {
      state.status = "fulfilled";
      state.city = payload;
    },
    [GetMainCity.rejected]: (state) => {
      state.status = "rejected";
    },
  },
});

export const UserChatActions = UserChat.actions;
export default UserChat;
