import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import PopUp from "../ui/PopUp";
import { AuthAction } from "../../store/loginStore/Auth";
import { NavbarButton } from "./NavbarButton";
import { ReactComponent as BurgerMenuIcon } from "../../assets/icons/menu.svg";
import avatarLogo from "../../assets/icons/avatar.svg";
import static_icon from "../../assets/icons/static_icon.svg";
import personIcon from "../../assets/icons/social.svg";
import managerIcon from "../../assets/icons/massage.svg";
import productIcon from "../../assets/icons/product.svg";
import productIcon2 from "../../assets/icons/storeProduct.svg";
import bannerIcon from "../../assets/icons/banners.svg";
import chat_icon from "../../assets/icons/chat_icon.svg";
import manager_stock from "../../assets/icons/manager_stock.svg";
import icon_category from "../../assets/icons/icon_category.svg";
import userCollection from "../../assets/icons/storage.svg";
import icon_abbalance from "../../assets/icons/icon_abbalance.svg";
import visit_icon from "../../assets/icons/visit_icon.svg";
import marketer from "../../assets/icons/marketer.svg";
import icon_ROP from "../../assets/icons/icon_ROP.svg";
import accountant from "../../assets/icons/accountant.svg";
import discount_icon from "../../assets/icons/discount_icon.svg";
import order_icon from "../../assets/icons/order.svg";
import logout_icon from "../../assets/icons/logouticon.svg";

export default function BurgerMenu({
  role,
  status,
  ordersCount,
  balanceCount,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openmobile, setopenmobile] = useState(false);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isEnchorEl, setIsEnchorEl] = useState(null);

  const product_page = () => {
    navigate("/home/product_page");
    setopenmobile(false);
  };
  const home_page = () => {
    navigate("/home/dash_board");
    setopenmobile(false);
  };
  const Moderator_product_page = () => {
    navigate("/home/products");
    setopenmobile(false);
  };
  const Moderator_banner_page = () => {
    navigate("/home/banner");
    setopenmobile(false);
  };
  const Moderator_category_page = () => {
    navigate("/home/category");
    setopenmobile(false);
  };
  const Moderator_collection_page = () => {
    navigate("/home/collection");
    setopenmobile(false);
  };
  const polzovateli = () => {
    navigate("/home/users");
    setopenmobile(false);
  };
  const chat = () => {
    navigate("/home/chat");
    setopenmobile(false);
  };
  const zakaz = () => {
    navigate("/home/zakaz");
    setopenmobile(false);
  };
  const toDiscount = () => {
    navigate("/home/discount");
    setopenmobile(false);
  };
  const tomanager_stock = () => {
    navigate("/home/warehouse");
    setopenmobile(false);
  };
  const balance = () => {
    navigate("/home/balance");
    setopenmobile(false);
  };
  const toVisiting = () => {
    navigate("/home/visiting");
    setopenmobile(false);
  };
  const manager_page = () => {
    navigate("/home/managers");
    setopenmobile(false);
  };
  const rop_page = () => {
    navigate("/home/rop");
    setopenmobile(false);
  };
  const product_rop = () => {
    navigate("/home/product_rop");
    setopenmobile(false);
  };
  const accountant_page = () => {
    navigate("/home/accountant");
    setopenmobile(false);
  };
  const tomarketer = () => {
    navigate("/home/marketer");
    setopenmobile(false);
  };
  const profileHandler = () => {
    navigate("/home/profile");
    setopenmobile(false);
  };
  const returnOrder = () => {
    navigate("/home/order_mod");
    setopenmobile(false);
  };

  const active = pathname.split("/")[2];
  const openOrCloseMobile = () => setopenmobile(!openmobile);
  const isOpenPopUpLogOutHandler = (e) => {
    setIsEnchorEl(e.currentTarget);
    setIsOpenPopUp(!isOpenPopUp);
  };
  const not_logout = () => {
    setIsEnchorEl(null);
    setIsOpenPopUp(!isOpenPopUp);
    setopenmobile(false);
  };
  const logoutHandler = () => {
    dispatch(AuthAction.Logout());
  };
  return (
    <WrapBurgerMenu>
      <div>
        <Button onClick={openOrCloseMobile}>
          <BurgerMenuIcon />
        </Button>
        <WrapperMobile open={openmobile}>
          {openmobile && <div className="back" onClick={openOrCloseMobile} />}
          <div className="mobile">
            <WrapperBox>
              <h3>{status}</h3>
              <div>
                <NavbarButton
                  onClick={profileHandler}
                  text="Профиль"
                  active={active === "profile" ? "active" : ""}
                  img={avatarLogo}
                />
                {role !== "accountant" && role !== "warehouse" && (
                  <NavbarButton
                    onClick={home_page}
                    active={active === "dash_board" ? "active" : ""}
                    text="Статистика"
                    img={static_icon}
                  />
                )}
                {role === "marketer" && (
                  <>
                    <NavbarButton
                      onClick={Moderator_product_page}
                      active={active === "products" ? "active" : ""}
                      text="Продукты"
                      img={productIcon2}
                    />
                    <NavbarButton
                      onClick={Moderator_banner_page}
                      active={active === "banner" ? "active" : ""}
                      text="Баннер"
                      img={bannerIcon}
                    />
                    <NavbarButton
                      onClick={toDiscount}
                      active={active === "discount" ? "active" : ""}
                      text="Акция"
                      img={discount_icon}
                    />

                    <NavbarButton
                      onClick={Moderator_category_page}
                      text="Категории"
                      img={icon_category}
                      active={active === "category" ? "active" : ""}
                    />
                    <NavbarButton
                      onClick={Moderator_collection_page}
                      active={active === "collection" ? "active" : ""}
                      text="Коллекции"
                      img={userCollection}
                    />
                  </>
                )}

                {role === "main_manager" && (
                  <NavbarButton
                    onClick={manager_page}
                    active={active === "managers" ? "active" : ""}
                    text="Менеджеры"
                    img={managerIcon}
                  />
                )}

                {role === "director" && (
                  <>
                    <NavbarButton
                      onClick={tomarketer}
                      active={active === "marketer" ? "active" : ""}
                      text="Маркетолог"
                      img={marketer}
                    />
                    <NavbarButton
                      onClick={rop_page}
                      active={active === "rop" ? "active" : ""}
                      text="РОП"
                      img={icon_ROP}
                    />
                    <NavbarButton
                      onClick={accountant_page}
                      active={active === "accountant" ? "active" : ""}
                      text="Бухгалтер"
                      img={accountant}
                    />
                  </>
                )}
                {(role === "manager" || role === "main_manager") && (
                  <NavbarButton
                    onClick={polzovateli}
                    active={active === "users" ? "active" : ""}
                    text="Пользователи"
                    img={personIcon}
                  />
                )}
                {role === "manager" && (
                  <NavbarButton
                    onClick={tomanager_stock}
                    active={active === "warehouse" ? "active" : ""}
                    text="Зав. склад"
                    img={manager_stock}
                  />
                )}
                {role === "main_manager" && (
                  <NavbarButton
                    onClick={product_rop}
                    active={active === "product_rop" ? "active" : ""}
                    text="Товары"
                    img={productIcon}
                  />
                )}
                {role === "manager" && (
                  <NavbarButton
                    onClick={chat}
                    active={active === "chat" ? "active" : ""}
                    text="Сообщения"
                    img={chat_icon}
                  />
                )}
                {(role === "accountant" || role === "warehouse") && (
                  <NavbarButton
                    onClick={zakaz}
                    active={active === "zakaz" ? "active" : ""}
                    text="Заказы"
                    img={order_icon}
                    count={ordersCount}
                  />
                )}
                {role !== "marketer" && role !== "main_manager" && (
                  <NavbarButton
                    onClick={product_page}
                    active={active === "product_page" ? "active" : ""}
                    text="Товары"
                    img={productIcon}
                  />
                )}
                {role !== "manager" &&
                  role !== "accountant" &&
                  role !== "warehouse" && (
                    <NavbarButton
                      active={active === "visiting" ? "active" : ""}
                      text="Посещаемость"
                      onClick={toVisiting}
                      img={visit_icon}
                    />
                  )}

                {role !== "warehouse" && (
                  <NavbarButton
                    active={active === "balance" ? "active" : ""}
                    text="Баланс"
                    img={icon_abbalance}
                    onClick={balance}
                    count={balanceCount}
                  />
                )}
                {(role === "accountant" || role === "manager") && (
                  <NavbarButton
                    onClick={returnOrder}
                    active={active === "order_mod" ? "active" : ""}
                    text="Возврат"
                    img={order_icon}
                    count={0}
                  />
                )}
                <NavbarButton
                  img={logout_icon}
                  text="Выйти"
                  onClick={isOpenPopUpLogOutHandler}
                />
                {isOpenPopUp && (
                  <PopUp
                    anchorEl={isEnchorEl}
                    open={isOpenPopUp}
                    onClose={isOpenPopUpLogOutHandler}
                  >
                    <InnerLogoutPopUp>
                      <h4>Вы хотите выйти?</h4>
                      <div>
                        <Button onClick={not_logout} variant={"outlined"}>
                          Нет
                        </Button>
                        <Button onClick={logoutHandler} variant="contained">
                          Выйти
                        </Button>
                      </div>
                    </InnerLogoutPopUp>
                  </PopUp>
                )}
              </div>
            </WrapperBox>
          </div>
        </WrapperMobile>
      </div>
    </WrapBurgerMenu>
  );
}
const InnerLogoutPopUp = styled("div")`
  padding: 10px;
  & > h4 {
    margin-bottom: 8px;
    font-size: 14px;
  }
  & > div {
    display: flex;
  }
  & > div > button {
    font-size: 12px;
    height: 30px;
    border-radius: 4;
  }
  & > div > button:first-of-type {
    margin-right: 4px;
  }
  & > div > button:last-of-type {
    margin-left: 4px;
  }
`;
const WrapperMobile = styled("div")`
  position: fixed;
  right: 0px;
  top: 0px;
  width: ${(props) => (props?.open ? "100%" : "0px")};
  height: ${(props) => (props?.open ? "100%" : "0px")};
  z-index: 5000;
  .back {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #807f7f69;
    z-index: 5500;
  }
  .mobile {
    position: fixed;
    z-index: 5550;
    right: 0px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    transition-duration: 1s;
    padding: ${(props) => (props?.open ? "10px" : "0px")};
    width: ${(props) => (props?.open ? "65%" : "30%")};
    transform: ${(props) =>
      props?.open ? "translateX(0px)" : "translateX(130%)"};
  }
`;
const WrapBurgerMenu = styled("div")`
  display: none;
  @media screen and (max-width: 950px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    & > svg {
      width: 150px;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
`;

const WrapperBox = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  width: 250px;
  & > h3 {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #09234f;
  }
  & > div {
    border-radius: 8px;
  }
`;
