import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import styled from "@emotion/styled";

function Accordions(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content?.current?.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <StyledDiv className="accordion__section">
      <StyledA className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div className="div">
          <img src={props?.img} alt="foto"/>
          <p className="accordion__title">{props?.title}</p>
        </div>
        <Chevron className={`${setRotate}`} width={6} fill={"#777"}  />
      </StyledA>
      <WrapperDiv
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <span className="accordion__text">{props?.content}</span>
      </WrapperDiv>
    </StyledDiv>
  );
}

export default Accordions;

const StyledDiv = styled("div")`
  background-color: transparent;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .accordion__icon {
    margin-left: 10px;
    transition: transform 0.6s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }
  .div{
    display: flex;
    align-items: center;
    p{
      margin-left: 10px;
    }
  }
`;

const StyledA = styled("button")`
 
  cursor: pointer;
  background: transparent;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 1px;

    color: #1d1d1d;
  };

  
`;

const WrapperDiv = styled("div")`
  background-color: transparent;
  overflow: hidden;
  transition: max-height 1s ease;
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 15px;
  }
 
`;

