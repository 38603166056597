import styled from "@emotion/styled";
import { Menu } from "@mui/material";

function PopUp({ children, open, onClose, anchorEl, ...props }) {
  return (
    <MenuPopUp open={open} onClose={onClose} anchorEl={anchorEl} {...props}>
      {children}
    </MenuPopUp>
  );
}

export default PopUp;

const MenuPopUp = styled(Menu)`
  height: ${(props) => (props.height ? props.height : "")};
  & .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }
`;
