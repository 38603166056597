import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import styled from "@emotion/styled";
import ImagePicker from "../../components/ui/ImagePicker";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import {
  ToUpWalletSlice,
  getRequisites,
  walletActions,
} from "../../store/userStore/wallet";
import { Box, Typography } from "@mui/material";

export default function ToUpWalletUsers() {
  const data_user = JSON.parse(localStorage.getItem("to-up-balance-user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status_toupwallet } = useSelector((s) => s.wallet);
  const [sum, setSum] = useState();
  const [check, setCheck] = useState([]);
  const { id } = useParams();

  const sendSumHandler = () => {
    const repliced = sum.replace(/[^0-9]/g, "");
    if (repliced && check?.length > 0) {
      dispatch(ToUpWalletSlice({ repliced, check, user: id }));
    }
  };

  const getSum = (e) => {
    const num = e.target.value;
    // Remove any existing commas and non-digit characters from the input
    const sanitizedValue = num.replace(/[^0-9]/g, "");

    // Use regular expressions to insert commas after every three digits
    const formattedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    setSum(formattedValue);
  };
  useEffect(() => {
    if (status_toupwallet.status === "OK") {
      NotificationManager.success("Заявка успешно отрпавлено!");
      navigate(-1);
      dispatch(walletActions.changeStatusToupWallet());
    }
  }, [status_toupwallet]);

  // ----------------------new addition-------------------------
  const [method, setMethod] = useState("Карта");
  const { requisites } = useSelector((s) => s.wallet);

  useEffect(() => {
    dispatch(getRequisites(method));
  }, [method]);

  console.log(data_user);
  return (
    <>
      <Typography>{data_user.name}</Typography>
      <Wrapper>
        <div className="toUpData">
          <div>
            <h3>Пополнить кошелек</h3>

            <h4>Наш реквизиты</h4>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                "&>div": { color: "#626262", cursor: "pointer" },
                ".active": {
                  color: "#34b53a",
                  borderBottom: "1px solid #34b53a",
                },
              }}
            >
              <div
                className={method === "Карта" && "active"}
                onClick={() => setMethod("Карта")}
              >
                Карта
              </div>
              <div
                className={method === "Каспи" && "active"}
                onClick={() => setMethod("Каспи")}
              >
                Каспи
              </div>
            </Box>
            <Box
              sx={{ whiteSpace: "pre-wrap", marginTop: "15px" }}
              variant="span"
              className="requisite"
            >
              {requisites?.text}
            </Box>
            {/* <span className="requisite">
            <p>Visa (optima):</p>
            <CopyText text="5852 8565 4545 0215" />
          </span> */}
          </div>
          <div className="send_sum">
            <div className="image_picker">
              <ImagePicker
                allPhotos={check}
                getPhoto={setCheck}
                maxphotos={1}
              />
            </div>
            <Input onChange={getSum} value={sum} label="Сумма" type="text" />
          </div>
        </div>
        <div className="send_btn">
          <Button variant={"userbtn"} onClick={sendSumHandler}>
            Пополнить
          </Button>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled("div")`
  max-width: 830px;
  height: 600px;
  margin: 25px auto 0 auto;
  background: #fff;
  border-radius: 6px;
  padding: 40px;
  & .requisite {
    color: #4a4a4a;
    margin-bottom: 15px;
    span {
      padding: 0;
      p {
        color: black;
      }
    }
  }
  & .toUpData {
    display: flex;
    justify-content: space-around;
    width: 100%;
    & > div {
      width: 100%;
    }
  }

  & > div {
    h3 {
      font-size: 18px;
      color: #242424;
    }
    h4 {
      margin: 10px 0;
      font-size: 15px;
      color: #34b53a;
    }
  }
  & .image_picker {
    width: 135px;
    height: 135px;
  }

  & .send_btn {
    width: 270px;
    margin: 100px auto 50px auto;
  }

  @media screen and (max-width: 700px) {
    padding: 15px;
    & .toUpData {
      display: block;
    }
    & .send_sum {
      margin: 50px auto;
    }
    & .image_picker {
      margin: 0 auto;
    }
  }
`;
