import { configureStore } from "@reduxjs/toolkit";
import CatalogSlice from "./moderatorStore/CatalogSlice";
import CollectionSlice from "./moderatorStore/CollectionSlice";
import Auth from "./loginStore/Auth";
import UserSlice from "./userStore/UserSlice";
import OrderProduct from "./userStore/OrderProduct";
import createUserSlice from "./managerStore/users";
import createManagerSlice from "./adminStore/managers";
import ordersSlice from "./managerStore/orders";
import profileSlice from "./managerStore/profile";
import productSlice from "./adminStore/products";
import BannerSlice from "./moderatorStore/BannerSlice";
import ProductSlice from "./moderatorStore/ProductSlice";
import DashboardSlice from "./adminStore/DashboardSlice";
import AccountingSlice from "./adminStore/accounting";
import SalesSlice from "./adminStore/SalesSlice";
import walletSlice from "./userStore/wallet";
import BalancesSlice from "./accountant/balances";
import Visit from "./adminStore/Visit";
import Chat from "./managerStore/Chat";
import UserChat from "./userStore/UserChat";
import productropSlice from "./ROPStore/product_rop";
import historysaleSlice from "./userStore/historysale";
import discountSlice from "./moderatorStore/discount";
import Stock from "./managerStore/Stock";
import OrderModeration from "./adminStore/OrderModeration";
import CreateOrder from "./managerStore/CreateOrder";
import reportSlice from "./warehouse/report";
import requisiteSlice from "./accountant/requisite";
import dealerStatusSlice from "./moderatorStore/dealerstatusCRUD";
import managerOrderSlice from "./managerStore/managerOrders";
import didntorderSlice from "./managerStore/didntOrderUser";

const store = configureStore({
  reducer: {
    loginAuth: Auth.reducer,
    userInfo: UserSlice.reducer,
    order: OrderProduct.reducer,
    crmuser: createUserSlice.reducer,
    managers: createManagerSlice.reducer,
    orders: ordersSlice.reducer,
    profile: profileSlice.reducer,
    products: productSlice.reducer,
    banner: BannerSlice.reducer,
    product: ProductSlice.reducer,
    stats: DashboardSlice.reducer,
    accounting: AccountingSlice.reducer,
    collection: CollectionSlice.reducer,
    catalog: CatalogSlice.reducer,
    sales: SalesSlice.reducer,
    wallet: walletSlice.reducer,
    balances: BalancesSlice.reducer,
    visit: Visit.reducer,
    chat: Chat.reducer,
    user_chat: UserChat.reducer,
    productrop: productropSlice.reducer,
    historysale: historysaleSlice.reducer,
    discount: discountSlice.reducer,
    stock: Stock.reducer,
    order_mod: OrderModeration.reducer,
    createOrder: CreateOrder.reducer,
    report: reportSlice.reducer,
    requisite: requisiteSlice.reducer,
    dealerSt: dealerStatusSlice.reducer,
    managerorder: managerOrderSlice.reducer,
    didntorder: didntorderSlice.reducer,
  },
});
export default store;
