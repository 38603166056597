import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getOrdersByDate = createAsyncThunk(
    "report/getOrdersByDate",
    async (props, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                `crm/prod-list/order/warehouse/`,
                props
            );
            return data;
        } catch (error) {
            return rejectWithValue(error?.detail);
        }
    }
);



const reportSlice = createSlice({
    name: "report",
    initialState: {
        orders: [],
        status_orders: ""
    },
    reducers: {
        clearStatus: (state) => {
            state.status_orders = ""

        },
    },
    extraReducers: {
        //------------------------------------------------------------------
        [getOrdersByDate.pending]: (state) => {
            state.status_orders = "pending";
        },
        [getOrdersByDate.fulfilled]: (state, { payload }) => {
            state.orders = payload;
            state.status_orders = "success";
        },
        [getOrdersByDate.rejected]: (state) => {
            state.status_orders = "payload";
        },
    },
});

export const reportActions = reportSlice.actions;
export default reportSlice;
