import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Spinner from "../components/ui/Spinner";
import ProtectedRoute from "./ProtectedRoute";
import { DetailCityCardProduct } from "../components/adminComponent/SkladProduct";
import UserLayout from "../layout/UserLayout";
import MainLayout from "../layout/MainLayout";
import CreatOrder from "../pages/managerPages/CreatOrder";
import CategoryUser from "../pages/moderatorPages/CategoryUser";
import ReportPage from "../pages/WareHousePage/ReportPage";
import RequisitePage from "../pages/buhgalter/RequisitePage";
import { InnerRequisite } from "../pages/buhgalter/InnerRequisite";
import ToUpWalletUsers from "../pages/managerPages/ToUpWalletUsers";
import ManagerOrders from "../pages/managerPages/ManagerOrders";
import InnerPageManagerOrder from "../pages/managerPages/InnerPageManagerOrder";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import DidntOrderUsers from "../pages/managerPages/DidntOrderUsers";
import DidntOrderOneUser from "../pages/managerPages/DidntOrderOneUser";

const lazyRefreshed = (componentImport) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("lazy_refreshed") || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("lazy_refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem("lazy_refreshed", "true");
          return window.location.reload();
        }
        reject(error);
      });
  });
};
const UserLists = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/UserLists"))
);
const MessagePage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/MessagePage"))
);
const HistorySaleLayout = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/HistorySaleLayout"))
);
const Process = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/Process"))
);
const Issued = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/Issued"))
);
const Deposits = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/Deposits"))
);
const Completed = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/Completed"))
);
const Denied = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/Denied"))
);
const DetailPageHistory = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/history/DetailPageHistory"))
);
const ABbapancePage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/ABbalancePage"))
);
const Chat = lazy(() =>
  lazyRefreshed(() => import("../pages/managerPages/Chat"))
);
const ProductDashboard = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/ProductDashboard"))
);
const ProductDashboardUser = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/ProductDashboardUser"))
);
const NotFoundPage = lazy(() =>
  lazyRefreshed(() => import("../layout/NotFoundPage"))
);

// main page
const MainPage = lazy(() => lazyRefreshed(() => import("../layout/MainPage")));

const ProductPage = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/ProductPage"))
);
const OutletLayout = lazy(() =>
  lazyRefreshed(() => import("../layout/OutletLayout"))
);
const DashboardPageLayout = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/DashboardPageLayout"))
);

// page admin

const DetailPageAKB = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/DetailPageAKB"))
);
const BannerDetailPage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/BannerDetailPage"))
);
const OrderMod = lazy(() =>
  lazyRefreshed(() => import("../pages/buhgalter/OrderMod"))
);
const Category = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/category/Category")
  )
);
const Collection = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/collection/Collection")
  )
);
const ProfilePage = lazy(() =>
  lazyRefreshed(() => import("../layout/ProfilePage"))
);
const SkladProduct = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/SkladProduct"))
);
const SkladByCityProduct = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/SkladByCityProduct"))
);
const ModeratorAllProduct = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/ModeratorAllProduct")
  )
);
const ModeratorDetailProduct = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/ModeratorDetailProduct")
  )
);
const EditCard = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/moderator-edit/EditCard")
  )
);
const BannerDetail = lazy(() =>
  lazyRefreshed(() =>
    import("../components/moderatorComponents/moderator-banner/BannerDetail")
  )
);
const EditUser = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/EditUser"))
);
const UserProfile = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/UserProfile"))
);
const DashboardPage = lazy(() =>
  lazyRefreshed(() => import("../pages/adminPages/DashboardPage"))
);

const InnerpageZakaz = lazy(() =>
  lazyRefreshed(() => import("../components/managerComponents/InnerpageZakaz"))
);
const Orders = lazy(() =>
  lazyRefreshed(() => import("../pages/managerPages/Orders"))
);

const CreateUser = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/CreateUser"))
);
const ABBalance = lazy(() =>
  lazyRefreshed(() => import("../pages/buhgalter/AB_Balance"))
);
const InnerPageBalance = lazy(() =>
  lazyRefreshed(() => import("../pages/buhgalter/InnerPageBalance"))
);
const InnerPageProducts = lazy(() =>
  lazyRefreshed(() => import("../components/ROPCompanents/InnerPageProducts"))
);
const OrderModDetail = lazy(() =>
  lazyRefreshed(() => import("../pages/buhgalter/OrderModDetail"))
);
///--------------------------discount ---------------------------------
const Discount = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/DIscount"))
);
const AddDiscount = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/AddDiscount"))
);
const InnerDiscount = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/InnerDiscount"))
);

//-ROP pages
const ProductROP = lazy(() =>
  lazyRefreshed(() => import("../pages/ROPPages/ProductROP"))
);

//--banner
const BannerPage = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/BannerPage"))
);
const AddBanner = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/AddBanner"))
);

const VisitingPage = lazy(() =>
  lazyRefreshed(() => import("../pages/moderatorPages/VisitingPage"))
);
const InnerCategories = lazy(() =>
  lazyRefreshed(() => import("../components/adminComponent/InnerCategories"))
);

// ------------------------------------------------------------------------------------

// page user
const CatalogPage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/Catalogpage"))
);
const StockPage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/StockPage"))
);
const UserMainPage = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/UserMainPage"))
);
const InnerPageCatalogs = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/InnerPageCatalogs"))
);
const Korzina = lazy(() =>
  lazyRefreshed(() => import("../components/userComponents/korzina/Korzina"))
);
const ChoseMethod = lazy(() =>
  lazyRefreshed(() =>
    import("../components/userComponents/korzina/ChoseMethod")
  )
);
const Ordering = lazy(() =>
  lazyRefreshed(() => import("../components/userComponents/korzina/Ordering"))
);
const Successfully = lazy(() =>
  lazyRefreshed(() =>
    import("../components/userComponents/korzina/Successfully")
  )
);
const Personal = lazy(() =>
  lazyRefreshed(() => import("../components/userComponents/personal/Personal"))
);

//balance
const ToUpWallet = lazy(() =>
  lazyRefreshed(() => import("../pages/userpages/ToUpWallet"))
);

const UserRoute = () => {
  return (
    <Routes>
      <Route exact path="/" element={<UserLayout />}>
        <Route exact index element={<UserMainPage />} />
        <Route exact path="message" element={<MessagePage />} />
        <Route exact path="catalog" element={<CatalogPage />} />
        <Route exact path="catalog/:title" element={<CatalogPage />} />
        <Route
          exact
          path="catalog/:title/:id"
          element={<InnerPageCatalogs />}
        />
        <Route exact path="historysale" element={<HistorySaleLayout />}>
          <Route index element={<Navigate to="in_process" replace />} />
          <Route exact path="in_process" element={<Process />} />
          <Route exact path="issued" element={<Issued />} />
          <Route exact path="deposits" element={<Deposits />} />
          <Route exact path="completed" element={<Completed />} />
          <Route exact path="denied" element={<Denied />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="balance" element={<ABbapancePage />} />
        <Route exact path="balance/toup" element={<ToUpWallet />} />
        <Route
          exact
          path="orders/:status/:id"
          element={<DetailPageHistory />}
        />
        <Route exact path="stock" element={<StockPage />} />
        <Route exact path="korzina" element={<Korzina />} />
        <Route exact path="korzina/chose" element={<ChoseMethod />} />
        <Route exact path="korzina/chose/ordering" element={<Ordering />} />
        <Route exact path="korzina/order/succes" element={<Successfully />} />
        <Route exact path="profile" element={<Personal />} />
        <Route exact path="banner/:id" element={<BannerDetailPage />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
};

const AdminRoute = ({ role }) => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {(role !== "accountant" || role !== "zavsklad") && (
          <Route path="home" element={<Navigate to="/home/dash_board" />} />
        )}
        <Route path="order_mod" element={<OrderMod />} />
        <Route path="order_mod/:id" element={<OrderModDetail />} />
        <Route exact path="profile" element={<ProfilePage />} />
        <Route exact path="zakaz" element={<OutletLayout />}>
          <Route exact path="" element={<Navigate to="order" />} />
          <Route exact path="order" element={<Orders />} />
          <Route exact path="order/:id" element={<InnerpageZakaz />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="zakaz_manager" element={<OutletLayout />} >
          <Route exact path="" element={<ManagerOrders />} />
          <Route exact path=":id" element={<InnerPageManagerOrder />} />
        </Route>
        <Route exact path="product_page" element={<ProductPage />}>
          <Route index element={<SkladProduct />} />
          <Route exact path=":id" element={<InnerCategories />} />
          <Route exact path=":id/:inner" element={<InnerPageProducts />} />
          <Route exact path="city" element={<SkladByCityProduct />} />
          <Route exact path="city/:id" element={<DetailCityCardProduct />} />
          <Route exact path="city/:id/:id" element={<InnerCategories />} />
          <Route
            exact
            path="city/:id/:id/:inner"
            element={<InnerPageProducts />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="product_rop" element={<OutletLayout />}>
          <Route exact path="" element={<ProductROP />} />
          <Route exact path="products/:id" element={<InnerPageProducts />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="products" element={<OutletLayout />}>
          <Route exact path="" element={<ModeratorAllProduct />} />
          <Route exact path=":id" element={<ModeratorDetailProduct />} />
          <Route exact path="edit/:id" element={<EditCard />} />
          <Route exact path="add" element={<EditCard />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="category" element={<OutletLayout />}>
          <Route exact path="" element={<Category />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="collection" element={<OutletLayout />}>
          <Route exact path="" element={<Collection />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="banner" element={<OutletLayout />}>
          <Route exact path="" element={<BannerPage />} />
          <Route exact path=":id" element={<BannerDetail />} />
          <Route exact path="edit/:id" element={<AddBanner type={true} />} />
          <Route exact path="add" element={<AddBanner />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="users" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="dealer" />} />
          <Route exact path="create" element={<CreateUser status="dealer" />} />
          <Route exact path="createorder/:id" element={<CreatOrder />} />
          <Route exact path="toup-wallet/:id" element={<ToUpWalletUsers />} />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="dealer" />}
          />
          <Route exact path=":id" element={<UserProfile status="dealer" />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="rop" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="main_manager" />} />
          <Route
            exact
            path="create"
            element={<CreateUser status="main_manager" />}
          />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="main_manager" />}
          />
          <Route
            exact
            path=":id"
            element={<UserProfile status="main_manager" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="accountant" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="accountant" />} />
          <Route
            exact
            path="create"
            element={<CreateUser status="accountant" />}
          />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="accountant" />}
          />
          <Route
            exact
            path=":id"
            element={<UserProfile status="accountant" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="managers" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="manager" />} />
          <Route
            exact
            path="create"
            element={<CreateUser status="manager" />}
          />
          <Route exact path=":id" element={<UserProfile status="manager" />} />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="manager" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="warehouse" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="warehouse" />} />
          <Route
            exact
            path="create"
            element={<CreateUser status="warehouse" />}
          />
          <Route
            exact
            path=":id"
            element={<UserProfile status="warehouse" />}
          />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="warehouse" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="marketer" element={<OutletLayout />}>
          <Route exact path="" element={<UserLists status="marketer" />} />
          <Route
            exact
            path="create"
            element={<CreateUser status="marketer" />}
          />
          <Route exact path=":id" element={<UserProfile status="marketer" />} />
          <Route
            exact
            path="update/:id"
            element={<EditUser status="accountant" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        {(role !== "accountant" || role !== "zavsklad") && (
          <Route exact path="dash_board" element={<DashboardPageLayout />}>
            <Route exact path="" element={<DashboardPage />} />
            <Route exact path=":title" element={<DetailPageAKB />} />
            <Route
              exact
              path="product/:date/:slug"
              element={<ProductDashboard />}
            />
            <Route
              exact
              path="product/:date/:slug/:id"
              element={<ProductDashboardUser />}
            />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        )}

        <Route exact path="balance" element={<OutletLayout />}>
          <Route exact path="" element={<ABBalance />} />
          <Route exact path=":id" element={<InnerPageBalance />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="chat" element={<OutletLayout />}>
          <Route exact path="" element={<Chat />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="discount" element={<OutletLayout />}>
          <Route exact path="" element={<Discount />} />
          <Route exact path=":id" element={<InnerDiscount />} />
          <Route exact path="add" element={<AddDiscount />} />
          <Route
            exact
            path="edit/:id"
            element={<AddDiscount type="update" />}
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route exact path="visiting" element={<OutletLayout />}>
          <Route exact path="" element={<VisitingPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>

        {/*     --------new additions ---------------------- */}
        <Route path="report" element={<ReportPage />} />
        <Route path="requisite" element={<Outlet />}>
          <Route path="" element={<RequisitePage />} />
          <Route path=":id" element={<InnerRequisite />} />
        </Route>
        
        <Route path="not_ordering" element={<Outlet />}>
          <Route path="" element={<DidntOrderUsers />} />
          <Route path=":id" element={<DidntOrderOneUser />} />
        </Route>
        <Route path="cat_user" element={<CategoryUser />} />


        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
};

const AllRoute = () => {
  const { role } = useSelector((i) => i?.loginAuth);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Spinner />}>
            <MainPage />
          </Suspense>
        }
      />
      <Route
        path="/home/*"
        element={
          <ProtectedRoute
            navigate="/"
            bool={
              role === "director" ||
              role === "marketer" ||
              role === "manager" ||
              role === "main_manager" ||
              role === "accountant" ||
              role === "warehouse"
            }
          >
            <AdminRoute role={role} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user/*"
        element={
          <ProtectedRoute navigate="/" bool={role === "dealer"}>
            <UserRoute />
          </ProtectedRoute>
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  );
};
export default AllRoute;
