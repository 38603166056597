import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { getProductSlug } from "../../store/adminStore/products";
import { formatNum } from "../../utils/helpers";

const CardProduct = ({ data, city }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role") || "";
  const goDetailNavigate = () => {
    localStorage.setItem("category_title", data?.title);
    if (city) {
      navigate(`${data?.slug}`);
    } else {
      if (role !== "manager" && role !== "warehouse") {
        dispatch(getProductSlug(data?.slug));
      }
      navigate(`/home/product_page/${data?.slug || 1}`);
    }
  };

  return (
    <WrapperBox onClick={goDetailNavigate}>
      <img alt="image_product" src={data?.image} />
      <div>
        <p>{data?.title || "test"}</p>
        <div>
          <p>Количество в 1С:</p>
          <p className="text">{formatNum(data?.count_1c) || 0} штук</p>
        </div>
        <div>
          <p>Количество в CRM:</p>
          <p className="text">{formatNum(data?.crm_count) || 0} штук</p>
        </div>
        <div>
          <p>Оплачено:</p>
          <p className="text">{formatNum(data?.order_count) || 0} штук</p>
        </div>
      </div>
    </WrapperBox>
  );
};
export default CardProduct;

const WrapperBox = styled("div")`
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(138, 149, 158, 0.23);
  border-radius: 8px;
  width: 100%;
  height: 120px;
  padding: 11px;
  display: flex;
  gap: 68px;
  cursor: pointer;
  align-items: center;
  .text {
    color: #787878;
  }
  & > img {
    width: 80px;
    height: 50px;
    object-fit: contain;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > p {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
    & > div {
      display: flex;
      gap: 10px;
      & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 15px;
        color: #000000;
      }
    }
  }
  @media screen and (max-width: 750px) {
    gap: 20px;
  }
`;
