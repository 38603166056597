import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";


const BasicModal = ({ onClose, open, children, addstyle, height }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: 24,
    height: `${height}`,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };
  const modal = {
    overflow: "scroll",
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      style={modal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, ...addstyle }}>{children}</Box>
      </Fade>
    </Modal>
  );
};
export default BasicModal;
