import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/user/me-info/`);
      localStorage.setItem("profile", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const getReviews = createAsyncThunk(
  "profile/getReviews",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("crm/review/");
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const readedReview = createAsyncThunk(
  "profile/readedReview",
  async (i, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.put(`crm/review/${i.id}/`, {
        status: true,
        text: i.text,
        product: i.product,
      });
      dispatch(getReviews());
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const deleteReview = createAsyncThunk(
  "profile/deleteReview",
  async (i, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.delete(`crm/review/${i.id}/`);
      dispatch(getReviews());
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
const initialState = {
  profile: localStorage.getItem("profile") || {},
  reviews: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: {
    [getProfile.fulfilled]: (state, { payload }) => {
      state.profile = payload;
    },
    /////-------------------------------------------
    [getReviews.fulfilled]: (state, { payload }) => {
      state.reviews = payload;
    },

    ///--------------------------------------------
    [readedReview.fulfilled]: (state) => {
      // state.reviews = payload;
    },
    //----------------------------------------------------
  },
});

export const profileActions = profileSlice.actions;
export default profileSlice;
