import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";

function PrivacyPolicy() {
  return (
    <Wrapper>
      <Box sx={{ background: "#dadada", padding: "3% 0" }}>
        <Box
          sx={{
            margin: "0 auto",
            width: "90%",
            background: "#fff",
            padding: "5%",
          }}
        >
          <h4 className="mr-25"> Политика конфиденциальности</h4> <br /> Настоящая Политика
          конфиденциальности персональных данных (далее – Политика
          конфиденциальности) действует в отношении всей информации, которую
          мобильное приложение, расположенное на платформах Google Play и App
          Store, может получить о Пользователе во время использования мобильного
          приложения, а также сайта.
          <h5 className="mr-25">1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h5>
          1.1 В настоящей Политике конфиденциальности используются следующие
          термины:
          <p className="mr-25">
            1.1.1. «Администрация мобильного приложения и сайта» –
            уполномоченные сотрудники на управление мобильным приложением и
            сайтом, действующие от имени организации, которые организуют и (или)
            осуществляет обработку персональных данных, а также определяет цели
            обработки персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с
            персональными данными.
          </p>
          <p className="mr-25"> 1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). </p>
          <p className="mr-25"> 1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. </p>
          <p className="mr-25"> 1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания. </p>
          <p className="mr-25"> 1.1.5. «Пользователь мобильного приложения и сайта (далее - Пользователь)» – лицо, имеющее доступ к мобильному приложению и сайту посредством сети Интернет и использующее приложение и сайт. </p>
          <p className="mr-25"> 1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
          <p className="mr-25"> 1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP. </p>
          <h5 className="mr-25">2. ОБЩИЕ ПОЛОЖЕНИЯ</h5>
          <p className="mr-25"> 2.1. Использование Пользователем мобильного приложения и сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя. </p>
          <p className="mr-25"> 2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование мобильного приложения и сайта. </p>
          <p className="mr-25"> 2.3. Настоящая Политика конфиденциальности применяется к мобильному приложению и сайту. </p>
          <p className="mr-25">2.4. Администрация мобильного приложения и сайтв не проверяет достоверность персональных данных, предоставляемых Пользователем приложения.  </p>
          <h5 className="mr-25"> 3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ </h5>

          <p className="mr-25"> 3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации мобильного приложения и сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации мобильного приложения и сайта при регистрации в приложении или при оформлении заказа для приобретения Товара. </p>
          <p className="mr-25"> 3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы в мобильном приложении и сайте включают в себя следующую информацию: </p>
          <p className="mr-25">  3.2.1. фамилию, имя Пользователя;</p>
          <p className="mr-25"> 3.2.2. контактный телефон Пользователя; </p>
          <p className="mr-25"> 3.2.3. адрес доставки Товара; </p>
          <p className="mr-25"> 3.3. Мобильное приложение и сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей. </p>
          <p className="mr-25"> 3.4. Любая иная персональная информация неоговоренная выше (история заказов и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности. </p>
          <h5 className="mr-25"> 4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ </h5>

          <p className="mr-25"> 4.1. Персональные данные Пользователя Администрация мобильного приложения и сайта может использовать в целях: </p>
          <p className="mr-25">  4.1.1. Идентификации Пользователя, зарегистрированного в мобильном приложении и на сайте, для оформления заказа.</p>
          <p className="mr-25"> 4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования мобильного приложения и сайта, оказания услуг, обработка запросов и заявок от Пользователя. </p>
          <p className="mr-25"> 4.1.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества. </p>
          <p className="mr-25"> 4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем. </p>
          <p className="mr-25">  4.1.5. Уведомления Пользователя мобильного приложения и сайта о состоянии Заказа.</p>
          <p className="mr-25"> 4.1.6. Обработки и получения платежей Пользователя. </p>
          <p className="mr-25"> 4.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием мобильного приложения и сайта. </p>
          <p className="mr-25"> 4.1.8. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от мобильного приложения и сайта. </p>
          <p className="mr-25"> 4.1.9. Осуществления рекламной деятельности с согласия Пользователя. </p>
          <h5 className="mr-25">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h5>
          <p className="mr-25"> 5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. </p>
          <p className="mr-25"> 5.2. Пользователь соглашается с тем, что Администрация мобильного приложения и сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного в мобильном приложении и на сайте, включая доставку Товара. </p>
          <p className="mr-25"> 5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти РБ только по основаниям и в порядке, установленным законодательством РБ. </p>
          <p className="mr-25"> 5.4. При утрате или разглашении персональных данных Администрация мобильного приложения и сайта информирует Пользователя об утрате или разглашении персональных данных. </p>
          <p className="mr-25">  5.5. Администрация мобильного приложения и сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
          <p className="mr-25"> 5.6. Администрация мобильного приложения и сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
          <h5 className="mr-25">6. ОБЯЗАТЕЛЬСТВА СТОРОН</h5>

          <p className="mr-25"> 6.1. Пользователь обязан: </p>
          <p className="mr-25"> 6.1.1. Предоставить информацию о персональных данных, необходимую для пользования мобильным приложением и сайта. </p>
          <p className="mr-25"> 6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации. </p>
          <p className="mr-25"> 6.2. Администрация мобильного приложения и сайта обязана:</p>
          <p className="mr-25"> 6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности. </p>
          <p className="mr-25"> 6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</p>
          <p className="mr-25">  6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
          <p className="mr-25"> 6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий. </p>

          <h5 className="mr-25">7. ОТВЕТСТВЕННОСТЬ СТОРОН  </h5>
          <p className="mr-25"> 7.1. Администрация мобильного приложения и сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством РБ, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности </p>
          <p className="mr-25">  7.2. В случае утраты или разглашения Конфиденциальной информации Администрация мобильного приложения и сайта не несёт ответственность, если данная конфиденциальная информация:</p>
          <p className="mr-25"> 7.2.1. Стала публичным достоянием до её утраты или разглашения. </p>
          <p className="mr-25"> 7.2.2. Была получена от третьей стороны до момента её получения Администрацией мобильного приложения и сайта. </p>
          <p className="mr-25"> 7.2.3. Была разглашена с согласия Пользователя. </p>
          <h5 className="mr-25"> 8. РАЗРЕШЕНИЕ СПОРОВ </h5>

          <p className="mr-25"> 8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией мобильного приложения и сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора). </p>
          <p className="mr-25"> 8.2 Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии. </p>
          <p className="mr-25"> 8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РБ. </p>
          <p className="mr-25"> 8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией мобильного приложения и сайта применяется действующее законодательство РБ. </p>
          <h5 className="mr-25">9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ  </h5>
          <p className="mr-25"> 9.1. Администрация мобильного приложения и сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя. </p>
          <p className="mr-25"> 9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения в мобильном приложении и на сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности. </p>
          <p className="mr-25"> 9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по почте на <a rel="stylesheet" href="mailto:info@asia-brand.kz" >info@asia-brand.kz</a> </p>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default PrivacyPolicy;

const Wrapper = styled("div")`
  .mr-25 {
    margin: 25px 0;
  }
`;
