import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { ImageUploadingFetch } from "../../api/axios";

export const getProfile = createAsyncThunk(
  "createorder/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/user/me-info/`);
      localStorage.setItem("profile", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue();
    }
  }
);
export const GetStockList = createAsyncThunk(
  "createorder/createorder",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("stock/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const PostOrderProductBuyManager = createAsyncThunk(
  "createorder/PostOrderProductBuyManager",
  async (props, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.post("order/c/", props.data);
      if (props.image && data.id) {
        dispatch(
          SendOrderBuyManager({ order_id: data.id, image: props.image })
        );
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const SendOrderBuyManager = createAsyncThunk(
  "createorder/SendOrderBuyManager",
  async (props, { rejectWithValue }) => {
    try {
      const formdata = new FormData();
      formdata.append("order_id", props?.order_id);
      formdata.append("image", props?.image);
      const { data } = await ImageUploadingFetch.post("order/image/", formdata);
      localStorage.setItem("main_manager", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
const CreateOrder = createSlice({
  name: "createorder",
  initialState: {
    data: [],
    user_info: null,
    products: [],
    stock: [],
    add_product: [],
    status: "",
    data_order: null,
    stock_status: ""
  },
  reducers: {
    AddProducts: (state, actions) => {
      state.products = actions.payload;
    },
    AddUserinfo: (state, actions) => {
      state.user_info = actions.payload;
    },
    AddToBuy: (state, actions) => {
      // const arr = [];
      // arr.push(actions?.payload);
      state.add_product = actions?.payload;
    },
    AddToAddBuy: (state, actions) => {
      state.add_product = actions.payload;
    },
    DefStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: {
    [GetStockList.pending]: (state, { payload }) => {
      state.stock_status = "pending";
    },
    [GetStockList.fulfilled]: (state, { payload }) => {
      state.stock = payload;
      state.stock_status = "success"
    },
    [GetStockList.rejected]: (state, { payload }) => {
      state.stock_status = "reject";
    },
    [PostOrderProductBuyManager.fulfilled]: (state, { payload }) => {
      state.data_order = payload;
      state.status = "fulfilled";
    },
    [PostOrderProductBuyManager.rejected]: (state, { payload }) => {
      state.status = "rejected";
      state.data_order = payload;
    },
  },
});

export const CreateOrderActions = CreateOrder.actions;
export default CreateOrder;
