import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider, Typography, createTheme } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";

export default function CheckboxLabels({
  getChoseData,
  products,
  label,
  color,
  variant,
  value,
}) {
  const [checked, setChecked] = React.useState([]);
  useEffect(() => {
    if (value) setChecked(value);
  }, [value?.length]);
  useEffect(() => {
    getChoseData(checked);
  }, [checked]);

  const checkAll = (e) => {
    if (e.target.checked) {
      const all = products.map((c) => c);
      setChecked(all);
    } else {
      setChecked([]);
    }
  };

  const handleChangeValue = (e, c) => {
    if (e.target.checked) {
      setChecked([...checked, c]);
    } else {
      setChecked(checked.filter((item) => item.id !== c.id));
    }
  };
  const them = createTheme({
    palette: {
      primary: {
        main: color || "#0071E3",
      },
    },
  });
  const includ = checked?.map((i) => i.id);
  return (
    <WrapCheckbox>
      <FormGroup>
        <ThemeProvider theme={them}>
          <FormControlLabel
            onChange={checkAll}
            control={<Checkbox />}
            label={
              <Typography
                component="span"
                style={{
                  display: "block",
                  color: variant === "product" ? "#09234F" : "#242424",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                {label}
              </Typography>
            }
            value={"value1"}
            checked={checked?.length === products?.length}
          />
          <div className="titles">
            {products?.map((i, index) => (
              <FormControlLabel
                key={index}
                label={i.title}
                checked={includ.includes(i.id)}
                onChange={(e) => handleChangeValue(e, i)}
                control={<Checkbox />}
              />
            ))}
          </div>
        </ThemeProvider>
      </FormGroup>
    </WrapCheckbox>
  );
}
const WrapCheckbox = styled("div")`
  margin: 20px 0;
  & .titles {
    /* display: flex; */
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
  }
  @media screen and (max-width: 700px) {
    & .titles {
      grid-template-columns: auto;
    }
  }
`;
