import { useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Pagination, Skeleton } from "@mui/material";

const Pogination = ({ count }) => {
  const [params, setparams] = useSearchParams();
  const [page, setpage] = useState(1);

  useEffect(() => {
    if (params.get("page")) {
      setpage(+params.get("page"));
    }
  }, [params]);
  const onChangePagination = (e, value) => {
    setpage(value);
    setparams({ page: value });
    window.scroll({
      behavior: "smooth",
      top: 0,
    });
  };

  return (
    <WrapperBox>
      {count ? (
        <>
          <div>
            {count > 1 && (
              <Pagination
                count={count}
                page={page}
                onChange={onChangePagination}
                size="large"
                variant="outlined"
                shape="rounded"
                siblingCount={1}
                boundaryCount={1}
              ></Pagination>
            )}
          </div>
          <span>Страниц: {count}</span>
        </>
      ) : (
        <WrapperSkeleton variant="rectangular" />
      )}
    </WrapperBox>
  );
};
export default React.memo(Pogination);
const WrapperSkeleton = styled(Skeleton)`
  width: 30%;
  height: 40px;
  border-radius: 8px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
const WrapperBox = styled("div")`
  display: flex;
  gap: 25px;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px;
  padding-top: 25px;
  justify-content: flex-end;
  @media screen and (max-width: 700px) {
    justify-content: space-between;
    padding: 25px 10px 25px 0px;
  }
`;
