import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const GetCollectionList = createAsyncThunk(
  "collection/GetCollectionList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("collection/list/");
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const AddNewCollection = createAsyncThunk(
  "collection/AddNewCollection",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("crm/collection/", props);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const DeleteCollection = createAsyncThunk(
  "collection/DeleteCollection",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        "crm/collection/" + props + "/"
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const EditCollection = createAsyncThunk(
  "collection/EditCollection",
  async (props, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        "crm/collection/" + props.slug + "/",
        { title: props?.title }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
// export const deleteCollection = createAsyncThunk(
//   "collection/deleteCollection",
//   async (props, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.delete("crm/collection/" + props.slug + "/");
//       return data;
//     } catch (error) {
//       return rejectWithValue(error?.detail);
//     }
//   }
// );

const CollectionSlice = createSlice({
  name: "collection",
  initialState: {
    collection: [],
    collection_status: "",
    add_collection_status: "",
    delete_collection: "",
    edit_collection: "",
    deletecollection_status: ""
  },
  reducers: {
    ChangeStatus: (state) => {
      state.add_collection_status = "";
      state.delete_collection = "";
      state.edit_collection = ""
      state.deletecollection_status = ""
    },
  },
  extraReducers: {
    // [deleteCollection.fulfilled]: (state) => {
    //   state.deletecollection_status = "success";
    // },
    [GetCollectionList.pending]: (state) => {
      state.collection_status = "pending";
    },
    [GetCollectionList.fulfilled]: (state, actions) => {
      state.collection_status = "success";
      state.collection = actions.payload;
    },
    [GetCollectionList.rejected]: (state) => {
      state.collection_status = "rejected";
    },
    [AddNewCollection.pending]: (state) => {
      state.add_collection_status = "pending";
    },
    [AddNewCollection.fulfilled]: (state) => {
      state.add_collection_status = "success";
    },
    [AddNewCollection.rejected]: (state) => {
      state.add_collection_status = "rejected";
    },
    [DeleteCollection.pending]: (state) => {
      state.delete_collection = "pending";
    },
    [DeleteCollection.fulfilled]: (state) => {
      state.delete_collection = "success";
    },
    [DeleteCollection.rejected]: (state) => {
      state.delete_collection = "rejected";
    },
    [EditCollection.pending]: (state) => {
      state.edit_collection = "pending";
    },
    [EditCollection.fulfilled]: (state) => {
      state.edit_collection = "success";
    },
    [EditCollection.rejected]: (state) => {
      state.edit_collection = "rejected";
    },
  },
});

export const CollectionActions = CollectionSlice.actions;
export default CollectionSlice;
