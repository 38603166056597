import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDealerStatus } from "../../store/loginStore/Auth";
import { Box, Typography } from "@mui/material";
import Button from "../../components/ui/Button";
import BasicModal from "../../components/ui/BasicModal";
import Input from "../../components/ui/Input";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/deleteIcon.svg";
import {
  addDealerStatus,
  dealerStatusActions,
  deleteCatUSer,
  editDealerStatus,
  getDealerStatus,
} from "../../store/moderatorStore/dealerstatusCRUD";
import { NotificationManager } from "react-notifications";

function CategoryUser() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { add_status, dealerList, edit_status, delete_status } = useSelector(
    (s) => s.dealerSt
  );

  const [data, setData] = useState({
    title: "",
    discount: "",
  });
  const [isDelete, setIsDelete] = useState({ modal: false, title: "" });
  const { dealer_st } = useSelector((s) => s.loginAuth);

  useEffect(() => {
    dispatch(getDealerStatus());
  }, []);

  useEffect(() => {
    if (add_status === "success") {
      NotificationManager.success("Успешно добавлен!");
      dispatch(dealerStatusActions.ChangeStatus());
      setModal(false);
      dispatch(GetDealerStatus());
    }
    if (edit_status === "success") {
      NotificationManager.success("Успешно редактирован!");
      dispatch(dealerStatusActions.ChangeStatus());
      setModal(false);
      dispatch(GetDealerStatus());
    }
    if (delete_status === "success") {
      NotificationManager.success("Успешно удален!");
      dispatch(dealerStatusActions.ChangeStatus());
      setIsDelete(false);
      dispatch(GetDealerStatus());
    }
  }, [add_status, edit_status, delete_status]);

  const openAddCat = () => {
    setModal(!modal);
    setData({ title: "", discount: "", main_title: "" });
  };

  const getValues = (e) => {
    const { value, name } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const editCatUSer = (e, type) => {
    setData({ ...e, type });
    setModal(!modal);
  };
  const addCatHandle = () => {
    if (data.type === "edit") {
      delete data.type;
      dispatch(editDealerStatus(data));
    } else {
      dispatch(addDealerStatus(data));
    }
  };

  const deleteCatUSerHandle = (title) => {
    setIsDelete((prev) => ({ ...prev, modal: !prev.modal, title }));
  };

  const deleteCatHandle = () => {
    dispatch(deleteCatUSer(isDelete?.title));
  };

  return (
    <Box marginBottom="60px">
      <Typography variant="h6">Статус пользователи</Typography>
      {dealer_st?.map((i, index) => (
        <RenderCatUser
          key={index + "cat"}
          main_title={i.main_title}
          discount={i.discount}
          editCatUSer={() => editCatUSer(i, "edit")}
          deleteCatUSer={() => deleteCatUSerHandle(i.title)}
        />
      ))}
      <AddCategoryModal
        open={modal}
        addCatHandle={addCatHandle}
        onClose={openAddCat}
        data={data}
        getValues={getValues}
      />
      <DeleteCatModal
        open={isDelete.modal}
        deleteCatHandle={deleteCatHandle}
        onClose={deleteCatUSerHandle}
      />
      <Button
        onClick={openAddCat}
        style={{ width: "230px", height: "40px" }}
        variant="outlined"
      >
        Добавить категория
      </Button>
    </Box>
  );
}

export default CategoryUser;

const AddCategoryModal = ({ open, onClose, getValues, data, addCatHandle }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <Box sx={{ padding: "10px 18px" }}>
        <Typography>
          {data.type === "edit" ? "Редактировать " : "Добавить "} статус
        </Typography>
        <Input
          value={data.main_title}
          onChange={getValues}
          name="main_title"
          label="Статус"
        />
        <Input
          value={data.discount}
          onChange={getValues}
          name="discount"
          label="Скидка"
          type="number"
        />
        <Button
          onClick={addCatHandle}
          style={{ marginTop: "20px", height: "40px" }}
        >
          {data.type === "edit" ? "Сохранить" : "Добавить "}
        </Button>
      </Box>
    </BasicModal>
  );
};

const DeleteCatModal = ({ open, onClose, deleteCatHandle }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <Box sx={{ padding: "10px 18px" }}>
        <Typography variant="h6">Удалить статус?</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: "20px", height: "40px" }}
          >
            Отмена
          </Button>
          <Button
            variant="danger"
            onClick={deleteCatHandle}
            style={{ marginTop: "20px", height: "40px" }}
          >
            Удалить
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
};

const RenderCatUser = ({
  main_title,
  deleteCatUSer,
  discount,
  editCatUSer,
}) => {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        maxWidth: "400px",
        border: "1px solid gray",
        borderRadius: "4px",
        margin: "10px 0",
        padding: "6px",
      }}
    >
      <Typography
        sx={{
          minWidth: "115px",
          color: "#676767",
          "& > span": { color: "black" },
        }}
      >
        Статус: <span>{main_title || "пусто"}</span>
      </Typography>
      <Typography
        sx={{
          minWidth: "115px",
          marginLeft: "50px",
          color: "#676767",
          "& > span": { color: "black" },
        }}
      >
        Скидка: <span>{discount} %</span>
      </Typography>
      <Box
        sx={{ width: "30px", display: "flex", justifyContent: "space-between" }}
      >
        {main_title !== "C" && (
          <Edit onClick={editCatUSer} style={{ marginLeft: "0px" }} />
        )}
        {/* {main_title !== "C" && (
          <Delete onClick={deleteCatUSer} style={{ marginLeft: "0px" }} />
        )} */}
      </Box>
    </Box>
  );
};
