import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import CardProduct from "./CardProduct";
import {
  getAllProducts,
  getProductsByCity,
} from "../../store/adminStore/products";
import { GetStock } from "../../store/managerStore/Stock";
import { GetAllCity } from "../../store/adminStore/Visit";

export const DetailCityCardProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams();
  const [nameCity, setNameCity] = useState();
  const goBackNavigate = (e) => navigate(e);

  const { products_bycity } = useSelector((state) => state?.products);
  const city = localStorage.getItem("city_title") || "";
  useEffect(() => {
    setNameCity(city);
    dispatch(getProductsByCity(id?.id));
  }, [id?.id, city]);
  return (
    <WrapperBox>
      <span>
        <h3 className="first" onClick={() => goBackNavigate(-1)}>
          Склад
        </h3>
        <h3 className="first">/</h3>
        <h3>{nameCity}</h3>
      </span>
      <div>
        {products_bycity?.map((i) => (
          <CardProduct key={i.slug} city data={i} />
        ))}
      </div>
    </WrapperBox>
  );
};

const SkladProduct = () => {
  const dispatch = useDispatch();
  const { loginAuth, profile, stock, visit } = useSelector((state) => state);
  const { all_products, products_bycity, productByCity_status, allproducts_status } = useSelector(
    (state) => state.products
  );

  const profile_manager = JSON.parse(localStorage.getItem("profile")) || {};
  const need_city = visit?.city?.find((i) => i?.slug === profile_manager?.city);
  const get_stock = stock?.stock_list?.find(
    (i) =>
      i?.city === need_city?.title ||
      (need_city?.title === "Тараз" ? i?.city === "Шымкент" : "")
  );
  useEffect(() => {
    if (loginAuth?.role !== "manager" && loginAuth?.role !== "warehouse") {
      dispatch(getAllProducts());
    } else if (profile?.profile?.city) {
      dispatch(GetStock());
      dispatch(GetAllCity());
      if (get_stock?.id) {
        localStorage.setItem("manager_stock", get_stock?.id);
        dispatch(getProductsByCity(get_stock?.id));
      }
    }
  }, [
    profile?.profile?.city,
    loginAuth?.role,
    visit?.city?.length,
    get_stock?.id,
  ]);
  const result =
    loginAuth?.role !== "manager" && loginAuth?.role !== "warehouse"
      ? all_products
      : products_bycity;

  return (
    <WrapperBox>
      <h3>Категория</h3>
      <div>
        {allproducts_status === "pending" || productByCity_status === "pending" ? (
          <>
            {[...Array(14)].map((i, index) => (
              <CategoriesSkeleton key={index} variant="rectangular" />
            ))}
          </>
        ) : (
          result?.map((i) => <CardProduct data={i} key={i.slug} />)
        )}
      </div>
    </WrapperBox>
  );
};
export default SkladProduct;
const CategoriesSkeleton = styled(Skeleton)`
  width: 48%;
  height: 120px;
  padding: 11px;
  border-radius: 6px;
`;
const WrapperBox = styled("div")`
  & > span {
    display: flex;
    gap: 5px;
    & > h3 {
      cursor: pointer;
    }
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #09234f;
    margin-top: 20px;
    margin-bottom: 12px;
    text-transform: capitalize;
  }
  .first {
    color: #8e8e92;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    & > div {
      width: 48%;
    }
  }
  @media screen and (max-width: 750px) {
    & > div > div {
      width: 100%;
    }
  }
`;
