import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import DeletePhoto from "../../assets/icons/deleteIcon.svg";
import PhotoIcon from "../../assets/icons/photoadd.svg";
import PhotoIcon2 from "../../assets/icons/photoadd2.svg";
import RepeatPhoto from "../../assets/icons/repeatIcon.svg";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { deleteImageProduct } from "../../store/moderatorStore/ProductSlice";

const ImagePicker = ({
  allPhotos,
  isDelete,
  maxphotos,
  getPhoto,
  type,
  ...props
}) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const getAllPhotoAndSend = (getphotofile) => getPhoto(getphotofile);
  useEffect(() => {
    setPhotos(allPhotos);
  }, [allPhotos]);
  const onChange = (imageList) => {
    setPhotos(imageList);
    getAllPhotoAndSend(imageList);
  };
  const getIdImage = (e) => {
    if (isDelete && e?.id) {
      dispatch(deleteImageProduct({ image_id: e?.id }));
    }
  };
  return (
    <DivWrapper>
      <DivPhotosAndText>
        <ImageUploading
          multiple
          value={photos}
          onChange={onChange}
          maxNumber={maxphotos || 1}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            onImageRemove,
            dragProps,
          }) => (
            <DivContainerPhotos>
              {imageList?.map((image, index) => (
                <DivImgButton key={index}>
                  <ImgPhoto
                    border={type}
                    loading="lazy"
                    src={image?.data_url}
                    alt="#"
                  />
                  <ButtonRedactor>
                    <ButtonRedactor2>
                      <RepeatButton onClick={() => onImageUpdate(index)} />
                      <DeletButton
                        onClick={() => {
                          onImageRemove(index);
                          getIdImage(image);
                        }}
                      />
                    </ButtonRedactor2>
                  </ButtonRedactor>
                </DivImgButton>
              ))}
              &nbsp;
              {maxphotos === photos?.length ? (
                ""
              ) : (
                <WrapperErrorText>
                  <ButtonPhotoWrapper border={type} style={props}>
                    <ButtonPhoto
                      changeImg={type}
                      onClick={onImageUpload}
                      {...dragProps}
                    />
                  </ButtonPhotoWrapper>
                </WrapperErrorText>
              )}
            </DivContainerPhotos>
          )}
        </ImageUploading>
      </DivPhotosAndText>
    </DivWrapper>
  );
};
export default ImagePicker;

const DivWrapper = styled.div`
  box-sizing: border-box;
  font-family: "Inter";
  width: 100%;
  height: 100%;
`;

const DivPhotosAndText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const DivContainerPhotos = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;
const DivImgButton = styled.div`
  width: 100%;
  height: 100%;
  &:hover > div {
    display: block;
  }
`;
const ImgPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${(props) => (props.border ? "50%" : "")};
`;
const ButtonPhotoWrapper = styled.div`
  background: #f3f3f3;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.border ? "50%" : "")};
`;
const WrapperErrorText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const ButtonPhoto = styled.label`
  background-image: ${(props) =>
    props.changeImg ? `url(${PhotoIcon2})` : `url(${PhotoIcon})`};
  display: block;
  background-position: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const ButtonRedactor = styled.div`
  display: none;
  @media screen and (max-width: 700px) {
    display: block;
  }
`;
const ButtonRedactor2 = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1;
  background-color: #80808063;
  position: relative;
  top: -30px;
`;
const RepeatButton = styled.button`
  background-image: url(${RepeatPhoto});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  border: none;
  height: 30px;
  cursor: pointer;
`;

const DeletButton = styled.button`
  background-image: url(${DeletePhoto});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
`;
