import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const GetDealerList = createAsyncThunk(
  "sales/GetDealerList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`dealer-status/list/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetCityList = createAsyncThunk(
  "sales/GetCityList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`city/list/`);
      localStorage.setItem("city", JSON.stringify(data));
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetCategoryList = createAsyncThunk(
  "sales/GetCategoryList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`category/list/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);
export const GetSalesList = createAsyncThunk(
  "sales/GetSalesList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`crm/discount/crud/`);
      return data;
    } catch (error) {
      return rejectWithValue(error?.detail);
    }
  }
);

const SalesSlice = createSlice({
  name: "sales",
  initialState: {
    sales: null,
    sales_status: "",
    dealer: null,
    dealer_status: "",
    city: JSON.parse(localStorage.getItem("city")) || [],
    city_status: "",
    category: null,
    category_status: "",
  },
  extraReducers: {
    [GetSalesList.pending]: (state) => {
      state.sales_status = "pending";
    },
    [GetSalesList.fulfilled]: (state, actions) => {
      state.sales_status = "success";
      state.sales = actions.payload;
    },
    [GetSalesList.rejected]: (state) => {
      state.sales_status = "rejected";
    },
    [GetDealerList.pending]: (state) => {
      state.dealer_status = "pending";
    },
    [GetDealerList.fulfilled]: (state, actions) => {
      state.dealer_status = "success";
      state.dealer = actions.payload;
    },
    [GetDealerList.rejected]: (state) => {
      state.dealer_status = "rejected";
    },
    [GetCityList.pending]: (state) => {
      state.city_status = "pending";
    },
    [GetCityList.fulfilled]: (state, actions) => {
      state.city_status = "success";
      state.city = actions.payload;
    },
    [GetCityList.rejected]: (state) => {
      state.city_status = "rejected";
    },
    [GetCategoryList.pending]: (state) => {
      state.category_status = "pending";
    },
    [GetCategoryList.fulfilled]: (state, actions) => {
      state.category_status = "success";
      state.category = actions.payload;
    },
    [GetCategoryList.rejected]: (state) => {
      state.category_status = "rejected";
    },
  },
});

export const SalesActions = SalesSlice.actions;
export default SalesSlice;
