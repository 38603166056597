import styled from "@emotion/styled";

export const NavbarButton = ({ active, text, img, count, onClick }) => {
  return (
    <WrapperBox onClick={onClick}>
      <div className={active}>
        <img loading="lazy" alt="btn_logo" src={img} />
        <p>{text}</p>
        {+count > 0 && <span>{count}</span>}
      </div>
    </WrapperBox>
  );
};

const WrapperBox = styled("div")`
  & > div {
    display: flex;
    width: 197px;
    height: 41px;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
  }
  & > div:hover {
    background: #dde0e6;
  }
  & > div > img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  & > div > p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #5d7285;
    white-space: nowrap;
  }
  & > div > span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: white;
    background: #09234f;
    border-radius: 4px;
    padding: 3px 5px;
  }
  .active {
    background-color: #dde0e6;
  }
`;
